export const USER_ROLES = {
  ADMIN: "admin",
  RECRUITER: "recruiter",
  WRITER: "writer",
  CLIENT: "client",
  MAIN_CLIENT: "main-client",
  EDITOR_MANAGER: "editor-manager",
  CORRECTOR: "corrector",
  CANDIDATE: "candidate",
};

export const ORDER_OPTIONS = {
  SEO_SUPPORT: 1,
  YTG_OPTIMISATION: 12,
};

export const ORDER_TYPES = {
  WEB_PAGE: 2,
  PRODUCT_DESCRIPTION: 3,
  ECOMMERCE_CATEGORY: 4,
  LOCALIZED_PAGE: 5,
  SEMANTIC_COCOON: 6,
  PODCAST_SCRIPT: 14,
};

export const WRITER_LEVELS = {
  EXPERT: 3,
  CANDIDATE: 4,
};

export const ORDER_TEXT_STATUSES = {
  AWAITING_WRITER: 1,
  IN_PROGRESS: 2,
  CORRECTING: 3,
  AWAITING_CLIENT: 4,
};

export const ORDER_STATUSES = {
  DRAFT: 1,
  AWAITING_PAYMENT: 2,
  AWAITING_CLIENT: 3,
  VALIDATING: 4,
  WRITING: 5,
  SENT: 7,
  APPROVED: 8,
};

export const ORDER_SUBSCRIPTION_STATUSES = {
  ACTIVE: 1,
  SUSPENDED: 2,
  CANCELED: 3,
  FINISHED: 4,
};

export const ORDER_SUBJECT_STATUSES = {
  PENDING: 1,
  TO_VALIDATE: 2,
  ACCEPTED: 3,
  REJECTED: 4,
};

export const TEMPLATE_TYPES = {
  SKIBIX_TEMPLATE: 1,
  NEW_TEMPLATE: 2,
  CUSTOM_TEMPLATE: 3,
};

export const PAYMENT_OPTIONS = {
  UNIQUE: 1,
  SUBSCRIPTION: 2,
  MONTHLY: 3,
  CREDIT: 4,
  WIRE_TRANSFER: 5,
};

export const CANDIDATE_STATUSES = {
  DRAFT: 1,
  AWAITING_CONTRACT: 2,
  AWAITING_TEST: 3,
  REJECTED: 8,
  PENDING: 9,
  VALIDATE: 10,
};
