var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"section"},[_c('b-loading',{attrs:{"is-full-page":"","active":_vm.dataFetching},on:{"update:active":function($event){_vm.dataFetching=$event}}}),(_vm.componentReady)?_c('div',[_c('div',{staticClass:"is-flex is-flex-wrap-wrap is-justify-content-space-between is-align-items-center"},[_c('div',[_c('h2',[_vm._v(_vm._s(_vm.$t("sites.my")))]),(_vm.dashboardView)?_c('router-link',{staticClass:"has-text-blue is-size-6 is-underlined",attrs:{"to":{ path: `${_vm.homepage}/websites` }}},[_vm._v(" Voir tous mes sites ")]):_vm._e()],1),_c('ButtonRouterLink',{staticClass:"is-fullheight",attrs:{"size":"large","link":`${_vm.homepage}/new-website`,"text":_vm.$t('sites.new'),"icon":"IconPlus","color":"pink"}})],1),_c('form',{staticClass:"is-flex is-flex-wrap-wrap mt-2"},[_c('b-field',{attrs:{"label":""}},[_c('b-autocomplete',{attrs:{"data":_vm.websites,"field":"domain","clearable":"","icon":"fas fa-search","placeholder":_vm.$t('sites.filters.search_by_domain')},on:{"select":(option) => (_vm.selected = option)},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(_vm._s(_vm.$t("sites.filters.no_result")))]},proxy:true}],null,false,273289544),model:{value:(_vm.websiteSearch),callback:function ($$v) {_vm.websiteSearch=$$v},expression:"websiteSearch"}})],1)],1),_vm._l((_vm.websites),function(site){return _c('div',{key:site.id,staticClass:"box has-background-white mt-2"},[_c('router-link',{staticClass:"is-flex is-align-items-center",attrs:{"to":{ path: `${_vm.homepage}/website/${site.id}` }}},[_c('icon-ribbon',{attrs:{"icon":"IconGlobe"}}),_c('div',[(site.name)?_c('h5',[_vm._v(_vm._s(site.name))]):_c('h5',[_vm._v(_vm._s(site.domain))]),(site.thematic)?_c('p',{staticClass:"body1"},[_vm._v(_vm._s(site.thematic))]):_vm._e(),(_vm.dashboardView)?_c('p',{staticClass:"has-text-blue is-size-7 is-underlined"},[_vm._v(" Voir toutes mes commandes ")]):_vm._e()])],1),_c('div',{staticClass:"mt-6"},[(
            site.orders.length === 0 ||
            (site.orders.length === 1 &&
              !site.approved_orders_count &&
              parseInt(site.orders[0].status) <= 2)
          )?_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-4-tablet is-3-desktop is-3-widescreen"},[(site.orders.length === 0 && site.approved_orders_count)?_c('ButtonIcon',{staticClass:"mr-2",attrs:{"color":"pink","size":"large","icon":"IconPlus","text":_vm.$t('orders.new')},on:{"click-button":function($event){return _vm.activateNewOrderConfirmationModal(site)}}}):_c('b-button',{staticClass:"button is-large is-fullwidth has-border-pink has-hover-bottom",attrs:{"tag":"router-link","to":site.orders[0]
                  ? `${_vm.homepage}/new-website/${site.id}/order/${site.orders[0].reference}`
                  : `${_vm.homepage}/new-website/${site.id}`,"type":"is-pink","inverted":""}},[_vm._v(" Reprendre le parcours ")])],1)]):_c('div',{staticClass:"columns is-multiline"},[_vm._l((site.orders),function(order){return _c('article',{key:order.id,staticClass:"column is-4-tablet is-3-desktop is-3-widescreen has-background-white"},[_c('OrderCard',{attrs:{"order":order}})],1)}),(_vm.shouldDisplayNewOrderButton(site))?_c('div',{staticClass:"column is-4-tablet is-3-desktop is-3-widescreen"},[_c('ButtonIcon',{staticClass:"width-100",attrs:{"color":"pink","size":"large","icon":"IconPlus","text":_vm.$t('orders.new')},on:{"click-button":function($event){return _vm.activateNewOrderConfirmationModal(site)}}})],1):_vm._e()],2)])],1)})],2):_vm._e(),(_vm.selectedSite)?_c('NewOrderConfirmationModal',{attrs:{"website":_vm.selectedSite},model:{value:(_vm.isNewOrderConfirmationModalActive),callback:function ($$v) {_vm.isNewOrderConfirmationModalActive=$$v},expression:"isNewOrderConfirmationModalActive"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }