export default () => ({
  users: null,
  usersCollection: null,
  user: {
    firstname: "",
    lastname: "",
    email: "",
    agency: "",
    phone: "",
  },
  newUser: {
    firstname: null,
    lastname: null,
    email: null,
    agency: null,
    phone: null,
    avatar: null,
    locale: "fr",
    password: "",
    password_confirmation: "",
    roles: [],
  },
  formValue: {},
  usersConfig: {
    include: "",
    sort: "created_at",
    sortDirection: "desc",
    page: 1,
    filters: {
      roles: [],
    },
    per_page: 100,
  },
  userConfig: {},
  userProfile: false,
  isUserSend: false,
  passwordGenerated: false,
  billingPortalUrl: null,
  onGoingSubscriptionCount: 0,
  payloadModels: {
    update: [
      "email",
      "password",
      "password_confirmation",
      "firstname",
      "lastname",
      "company_id",
      "address_line_1",
      "address_line_2",
      "city",
      "country",
      "phone",
      "country_code",
      "pro_id",
      "locale",
      "roles",
    ],
    updateProfile: [
      "email",
      "password",
      "password_confirmation",
      "firstname",
      "lastname",
      "company_id",
      "address_line_1",
      "address_line_2",
      "city",
      "postal_code",
      "country",
      "phone",
      "country_code",
      "tva",
      "pro_id",
      "agency",
      "locale",
      "avatar",
      "website",
      "linkedin_profile",
      "discord_channel",
      "internal_comment",
      "years_of_experience",
      "seo_level",
      "redaction_for_writer",
      "words_in_month",
      "have_formation",
      "formation",
      "preferences.engagement",
    ],
  },
});
