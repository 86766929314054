<template>
  <div class="mb-3">
    <div class="columns is-align-items-end">
      <div class="column">
        <component
          v-bind="$attrs"
          :is="'BriefText'"
          :isDisabled="true"
          @save-result="saveResult"
          class="is-flex is-flex-direction-column is-justify-content-space-evenly mt-1"
          ref="creditField"
        />
      </div>

      <div class="column is-narrow pl-0">
        <template v-if="!showAddForm">
          <b-button
            inverted
            type="is-pink"
            class="button is-small has-border-pink has-hover-bottom mb-1"
            @click.prevent="showAddForm = true"
          >
            +
          </b-button>
          <b-button
            inverted
            type="is-pink"
            class="button is-small has-border-pink has-hover-bottom ml-2 mb-1"
            :aria-expanded="isOpenCreditHistoriesTable"
            aria-controls="creditHistoriesCollapse"
            @click="isOpenCreditHistoriesTable = !isOpenCreditHistoriesTable"
          >
            Historique
          </b-button>
        </template>

        <div v-else class="is-flex is-align-items-end">
          <b-input
            v-model="creditToAdd"
            type="number"
            placeholder="Crédit HT à ajouter"
            class="b-input mt-2"
          />

          <b-button
            v-if="creditToAdd"
            inverted
            type="is-pink"
            class="button is-small has-border-pink has-hover-bottom ml-2 mb-1"
            @click.prevent="addCredit"
          >
            +
          </b-button>

          <b-button
            v-else
            inverted
            type="is-pink"
            class="button is-small has-border-pink has-hover-bottom ml-2 mb-1"
            @click.prevent="showAddForm = false"
          >
            X
          </b-button>
        </div>
      </div>
    </div>

    <div class="columns is-align-items-end">
      <b-collapse
        aria-id="creditHistoriesCollapse"
        class="column is-full"
        animation="slide"
        v-model="isOpenCreditHistoriesTable"
      >
        <template #trigger> </template>
        <div>
          <div :aria-expanded="isOpenCreditHistoriesTable">
            <strong>Historique d'ajout et d'utilisation de crédits</strong>
          </div>

          <CreditHistoriesTable
            v-if="$attrs.field.companyId"
            :company-id="$attrs.field.companyId"
          />
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import BriefText from "@/components/Model/Form/FormItem/BriefText";
import CreditHistoriesTable from "@/components/Model/Company/Tables/CreditHistoriesTable";

export default {
  components: {
    BriefText,
    CreditHistoriesTable,
  },

  data() {
    return {
      creditToAdd: null,
      showAddForm: false,
      isOpenCreditHistoriesTable: false,
    };
  },

  methods: {
    saveResult(result) {
      this.$emit("save-result", result);
    },

    addCredit() {
      this.$refs.creditField.selected =
        parseFloat(this.$refs.creditField.selected) +
        parseFloat(this.creditToAdd);

      this.$refs.creditField.save();

      this.creditToAdd = null;
      this.showAddForm = false;
    },
  },
};
</script>
