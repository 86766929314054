<template>
  <div class="main">
    <div class="px-5">
      <div class="columns">
        <div class="column">
          <h2>Options</h2>

          <b-button href="#" @click.prevent="createOption" class="mb-2">
            Ajouter une option
          </b-button>

          <OptionsTable @show-popup="$refs.createModal.show()" />
        </div>
      </div>
    </div>

    <CreateOptionModal ref="createModal" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import OptionsTable from "@/components/Model/Options/OptionsTable";
import CreateOptionModal from "@/components/Model/Options/Modal/CreateOptionModal";

export default {
  title: "title.admin.thematics",
  name: "AdminOptions",
  components: { CreateOptionModal, OptionsTable },
  data() {
    return {};
  },
  created() {
    this.config.include += "";
  },
  computed: {
    ...mapState("thematic", {
      config: (state) => state.thematicsConfig,
    }),
  },
  methods: {
    createOption() {
      this.$store.commit("option/setDefaultOption");

      this.$refs.createModal.show();
    },
  },
};
</script>
