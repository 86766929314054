export default () => ({
  thematics: null,
  thematicsConfig: {
    include: "",
    sort: "name",
    sortDirection: "",
    page: 1,
    filters: "",
    per_page: 100,
  },
  categories: null,
  thematic: null,
  newThematic: {
    fr: { name: "" },
    en: { name: "" },
    parent: { name: "" },
  },
});
