import store from "@/store";
import router from "@/router";
import { ORDER_OPTIONS } from "@/utils/constants";

export const preparePhoneObject = (phone, countryCode) => {
  return {
    value: phone,
    countryCode,
    value_name: "phone",
  };
};

export const formatDateToYYYYMMDD = (date) => {
  return new Intl.DateTimeFormat("fr-FR")
    .format(date)
    .split("/")
    .reverse()
    .join("-");
};

export const prepareRequestFilters = (filters, defaultFilters = {}) => {
  let preparedFilters = defaultFilters || {};

  Object.keys(filters).forEach((element) => {
    preparedFilters[element] = filters[element];
  });

  return preparedFilters;
};

export const ValueOrDefault = (
  value,
  defaultValue = null,
  valueIfValue = null
) => {
  if (!value) {
    return defaultValue;
  }

  return valueIfValue || value;
};

export const isEuropeanCountry = (countryName) => {
  return [
    "allemagne",
    "autriche",
    "belgique",
    "bulgarie",
    "chypre",
    "croatie",
    "danemark",
    "espagne",
    "estonie",
    "finlande",
    "france",
    "grèce",
    "hongrie",
    "irlande",
    "italie",
    "lettonie",
    "lituanie",
    "luxembourg",
    "malte",
    "pays-bas",
    "pologne",
    "portugal",
    "république tchèque",
    "roumanie",
    "royaume-uni",
    "slovaquie",
    "slovénie",
    "suède",
  ].includes(countryName);
};

export const isCountryEuropeanAndNotFrance = (countryName) => {
  countryName = countryName?.toLowerCase();

  if (countryName === "france") {
    return false;
  }

  return isEuropeanCountry(countryName);
};

export const percentage = (partialValue, totalValue, adjust = true) => {
  let percent = (100 * partialValue) / totalValue;

  if (adjust && percent > 100) {
    return 100;
  }

  return percent.toFixed(2) * 1;
};

export const isInDevEnv = () => {
  const url = window.location.href;
  return ["localhost", ".test", "skriboo"].some((part) => url.includes(part));
};

export const apiBaseUrl = () => `${process.env.VUE_APP_BACKEND_URL}/api`;

function truncateMiddle(string, length, startMaxIndex = null) {
  if (string.length <= length) {
    return string;
  }

  if (startMaxIndex === null || startMaxIndex < 0) {
    startMaxIndex = Math.floor(length / 2);
  }

  const start = string.substring(0, startMaxIndex);
  const end = string.substring(string.length - Math.floor(length / 2));

  return start + "..." + end;
}

export function formatBriefUrl(url, truncateLength = 20) {
  const isFileUrl = /\.[a-zA-Z]{2,5}$/.test(url);

  if (isFileUrl) {
    return url.split("/").pop();
  } else {
    const firstIndexOfSlash = url.indexOf("/", url.indexOf("://") + 3) + 1;
    return truncateMiddle(url, truncateLength, firstIndexOfSlash);
  }
}

export function copyAndNotify(text, targetElement) {
  navigator.clipboard.writeText(text);

  document.getElementById(text).classList.add("copied-element");

  if (
    targetElement &&
    (targetElement.isContentEditable ||
      targetElement.tagName.toLowerCase() === "input" ||
      targetElement.tagName.toLowerCase() === "textarea")
  ) {
    // Get the selection and range
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);

    // Create a new text node with the provided text
    const textNode = document.createTextNode(text);

    // Insert the new text node at the cursor position
    range.deleteContents();
    range.insertNode(textNode);

    // Move the cursor to the end of the newly inserted text
    range.setStartAfter(textNode);
    range.setEndAfter(textNode);
  }

  setTimeout(() => {
    document.getElementById(text).classList.remove("copied-element"); // Clear the result message after 5 seconds
  }, 500); // 5000 milliseconds = 5 seconds
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function scrollToValidationError() {
  setTimeout(() => {
    let element = document.querySelector(".card-validation-error");

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, 500);
}

export function orderDeliveryDateKey() {
  return store.state.auth.roles.includes("writer") ||
    store.state.auth.roles.includes("candidate")
    ? "date_before_delivery_date"
    : "desired_delivery_date";
}

export function orderHasSeoSupportOption(order) {
  return order?.options
    ? order.options.some(
        (option) => (option?.id || option) === ORDER_OPTIONS.SEO_SUPPORT
      )
    : false;
}

const retrieveFiltersFromUrlParams = (queries) => {
  const filters = {};

  Object.entries(queries).map(([key, value]) => {
    if (key.includes("filter[")) {
      const filterKey = key.match(/filter\[(.*)\]/)[1];
      filters[filterKey] = value;
    }
  });

  return filters;
};

export { retrieveFiltersFromUrlParams };

export function addFiltersToUrlParams(filters, query) {
  const currentFilters = retrieveFiltersFromUrlParams(query);
  const params = { ...query };

  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      currentFilters[key] = value;
    } else if (currentFilters[key] !== undefined) {
      delete currentFilters[key];
    }

    if (params[`filter[${key}]`] !== undefined) {
      delete params[`filter[${key}]`];
    }
  });

  Object.entries(currentFilters).forEach(([key, value]) => {
    params[`filter[${key}]`] = value;
  });

  router.push({
    query: params,
  });
}

export function clearEditorContent(content) {
  const regexList = [
    // Remove style attribute from all tags except <font>
    { pattern: /<(?!font)([^>]*) style="[^"]*"/g, replacement: "<$1" },
    // Remove empty font tags
    { pattern: /<font[^>]*>[ ]?<\/font>/g, replacement: "" },
    // Remove font tags but retain inner content
    {
      pattern:
        /<font(?: style="vertical-align: inherit;")?>((?:(?!<\/font>).)*)<\/font>/g,
      replacement: "$1",
    },
    // Remove the 'data-comment-content' attribute from <comment> tags if it has no value
    {
      pattern: /<(comment)[^>]* data-comment-content(=" ?")? [^>]*>/g,
      replacement: "<$1>",
    },
    // Unwrap nested <comment> tags
    {
      pattern:
        /<comment>((?:(?!<\/comment>)[\s\S])*<comment[^>]*>(?:(?!<\/comment>)[\s\S])*<\/comment>(?:(?!<\/comment>)[\s\S])*)<\/comment>/g,
      replacement: "$1",
    },
    // Merge consecutive <comment> tags with the same 'data-comment-uuid' attribute value
    {
      pattern:
        /(<comment ([\s\S])* data-comment-uuid="([^"]*)"[^>]*>)((?:(?!<\/comment>)[\s\S])*)<\/comment>\s*<comment ((?!data-comment-uuid)[\s\S])* data-comment-uuid="([^"]*)"[^>]*>((?:(?!<\/comment>)[\s\S])*<\/comment>)/g,
      replacement: (match, p1, p2, uuid1, p4, p5, uuid2, p7) => {
        if (uuid1 === uuid2) {
          return `${p1}${p4}${p7}`;
        }

        return match;
      },
    },
    // Unwrap <mark|comment|span> tags
    {
      pattern: /<(mark|comment|span)>((?:(?!<\/\1>).)*)<\/\1>/g,
      replacement: "$2",
    },
    // Replace &nbsp; with a space
    { pattern: /&nbsp;/g, replacement: " " },
    // Replace multiple spaces with a single space
    { pattern: / {2,}/g, replacement: " " },
    // Replace curly apostrophe with straight apostrophe
    { pattern: /’/g, replacement: "'" },
  ];

  let result = content;

  do {
    content = result;
    regexList.forEach(({ pattern, replacement }) => {
      result = result.replace(pattern, replacement);
    });
  } while (result !== content);

  return result;
}

export function prepareFilters(filter, targetKey = "status") {
  let filters = {};

  Object.keys(filter).forEach((element) => {
    if (element === targetKey && !Array.isArray(filter[element])) {
      filter[element] = [filter[element]];
    }

    filters[element] = filter[element];
  });

  return filters;
}

export const removeAQueryFromUrl = (queryKey) => {
  const query = Object.assign({}, router.query);
  delete query[queryKey];

  router.replace({
    query,
  });
};

export function prepareSteps(steps) {
  let stepsData = steps;

  steps.map((step, index) => {
    if (step.patchAction) {
      step.content.map((stepContent, stepContentIndex) => {
        stepsData[index].content[stepContentIndex].patchAction =
          step.patchAction;
      });

      delete stepsData[index].patchAction;
    }

    if (step.model) {
      step.content.map((stepContent, stepContentIndex) => {
        stepsData[index].content[stepContentIndex].model = step.model;
      });

      delete stepsData[index].model;
    }
  });

  return stepsData;
}

export function parsePlainTextUrlsToAnchorTags(content) {
  return content.replace(
    /(href=")?((https?:\/\/|www\.)[^\s"<>]+)/g,
    (match, hrefAttr) => {
      if (hrefAttr) return match;
      return `<a href="${match}" target="_blank" class="has-text-pink">${match}</a>`;
    }
  );
}
