<template>
  <div
    v-if="isloading"
    class="is-flex is-flex-direction-column is-justify-content-space-evenly"
  >
    <div class="borderTop mb-2" v-if="options && options.length > 0">
      <p class="body2 has-text-blue">Précédemment utilisés sur ce site</p>
      <div class="columns is-multiline mt-2">
        <b-field
          v-for="optionSite in options"
          :key="optionSite.id"
          class="column is-4-tablet is-4-desktop is-4-widescreen isheightMaxContent"
        >
          <b-radio-button
            v-model="templateSelected"
            :native-value="optionSite.id"
            type="is-pink-light"
          >
            <ul
              class="is-flex is-flex-direction-column is-justify-content-space-evenly"
            >
              <li>
                <h4
                  :class="{
                    'has-text-pink': templateSelected === optionSite.id,
                  }"
                >
                  {{ optionSite.name }}
                </h4>
              </li>
              <li
                v-if="optionSite.order_type_id"
                class="body1 is-flex is-align-items-center"
                :class="{
                  'has-text-pink': templateSelected === optionSite.id,
                  'has-text-blue-light': templateSelected !== optionSite.id,
                }"
              >
                <IconFileText />
                <p
                  class="ml-1"
                  :class="{
                    'has-text-pink': templateSelected === optionSite.id,
                  }"
                >
                  {{ $t(`orders.texts.type.${optionSite.order_type_id}`) }}
                </p>
              </li>
              <li
                v-if="optionSite.website.domain"
                class="body1 is-flex is-align-items-center"
                :class="{
                  'has-text-pink': templateSelected === optionSite.id,
                  'has-text-blue-light': templateSelected !== optionSite.id,
                }"
              >
                <IconGlobe />
                <p
                  class="ml-1"
                  :class="{
                    'has-text-pink': templateSelected === optionSite.id,
                  }"
                >
                  {{ optionSite.website.domain }}
                </p>
              </li>
            </ul>
          </b-radio-button>
        </b-field>
      </div>
    </div>
    <div
      v-if="optionsOthersSites && optionsOthersSites.length > 0"
      class="borderTop mb-2"
    >
      <p class="body2 has-text-blue">
        Précédemment utilisés sur d'autres sites
      </p>
      <div>
        <b-field label="" class="mt-4">
          <b-select
            v-model="websiteSelected"
            :placeholder="$t('wizard.brief.filters.sites')"
          >
            <option
              v-for="(site, index) in sites"
              :key="index"
              :value="site.id"
            >
              {{ site.domain }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="columns is-multiline mt-2">
        <b-field
          v-for="option in optionsOthersSites"
          :key="option.id"
          class="column is-4-tablet is-4-desktop is-4-widescreen isheightMaxContent"
        >
          <b-radio-button
            v-model="templateSelected"
            :native-value="option.id"
            type="is-pink-light"
          >
            <ul
              class="is-flex is-flex-direction-column is-justify-content-space-evenly"
            >
              <li>
                <h4
                  :class="{ 'has-text-pink': templateSelected === option.id }"
                >
                  {{ option.name }}
                </h4>
              </li>
              <li
                v-if="option.order_type_id"
                class="body1 is-flex is-align-items-center"
                :class="{
                  'has-text-pink': templateSelected === option.id,
                  'has-text-blue-light': templateSelected !== option.id,
                }"
              >
                <IconFileText />
                <p
                  class="ml-1"
                  :class="{ 'has-text-pink': templateSelected === option.id }"
                >
                  {{ $t(`orders.texts.type.${option.order_type_id}`) }}
                </p>
              </li>
              <li
                v-if="option.website.domain"
                class="body1 is-flex is-align-items-center"
                :class="{
                  'has-text-pink': templateSelected === option.id,
                  'has-text-blue-light': templateSelected !== option.id,
                }"
              >
                <IconGlobe />
                <p
                  class="ml-1"
                  :class="{ 'has-text-pink': templateSelected === option.id }"
                >
                  {{ option.website.domain }}
                </p>
              </li>
            </ul>
          </b-radio-button>
        </b-field>
      </div>
      <CardValidationField
        :field="field.isValid"
        :is-next-step="isNextStep"
        :message="$t('wizard.message.required')"
      />
    </div>
  </div>
</template>

<script>
import CardValidationField from "@/components/UI/card/CardValidationField";
import { mapActions, mapState } from "vuex";

export default {
  name: "TemplateCustomSelect",
  props: ["field", "indexStep", "indexField", "isSmallField", "isNextStep"],
  components: { CardValidationField },

  created() {
    this.websiteId = this.$route.params.id;
    this.templateConfig.filters = this.filters;

    this.getTemplates()
      .then(() => {
        this.options = this.customTemplates.filter(
          (element) => parseInt(element.website_id) === parseInt(this.websiteId)
        );

        this.optionsOthersSites = this.customTemplates.filter(
          (element) => parseInt(element.website_id) !== parseInt(this.websiteId)
        );

        const uniqueWebsiteIds = new Set();

        this.optionsOthersSites.forEach((website) => {
          if (uniqueWebsiteIds.has(website.website_id)) {
            return;
          }

          this.sites.push({
            id: website.website_id,
            domain: website.website.domain,
          });

          uniqueWebsiteIds.add(website.website_id);
        });

        if (
          this.newOrder.template_id &&
          this.customTemplates.find(
            (customTemplate) => customTemplate.id === this.newOrder.template_id
          )
        ) {
          this.templateSelected = this.newOrder.template_id;
        }

        this.isloading = true;
      })
      .catch((e) => {
        this.$store.dispatch("fireError", e);
      });
  },

  data() {
    return {
      sites: [],
      isloading: false,
      templateSelected: this.field.value ? this.field.value : null,
      filters: { is_public: 0, user: 1 },
      options: [],
      optionsOthersSites: [],
      websiteSelected: null,
    };
  },

  watch: {
    templateSelected: function (newValue) {
      this.$emit("save-result", {
        index: this.indexStep,
        index_field: this.indexField,
        value_name: this.field.value_name,
        value: newValue,
      });
    },

    websiteSelected: function (newValue) {
      if (newValue) {
        this.optionsOthersSites = this.customTemplates.filter(
          (element) => parseInt(element.website_id) === parseInt(newValue)
        );
      } else {
        this.optionsOthersSites = this.customTemplates.filter(
          (element) => parseInt(element.website_id) !== parseInt(this.websiteId)
        );
      }
    },
  },

  computed: {
    ...mapState("template", ["customTemplates"]),
    ...mapState("order", ["newOrder"]),

    template: {
      get() {
        return this.$store.state.template.templateSelected;
      },
      set(value) {
        this.$store.commit("template/setTemplateSelected", value);
      },
    },

    templateConfig: {
      get() {
        return this.$store.state.template.templateConfig;
      },
      set(value) {
        this.$store.commit("template/setTemplateConfig", value);
        this.$store.commit("template/setPreserveTemplateState", false);
      },
    },
  },

  methods: {
    ...mapActions("template", ["getTemplates"]),
  },
};
</script>

<style scoped>
.borderTop {
  border-top: 1px solid #dbdce5;
}
</style>
