import { render, staticRenderFns } from "./CreateThematicForm.vue?vue&type=template&id=ce9c2832"
import script from "./CreateThematicForm.vue?vue&type=script&lang=js"
export * from "./CreateThematicForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports