<template>
  <section
    class="section pt-4 pb-6 is-flex is-flex-wrap-wrap is-justify-content-space-between has-background-white"
    :class="{
      'dashboard-header-large': !user.is_active,
    }"
  >
    <article
      class="container is-flex is-justify-content-flex-start is-align-items-center mb-2 ml-0 dashboard-header-1"
    >
      <UserAvatarBigSize
        class="has-border-white rounded"
        :user="user"
        :hasBorderBlue="true"
        size="big"
        imgAlt="photo de l'utilisateur skribix"
      />
      <div class="ml-4">
        <h1 class="p-0">{{ $t("dashboard.hello") }} {{ user.firstname }}</h1>

        <p
          v-if="writer"
          class="body1"
          v-html="
            user.is_active === false
              ? $t('dashboard.greeting_inactive_account')
              : $t('dashboard.greeting')
          "
        ></p>
        <p v-else-if="client" class="body1">
          {{ $t("dashboard.client") }}
        </p>
      </div>
      <div v-if="writer" class="availability-input-wrapper">
        <b-field
          v-if="
            writerPreferences.availability ||
            writerPreferences.availability === false ||
            writerPreferences.availability === null
          "
          label="Disponible"
        >
          <b-switch
            v-model="writerPreferences.availability"
            @input="updateWriterPreference"
          >
            {{ writerPreferences.availability ? "Yes" : "No" }}
          </b-switch>
        </b-field>
      </div>
    </article>
    <div
      v-if="admin || writer || editorManager || corrector || recruiter"
      class="container is-flex is-justify-content-space-between mr-0 mb-2"
    >
      <div
        class="is-flex is-flex-direction-column is-justify-content-center user-role-wrapper"
      >
        <p class="body1">Vous avez les rôles :</p>
        <div v-for="role in roles" :key="role">
          <p class="body1">
            {{ $t(`role.${role}`) }}
            <span v-if="writer && role === 'writer'">
              -
              {{
                $t(
                  `wizard.order.text.writing_level.${writerPreferences.writer_level}`
                )
              }}
            </span>
          </p>
        </div>
      </div>
      <div
        v-if="writer && isLoading"
        class="is-flex is-flex-direction-column is-justify-content-center"
      >
        <span
          class="container is-flex is-flex-wrap-wrap is-justify-content-flex-end"
        >
          <BoxWriterSpace class="mr-2" />
          <BoxWorkshopSkribix />
        </span>
      </div>
    </div>
    <div
      v-else-if="candidate"
      class="container is-flex is-flex-wrap-wrap is-justify-content-flex-end"
    >
      <BoxCandidateSpace class="mr-2" />
      <BoxWorkshopCandidateSkribix />
    </div>
    <div
      v-else
      class="container is-flex is-flex-wrap-wrap is-justify-content-flex-end"
    >
      <BoxSeoWebinar class="mr-2" />
      <BoxExportWordpress />
    </div>
  </section>
</template>

<script>
import BoxSeoWebinar from "@/components/UI/box/BoxSeoWebinar";
import BoxExportWordpress from "@/components/UI/box/BoxReview";
import BoxWriterSpace from "@/components/UI/box/BoxWriterSpace";
import BoxCandidateSpace from "@/components/UI/box/BoxCandidateSpace";
import BoxWorkshopSkribix from "@/components/UI/box/BoxWorkshopSkribix";
import BoxWorkshopCandidateSkribix from "@/components/UI/box/BoxWorkshopCandidateSkribix";
import { mapActions, mapGetters, mapState } from "vuex";
import UserAvatarBigSize from "@/components/Model/User/UserAvatarBigSize";

export default {
  name: "dashboard-header",
  components: {
    BoxExportWordpress,
    BoxSeoWebinar,
    UserAvatarBigSize,
    BoxWriterSpace,
    BoxCandidateSpace,
    BoxWorkshopSkribix,
    BoxWorkshopCandidateSkribix,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    if (this.writer) {
      this.getData();
    }
  },
  computed: {
    ...mapState("auth", ["user", "roles"]),
    ...mapGetters("auth", [
      "admin",
      "writer",
      "client",
      "editorManager",
      "corrector",
      "recruiter",
      "candidate",
    ]),
    writerPreferences: {
      get() {
        return this.$store.state.writer.writerPreferences;
      },
      set(value) {
        this.$store.commit("writer/setWriterPreferences", value);
      },
    },
  },
  methods: {
    ...mapActions("writer", ["editWriterPreference", "getWriterPreference"]),
    getData() {
      this.isLoading = false;
      this.getWriterPreference(this.user.reference)
        .then(() => {
          this.isLoading = true;
        })
        .catch((e) => {
          this.$store.dispatch("fireError", e);
        });
    },
    updateWriterPreference() {
      this.editWriterPreference(this.user.reference)
        .then(() => {
          this.isLoading = true;
        })
        .catch((e) => {
          this.$store.dispatch("fireError", e);
        });
    },
  },
};
</script>

<style scoped lang="scss">
@media (min-width: 1717px) {
  .availability-input-wrapper {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 1720px) {
  .dashboard-header-1 {
    max-width: 100% !important;
  }

  .availability-input-wrapper {
    margin-left: auto;
  }
}

.dashboard-header-large {
  & > article,
  & > div {
    max-width: 100% !important;

    .availability-input-wrapper {
      min-width: 28%;
      display: flex;
      justify-content: center;
    }

    .user-role-wrapper {
      margin-left: 4.3%;
    }
  }
}
</style>
