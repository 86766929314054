<template>
  <Modal ref="baseModal">
    <template v-slot:header>
      <h3 v-if="option?.id">Modifier une option</h3>
      <h3 v-else>Ajouter une option</h3>

      <CloseModalButton @click="hide" />
    </template>

    <div v-if="option">
      <CreateThematicForm @saved="saved" />
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/UI/Modal";
import CloseModalButton from "@/components/UI/buttons/CloseModalButton";
import CreateThematicForm from "../Form/CreateOptionForm";
import { mapState } from "vuex";
export default {
  name: "CreateOptionModal",
  components: { CreateThematicForm, CloseModalButton, Modal },
  computed: {
    ...mapState("option", {
      option: (state) => state.option,
      options: (state) => state.options,
    }),
  },
  methods: {
    show() {
      this.$refs.baseModal.show();
    },
    hide() {
      this.$refs.baseModal.hide();
    },
    saved() {
      this.$emit("saved");
      this.hide();
    },
  },
};
</script>
