<template>
  <b-select
    placeholder="Choisissez une facturation"
    v-model="billingTypeModel"
    @change.native="handleChange()"
  >
    <option :value="1">Facturé par mot</option>
    <option :value="2">Facturé par texte</option>
    <option :value="3">Facturé par commande</option>
    <option :value="4">Facturé par unité</option>
  </b-select>
</template>

<script>
export default {
  name: "BillingType",
  props: ["billingType"],
  model: {
    props: "billingType",
    event: "change",
  },
  methods: {
    handleChange() {
      this.$emit("change", this.billingTypeModel);
    },
  },
  data() {
    return {
      billingTypeModel: 1,
    };
  },
  created() {
    this.billingTypeModel = this.billingType ?? 1;
  },
};
</script>
