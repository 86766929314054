<template>
  <div>
    <Modal
      v-if="!isModalCreateActive"
      ref="baseModal"
      class="is-fullheight left-modal"
    >
      <template v-slot:header>
        <h3 v-if="user?.reference">
          Modifier {{ user.firstname }} {{ user.lastname }}
        </h3>
        <h3 v-else>Ajouter un utilisateur</h3>

        <CloseModalButton @click="hide" />
      </template>

      <div>
        <AddUserForm
          @open-modal-add-company="openModalAddCompany"
          @user-saved="hideAndRefreshData"
          @user-deleted="hideAndRefreshData"
        />
      </div>
    </Modal>
    <CreateCompanyModal
      :user-reference="user?.reference"
      :is-modal-create-active="isModalCreateActive"
      @close-modal-add-company="closeModalAddCompany"
      @save-company="saveCompany"
    />
  </div>
</template>

<script>
import Modal from "@/components/UI/Modal";
import CloseModalButton from "@/components/UI/buttons/CloseModalButton";
import { mapState } from "vuex";
import AddUserForm from "@/components/Model/User/Form/AddUserForm";
import CreateCompanyModal from "@/components/Model/User/Modal/CreateCompanyModal";

export default {
  name: "CreateUserModal",
  components: {
    CreateCompanyModal,
    AddUserForm,
    CloseModalButton,
    Modal,
  },
  computed: {
    ...mapState("user", {
      user: (state) => state.user,
    }),
    companySearch: {
      get() {
        return this.$store.state.company.companySearch;
      },
      set(value) {
        this.$store.commit("company/setCompanySearch", value);
      },
    },
    passwordGenerated: {
      get() {
        return this.$store.state.user.passwordGenerated;
      },
      set(value) {
        this.$store.commit("user/setPasswordGenerated", value);
      },
    },
  },
  data() {
    return {
      isModalCreateActive: false,
    };
  },
  methods: {
    show() {
      this.$refs.baseModal.show();
    },

    hide() {
      this.companySearch = null;
      this.passwordGenerated = null;
      this.$refs.baseModal.hide();
    },

    hideAndRefreshData() {
      this.$emit("get-data");
      this.hide();
    },

    openModalAddCompany() {
      this.isModalCreateActive = true;
    },

    saveCompany(response) {
      this.companySearch = response.name;
      this.user.company_id = response.id;
      this.closeModalAddCompany();
    },

    closeModalAddCompany() {
      this.isModalCreateActive = false;
    },
  },
};
</script>
