<template>
  <div>
    <main class="section" v-if="isLoading && fields">
      <form
        class="column is-12-mobile is-12-tablet is-12-desktop is-12-widescreen is-12-fullhd is-flex is-flex-direction-column is-justify-content-space-evenly m-auto"
      >
        <div
          v-for="(element, index) in fields"
          class="my-1"
          :key="index"
          style="height: 100%"
        >
          <p class="has-text-blue bold mb-2" v-if="element.title">
            {{ element.title }}
          </p>
          <component
            :key="index"
            :indexStep="index"
            :indexField="index"
            :is="element.name"
            :field="element"
            :isSmallField="element.small"
            :isNextStep="isNextStep"
            @save-result="saveResult"
            :isNotWizard="true"
            class="is-flex is-flex-direction-column is-justify-content-space-evenly mt-1"
          />
        </div>
      </form>
    </main>
  </div>
</template>

<script>
import BriefUpload from "@/components/Model/Form/FormItem/BriefUpload";
import BriefText from "@/components/Model/Form/FormItem/BriefText";
import BriefPassword from "@/components/Model/Form/FormItem/BriefPassword";
import PhoneInput from "@/components/Model/Form/FormItem/PhoneInput";
import BriefUrl from "@/components/Model/Form/FormItem/BriefUrl";
import BriefTextarea from "@/components/Model/Form/FormItem/BriefTextarea";
import ThematicsMultiSelect from "@/components/Model/Form/FormItem/ThematicsMultiSelect";
import OrderTextOptionsFormItem from "@/components/Model/Form/OrderFormItem/OrderTextOptionsFormItem";
import OrderTypesMultiSelect from "@/components/Model/Form/FormItem/OrderTypesMultiSelect";
import LocalesMultiSelect from "@/components/Model/Form/FormItem/LocalesMultiSelect";
import { mapGetters } from "vuex";
export default {
  name: "ProfilTextExample",
  props: ["field", "indexStep", "indexField"],
  components: {
    BriefUpload,
    BriefPassword,
    BriefUrl,
    ThematicsMultiSelect,
    LocalesMultiSelect,
    OrderTextOptionsFormItem,
    BriefText,
    BriefTextarea,
    OrderTypesMultiSelect,
    PhoneInput,
  },
  data() {
    return {
      isLoading: true,
      isNextStep: false,
      fields: [],
      regex:
        "https?:\\/\\/(?:www\\.)?([-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b)*(\\/[\\/\\d\\w\\.-]*)*(?:[\\?])*(.+)*",
    };
  },
  computed: {
    ...mapGetters("auth", ["candidate", "writer"]),
    candidateProfile: {
      get() {
        return this.$store.state.user.candidateProfile;
      },
      set(value) {
        this.$store.commit("user/setCandidateProfile", value);
      },
    },
    hasError() {
      if (this.candidateProfile.website) {
        if (this.candidateProfile.website.match(this.regex)) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },

    fieldsUserProfile() {
      return [
        {
          title: "Vos textes d'exemples (pdf,doc,docx,txt)",
          name: "BriefUpload",
          value: null,
          value_name: "textsUpload",
          required: true,
          maxSize: 2, // 2Mo
          acceptedExt: ["pdf", "doc", "docx", "txt"],
          initValue: () => {
            return Array.isArray(this.candidateProfile.textsUpload)
              ? this.candidateProfile.textsUpload
              : null;
          },
        },
      ];
    },
  },

  created() {
    this.fields = this.fieldsUserProfile;
  },

  methods: {
    saveResult(result) {
      if (result.index === 5 || result.index === 6) {
        this.candidateProfile.preferences[result.value_name] = result.value;
      } else if (result.country_code) {
        this.candidateProfile["country_code"] = result.country_code;
        this.candidateProfile[result.value_name] = result.value;
      } else {
        this.candidateProfile[result.value_name] = result.value;
      }

      this.fields[result.index_field].value = result.value;
      this.fields[result.index_field].hasError = result.hasError;
    },

    validStep(steps) {
      this.isNextStep = true;

      steps.forEach((element) => {
        if (element.required === true) {
          if (
            (Array.isArray(element.value) &&
              element.value.every((elem) => elem === null)) ||
            (Array.isArray(element.value) && element.value.length === 0) ||
            (Array.isArray(element.value) && element.hasError)
          ) {
            element.isValid = false;
          } else if (
            element.value & (typeof element.value === "object") &&
            Object.keys(element.value) &&
            Object.keys(element.value).length === 0
          ) {
            element.isValid = false;
          } else if (element.isUrlValid && element.value) {
            if (element.value.match(this.regex)) {
              element.isValid = true;
            } else {
              element.isValid = false;
            }
          } else if (
            !element.value ||
            element.value === " " ||
            element.hasError
          ) {
            element.isValid = false;
          } else {
            element.isValid = true;
          }
        } else if (element.isUrlValid && element.value) {
          if (element.value.match(this.regex)) {
            element.isValid = true;
          } else {
            element.isValid = false;
          }
        } else {
          if (element.hasError) {
            element.isValid = false;
          } else {
            element.isValid = true;
          }
        }
      });

      if (steps.filter((element) => element.isValid === false).length === 0) {
        steps.isStepValid = true;
      } else {
        steps.isStepValid = false;
      }
    },
  },
};
</script>
<style scoped>
.section {
  padding: 0;
}
</style>
