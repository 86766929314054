<template>
  <div>
    <main class="section" v-if="isLoading">
      <form
        class="column is-12-mobile is-12-tablet is-12-desktop is-12-widescreen is-12-fullhd is-flex is-flex-direction-column is-justify-content-space-evenly m-auto"
      >
        <div
          v-for="(element, index) in fieldsUserProfile"
          class="my-1"
          :key="index"
          style="height: 100%"
        >
          <p class="has-text-blue bold" v-if="element.title">
            {{ element.title }}
          </p>
          <component
            :key="index"
            :indexStep="index"
            :indexField="index"
            :is="element.name"
            :field="element"
            :isSmallField="element.small"
            :isNextStep="isNextStep"
            @save-result="saveResult"
            :isNotWizard="true"
            class="is-flex is-flex-direction-column is-justify-content-space-evenly mt-1"
          />
        </div>
      </form>
    </main>
  </div>
</template>

<script>
import BrieWysiwyg from "@/components/Model/Form/FormItem/BrieWysiwyg";

export default {
  name: "ProfilFormItem",
  props: ["field", "indexStep", "indexField"],
  components: {
    BrieWysiwyg,
  },
  data() {
    return {
      isLoading: true,
      isNextStep: false,
    };
  },
  watch: {
    website: function (newValue) {
      this.$emit("save-result", {
        index: this.indexStep,
        index_field: this.indexField,
        value_name: this.field.value_name,
        value: newValue,
        hasError: this.hasError,
      });
    },
  },
  computed: {
    candidateProfile: {
      get() {
        return this.$store.state.user.candidateProfile;
      },
      set(value) {
        this.$store.commit("user/setCandidateProfile", value);
      },
    },
    fieldsUserProfile() {
      return [
        {
          id: 1,
          title: "Description",
          name: "BrieWysiwyg",
          value_name: "introduction",
          required: true,
          value: this.candidateProfile.preferences.introduction
            ? this.candidateProfile.preferences.introduction
            : "<b>Introduction</b>Bonjour, je suis… rédactrice/rédacteur… \r\r <h2>Comment es-tu venu à la rédaction web ?</h2>Décris ton parcours avant la rédaction et ce qui t’a amené à ce métier. \r\r <h2>Quels sont les thèmes sur lesquels tu préfères rédiger ?</h2>Détaille tes expériences : \r <b>-les thématiques liées à ta formation initiale, tu peux préciser laquelle \r-les thèmes en lien avec tes expériences professionnelles précédentes (même en dehors de la rédaction) \r-les sujets en rapport avec tes passions personnelles, tes hobbies, tes soft skills </b>\r Quels sujets à l'inverse ne souhaites-tu pas du tout traiter ? \r\r <h2>Parmi tes expériences dans la rédaction, sur quels sujets as-tu rédigé ?</h2>Précise tes références : as-tu travaillé pour ces entreprises sur le long terme, le moyen terme, de façon récurrente ? \r\r <h2>Quels traits de personnalité confortent le professionnel que tu es ?</h2>Parle-nous de tes soft skills. \r\r <h2>Présentation plus détaillée de toi</h2>Tu n’es pas qu’une rédactrice ou un rédacteur. Parle-nous de l’homme ou de la femme que tu es.",
        },
      ];
    },
  },
  methods: {
    saveResult(result) {
      this.candidateProfile.preferences[result.value_name] = result.value;
      this.fieldsUserProfile[result.index_field].value = result.value;
    },

    validStep(steps) {
      this.isNextStep = true;
      steps.forEach((element) => {
        if (element.required === true) {
          if (
            !this.candidateProfile.preferences[element.value_name] ||
            this.candidateProfile.preferences[element.value_name] === " " ||
            element.hasError
          ) {
            element.isValid = false;
          } else {
            element.isValid = true;
          }
        } else {
          if (element.hasError) {
            element.isValid = false;
          } else {
            element.isValid = true;
          }
        }
      });

      if (steps.filter((element) => element.isValid === false).length === 0) {
        steps.isStepValid = true;
      } else {
        steps.isStepValid = false;
      }
    },
  },
};
</script>
<style scoped>
.section {
  padding: 0;
}
</style>
