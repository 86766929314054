<template>
  <div>
    <main class="section" v-if="isLoading">
      <div v-if="candidateProfile.preferences.contract_signed && !writer">
        <p>
          Maintenant que tu as complété ton compte, tu vas pouvoir accéder aux
          commandes de test.
        </p>
        <p>
          Lis bien le briefing et choisis un sujet (1 seul) parmi les textes
          proposés.
        </p>
        <p>
          Si tu as des questions, n'hésites pas à interroger l'équipe dans
          l'onglet Suivi de commande ou sur Discord.
        </p>
        <p>Bonne chance !</p>
      </div>
      <div v-if="!candidateProfile.preferences.contract_signed && !writer">
        <p>Vous devez signer votre contrat pour aller plus loin.</p>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CreateWriterAccount",
  props: ["field", "indexStep", "indexField"],
  components: {},
  data() {
    return {
      isLoading: true,
      isNextStep: false,
    };
  },

  computed: {
    ...mapGetters("auth", ["writer", "candidate"]),
    candidateProfile: {
      get() {
        return this.$store.state.user.candidateProfile;
      },
      set(value) {
        this.$store.commit("user/setCandidateProfile", value);
      },
    },
  },
};
</script>
<style scoped>
.section {
  padding: 0;
}
p {
  margin-bottom: 40px;
}
</style>
