<template>
  <div>
    <b-loading :active.sync="firstDataFetching" />

    <b-table
      v-if="thematics"
      :data="thematics"
      :default-sort="[config.sort, config.sortDirection]"
      :loading="loading"
      class="table-striped"
      :narrowed="true"
      :mobile-cards="true"
      :per-page="config.per_page"
      :show-detail-icon="true"
      :striped="true"
      backend-filtering
      backend-sorting
      @sort="onSort"
      @filters-change="onFilterChange"
    >
      <b-table-column
        field="id"
        label="#"
        sortable
        centered
        numeric
        v-slot="props"
      >
        <p class="body1 has-text-grey-darker">
          {{ props.row.id }}
        </p>
      </b-table-column>

      <b-table-column sortable field="parent_id" label="Parent" v-slot="props">
        <p class="body1 has-text-primary" v-if="props.row.parent">
          {{ props.row.parent.name }}
        </p>
      </b-table-column>

      <b-table-column field="name" label="Nom" sortable v-slot="props">
        <p class="body1 has-text-grey-darker">
          {{ props.row.name }}
        </p>
      </b-table-column>

      <b-table-column
        field="price"
        label="Prix client"
        sortable
        numeric
        v-slot="props"
      >
        <p class="body1 has-text-grey-darker">
          <span v-if="props.row.price > 0">
            {{ props.row.price | toCurrency }}
          </span>
        </p>
      </b-table-column>

      <b-table-column
        field="writer_price"
        label="Prix rédacteur"
        sortable
        numeric
        v-slot="props"
      >
        <p class="body1 has-text-grey-darker">
          <span v-if="props.row.writer_price > 0">
            {{ props.row.writer_price | toCurrency }}
          </span>
        </p>
      </b-table-column>

      <b-table-column
        field="billing_type"
        label="Type de facturation"
        sortable
        centered
        v-slot="props"
      >
        <p class="body1 has-text-grey-darker">
          <BillingType
            v-if="props.row.price > 0"
            :billing-type="props.row.billing_type"
          />
        </p>
      </b-table-column>

      <b-table-column
        field="created_at"
        label="Créé le"
        sortable
        centered
        numeric
        v-slot="props"
      >
        <p class="body1 has-text-grey-darker">
          {{ props.row.created_at | toDate }}
        </p>
      </b-table-column>

      <b-table-column
        field=""
        label="Action"
        v-slot="props"
        class="is-right has-text-right"
      >
        <b-button
          href="#"
          class="has-text-blue mb-1"
          @click.prevent="edit(props.row)"
        >
          <IconPen class="mt-1" />
        </b-button>

        <b-button
          href="#"
          class="has-text-blue"
          @click.prevent="confirm(props.index, props.row)"
        >
          <iconDelete class="mt-1" />
        </b-button>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BillingType from "@/components/Model/Billing/BillingType";
export default {
  name: "ThematicsTable",
  components: { BillingType },
  data() {
    return {
      loading: true,
      firstDataFetching: true,
    };
  },
  created() {
    this.loadAsyncData(false);
  },
  computed: {
    ...mapState("thematic", {
      thematics: (state) => state.thematics,
      config: (state) => state.thematicsConfig,
    }),
    thematics: {
      get() {
        return this.$store.state.thematic.thematics;
      },
      set(value) {
        this.$store.commit("thematic/setThematics", value);
      },
    },
  },
  methods: {
    ...mapActions("thematic", ["getThematics", "deleteThematic"]),
    edit(thematic) {
      thematic.translations.forEach((translation) => {
        thematic[translation.locale] = { name: translation.name };
      });

      this.$store.state.locales.forEach((locale) => {
        if (!thematic[locale]) {
          thematic[locale] = { name: null };
        }
      });

      let parent = this.thematics.find(
        (item) => item.id === thematic.parent_id
      );

      if (!parent) {
        parent = { name: "" };
      }

      thematic.parent = parent;

      this.$store.commit("thematic/setThematic", thematic);
      this.$emit("show-popup");
    },
    loadAsyncData(reload) {
      this.loading = true;

      if (this.thematics && !reload) {
        this.firstDataFetching = false;
        this.loading = false;
      } else {
        this.getThematics().finally(() => {
          this.firstDataFetching = false;
          this.loading = false;
        });
      }
    },
    onSort(field, order) {
      this.config.sort = field;
      this.config.sortDirection = order;

      this.loadAsyncData(true);
    },
    onFilterChange(filter) {
      let filters = {};
      Object.keys(filter).forEach((element) => {
        filters[element] = filter[element];
      });
      this.config.filters = filters;

      this.loadAsyncData(true);
    },
    onPageChange(page) {
      this.config.page = page;

      this.loadAsyncData(true);
    },
    confirm(index, thematic) {
      this.$buefy.dialog.confirm({
        message: "Continue on this task?",
        onConfirm: () => this.doDelete(index, thematic),
      });
    },
    doDelete(index, thematic) {
      this.loading = true;

      this.deleteThematic(thematic.id)
        .then(() => {
          this.thematics.splice(index, 1);
          this.$store.dispatch("notify", {
            type: "is-success",
            message: "La thématique a bien été supprimée",
          });
        })
        .catch((e) => {
          this.$store.dispatch("fireError", e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
