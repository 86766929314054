<template>
  <article class="hero box p-4 is-pink-light my-2">
    <figure>
      <img
        style="height: 7rem; width: 14rem"
        src="/img/box/workshop.png"
        title="illustration Ateliers Skribix"
        alt="illustration Ateliers Skribix"
      />
    </figure>
    <h4 class="has-text-pink">Les ateliers Skribix</h4>
    <p class="body1 has-text-pink">Pour te perfectionner !</p>
    <a
      class="mt-4 has-text-pink is-flex is-align-items-center"
      href="https://academy.skribix.com/bundles/redacteurs-skribix-fr"
      target="_blank"
    >
      S'inscrire <IconArrowRight class="ml-2" />
    </a>
  </article>
</template>
<script>
export default {
  name: "BoxWorkshopSkribix",
};
</script>
