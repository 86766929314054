<template>
  <main class="columns is-desktop">
    <div class="column is-flex is-align-items-center">
      <div
        class="registerForm m-auto is-flex is-flex-direction-column is-justify-content-space-evenly px-3 py-4"
      >
        <h2 class="pb-2">Inscrivez vous gratuitement</h2>

        <ClientRegistrationForm />
      </div>
    </div>
    <div
      class="column has-background-white p-5 is-flex is-flex-wrap-wrap is-justify-content-space-evenly min-fullheight"
    >
      <div
        class="whiteBackground has-background-white is-flex is-flex-wrap-wrap is-align-items-center"
      >
        <div>
          <router-link to="/login">
            <img src="/img/logo-skribix.png" alt="Logo Skribix" class="mb-2" />
          </router-link>

          <h2 class="mb-5">
            Nous écrivons des textes
            <br />que les gens aiment... <br />et que
            <span class="has-text-pink"> Google adore !</span>
          </h2>
          <ul
            class="testimonyCard is-flex is-flex-direction-column is-justify-content-space-evenly m-2"
          >
            <li class="is-flex py-2">
              <UserAvatarBigSize
                :user="{
                  avatar: chosenTestimony.image,
                }"
                size="medium"
                :imgAlt="`Photo : ${chosenTestimony.user}, ${chosenTestimony.job} chez ${chosenTestimony.company}`"
              />
              <div>
                <p class="body1 has-text-blue bold">
                  {{ chosenTestimony.user }}
                </p>
                <p class="body2">
                  {{ chosenTestimony.job }}
                  <span class="has-text-pink">
                    {{ chosenTestimony.company }}
                  </span>
                </p>
              </div>
            </li>
            <li
              class="card has-border-none has-background-pink-light px-4 py-2"
            >
              <p class="body1 has-text-blue jagged-bottom">
                {{ chosenTestimony.testimony }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import UserAvatarBigSize from "@/components/Model/User/UserAvatarBigSize";
import ClientRegistrationForm from "@/components/Model/Guest/ClientRegistrationForm.vue";

export default {
  name: "ClientRegistrationView",
  title: "title.auth.client_registration",
  components: { UserAvatarBigSize, ClientRegistrationForm },
  data() {
    return {
      chosenTestimony: {},
      testimonies: [
        {
          user: "Gabin Berthet",
          image: "/img/testimonies/gabin.jpg",
          job: "Chef de Projet SEO ",
          company: "@Nocsy",
          testimony:
            "Parfait ! Je ne peux que recommander le rédacteur qui a su s'adapter à un sujet très spécifique. Les recherches sont précises et sérieuses. Bravo !",
        },
        {
          user: "Christophe Bernard",
          image: "/img/testimonies/christophe.jpg",
          job: "Head of SEO",
          company: "@Ymanci",
          testimony:
            "Depuis longtemps je voulais travailler avec Morgane et son agence. C'est enfin fait et je suis ravi par la qualité des textes qui ont été produits. C'est le début d'une collaboration qui va s'inscrire dans la durée.",
        },
        {
          user: "Dan Bernier",
          image: "/img/testimonies/dan_bernier.png",
          job: "Fondateur",
          company: "@Inéolab",
          testimony:
            "Réponse très professionnelle et très rapide. Le contenu correspond à la demande. Les conseils sont de bonnes factures et permettent de comprendre ce qui va être fait. Rien à dire, le travail est très bon : je recommande et je re commanderai !",
        },
        {
          user: "Jean Baptiste Martin",
          image: "/img/testimonies/jb-martin.png",
          job: "Head of SEO",
          company: "@Plus que Pro",
          testimony:
            "Parmi la multitude de prestataires proposant de la rédaction de contenus, Skribix se détache du lot en proposant un service avancé, comprenant l’optimisation SEO à partir de la seule requête cible ainsi que la recherche documentaire. Le résultat est l’élaboration de textes pertinents pour les visiteurs et performants pour les moteurs de recherche, à partir de briefs rapides à préparer. Enfin, le respect des délais de livraison est tenu, ce qui permet d’établir des calendriers éditoriaux faciles à respecter.",
        },
      ],
    };
  },
  beforeMount() {
    this.chosenTestimony =
      this.testimonies[Math.floor(Math.random() * this.testimonies.length)];
  },
};
</script>
<style scoped>
.summaryOrderCard,
.testimonyCard {
  width: 75%;
}
.registerForm .image {
  width: 20vh;
  height: 20vh;
}
.jagged-bottom {
  position: relative;
}
.jagged-bottom:after {
  background: #fef2f5;
  content: "";
  height: 2em;
  width: 2em;
  position: absolute;
  top: -1em;
  left: -1em;
  right: 0;
  clip-path: polygon(100% 50%, 50% 0, 5% 50%);
}

@media (min-width: 768px) {
  .registerForm {
    min-height: 70%;
  }
  .whiteBackground {
    width: 100%;
    min-height: max-content;
  }
  .min-fullheight {
    min-height: 100vh;
  }
}
</style>
