<template>
  <div>
    <main class="section" v-if="isLoading">
      <p>
        Tous les rédacteurs signent obligatoirement un contrat de prestation de
        services.
      </p>
      <p>
        Tu dois donc le compléter, ajouter les documents demandés et le signer.
      </p>
      <p>
        Pour rappel, ta relation avec Skribix est strictement confidentielle, il
        t'es donc interdit de mentionner Skribix en tant qu'employeur ou client
        sur Linkedin, Malt, ton site Internet ou tout autre support.
      </p>

      <p v-if="false">
        Tu as reçu sur ton email {{ candidateProfile.email }} le code pour
        signer ton contrat.
      </p>

      <b-button
        v-if="!candidateProfile.preferences.contract_signed"
        inverted
        type="is-blue"
        icon-pack="fas"
        icon-left="arrow-right"
        class="button is-large has-border-blue has-hover-bottom ml-2"
        @click="onClick"
      >
        Signer ton contrat
      </b-button>
      <CardValidationField
        v-if="hasError && hasErrorMessage"
        :is-next-step="isNextStep"
        :message="hasErrorMessage"
      />

      <div v-if="candidateProfile.preferences.contract_signed">
        <h3>Ton Contrat a déjà été signé</h3>
      </div>
    </main>
  </div>
</template>

<script>
import CardValidationField from "@/components/UI/card/CardValidationField";
export default {
  name: "ContractFormItem",
  props: ["field", "indexStep", "indexField"],
  components: { CardValidationField },
  data() {
    return {
      isLoading: true,
      isNextStep: false,
      componentReady: true,
      hasError: false,
      hasErrorMessage:
        "Merci de signer ton contrat. Si cela a déjà été fait, recharger la page.",
    };
  },

  computed: {
    candidateProfile: {
      get() {
        return this.$store.state.user.candidateProfile;
      },
      set(value) {
        this.$store.commit("user/setCandidateProfile", value);
      },
    },
    fieldsUserProfile() {
      return [
        {
          value_name: "contract",
          required: true,
        },
      ];
    },
  },
  methods: {
    onClick() {
      window.open(this.candidateProfile.embedded_signing_url, "_self");
    },
    validStep(steps) {
      this.isNextStep = true;
      steps.forEach((element) => {
        if (element.required === true) {
          if (this.candidateProfile.preferences.contract_signed) {
            element.isValid = true;
          } else {
            this.hasError = true;
            element.isValid = false;
          }
        }
      });

      if (steps.filter((element) => element.isValid === false).length === 0) {
        steps.isStepValid = true;
      } else {
        steps.isStepValid = false;
      }
    },
  },
};
</script>
<style scoped>
.section {
  padding: 0;
}
p {
  margin-bottom: 40px;
}
</style>
