<template>
  <div class="main">
    <b-loading :active.sync="dataFetching" />

    <div class="px-5">
      <div class="columns">
        <div class="column">
          <h2>Sociétés</h2>

          <CompaniesTable
            v-if="!dataFetching"
            @create-company="createCompany"
            @get-data="getData"
          />
        </div>
      </div>
    </div>

    <CreateCompanyModal
      ref="companyModal"
      @get-data="getData"
      :isModalCreateActive="isModalCreateActive"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CompaniesTable from "@/components/Model/Company/Tables/CompaniesTable.vue";
import CreateCompanyModal from "@/components/Model/Company/Modal/CreateCompanyModal";
export default {
  name: "AdminCompany",
  components: {
    CreateCompanyModal,
    CompaniesTable,
  },
  data() {
    return {
      dataFetching: true,
      isModalCreateActive: false,
    };
  },
  computed: {
    ...mapState("user", {
      config: (state) => state.usersConfig,
    }),
    company: {
      get() {
        return this.$store.state.company.company;
      },
      set(value) {
        this.$store.commit("company/setCompany", value);
      },
    },
    isUserSend: {
      get() {
        return this.$store.state.user.isUserSend;
      },
      set(value) {
        this.$store.commit("user/setIsUserSend", value);
      },
    },
  },
  mounted() {
    this.config.include = "roles,company";
    this.getData();
  },
  methods: {
    ...mapActions("company", ["getCompanies"]),
    getData() {
      this.dataFetching = true;
      let getCompanyPromise = this.getCompanies();
      Promise.all([getCompanyPromise])
        .catch((e) => {
          this.$store.dispatch("fireError", e);
        })
        .finally(() => {
          this.dataFetching = false;
        });
    },
    createCompany(company) {
      this.company = company;
      this.isModalCreateActive = true;
      this.$refs.companyModal.show();
      this.isUserSend = false;
    },
  },
};
</script>
