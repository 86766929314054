import { render, staticRenderFns } from "./TemplateCreateOrUpdateModal.vue?vue&type=template&id=661de8d8&scoped=true"
import script from "./TemplateCreateOrUpdateModal.vue?vue&type=script&lang=js"
export * from "./TemplateCreateOrUpdateModal.vue?vue&type=script&lang=js"
import style0 from "./TemplateCreateOrUpdateModal.vue?vue&type=style&index=0&id=661de8d8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "661de8d8",
  null
  
)

export default component.exports