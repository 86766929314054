<template>
  <article class="hero box p-4 m-0 is-white my-2">
    <figure>
      <img
        style="height: 7rem; width: 14rem"
        src="/img/box/space.png"
        title="illustration Espace rédacteur"
        alt="illustration Espace rédacteur"
      />
    </figure>
    <h4>L'espace Candidat</h4>
    <p class="body1">Tout sur l'onboarding Skribix !</p>
    <a
      class="mt-4 has-text-blue-light is-flex is-align-items-center"
      href="https://espace-redacteur.skribix.com?msopen=/member/plans/c39a791e0p"
      target="_blank"
    >
      Voir la documentation <IconArrowRight class="ml-2" />
    </a>
  </article>
</template>
<script>
export default {
  name: "BoxCandidateSpace",
};
</script>
