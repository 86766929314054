<template>
  <section class="section is-fullheight">
    <div class="container is-fullheight">
      <div class="columns is-centered is-align-items-center is-fullheight">
        <div class="column is-6-tablet is-5-desktop is-4-widescreen">
          <form class="box has-background-white">
            <h5 class="mb-2 has-text-centered">
              Renouvellement de mot de passe
            </h5>
            <div class="field">
              <label for="email" class="label">Email</label>
              <input
                id="email"
                v-model="email"
                autocomplete="email"
                autofocus
                class="input has-background-white"
                name="email"
                placeholder="Email"
                required
                type="email"
              />
            </div>
            <p
              v-if="isSubmit && mailMessage"
              class="body2 has-text-danger mb-2"
            >
              {{ mailMessage }}
            </p>
            <div class="field">
              <label for="password" class="label">Mot de passe</label>
              <input
                id="password"
                v-model="password"
                autocomplete="current-password"
                class="input has-background-white"
                name="password"
                placeholder="Mot de passe"
                required
                type="password"
              />
            </div>
            <p
              v-if="isSubmit && passwordMessage"
              class="body2 has-text-danger mb-2"
            >
              {{ passwordMessage }}
            </p>
            <div class="field">
              <label for="password" class="label">
                Confirmation de mot de passe
              </label>
              <input
                id="passwordConfirmation"
                v-model="passwordConfirmation"
                autocomplete="current-password"
                class="input has-background-white"
                name="password"
                placeholder="Confirmez votre mot de passe"
                required
                type="password"
              />
            </div>
            <div class="is-flex is-justify-content-space-between">
              <button
                v-show="!loading"
                class="button is-blue"
                @click.prevent="resetPwdHandler"
              >
                {{ $t("auth.send") }}
              </button>
              <button
                v-show="loading"
                class="btn btn-primary btn-auth-loading btn-block waves-effect waves-themed"
                disabled="disabled"
                type="button"
              >
                <span
                  aria-hidden="true"
                  class="spinner-border spinner-border-sm"
                  role="status"
                ></span>
                {{ $t("auth.send") }}...
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.h2 {
  margin: 0.5em 0 1em 0;
}
</style>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  title: "title.auth.login",
  name: "ResetPwd",
  data: function () {
    return {
      email: "",
      password: "",
      passwordConfirmation: "",
      isSubmit: false,
      isForgotPasswordSubmit: false,
      loading: false,
      mailregExp:
        /^((\w[^W]+)[.-]?){1,}@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      mailMessage: "",
      passwordMessage: "",
      isForgotPwd: false,
      token: "",
    };
  },
  created() {
    this.token = this.$route.query.token;
  },
  computed: {
    ...mapState("auth", ["isConnected"]),
    ...mapGetters("auth", ["homepage"]),
  },
  methods: {
    ...mapActions("auth", {
      resetPwd: "resetPassword",
    }),
    resetPwdHandler() {
      this.loading = true;
      this.isSubmit = true;
      this.confirmMail(this.email);
      this.confirmPassword(this.password, this.passwordConfirmation);

      if (this.mailMessage || this.passwordMessage) {
        this.loading = false;
        return;
      }

      this.resetPwd({
        token: this.token,
        email: this.email,
        password: this.password,
        password_confirmation: this.passwordConfirmation,
      })
        .then(() => {
          this.loading = false;
          this.$store.dispatch("notify", {
            type: "is-success",
            message: `Votre nouveau mot de passe a bien été enregistré`,
          });
          this.isSubmit = false;
          this.$router.push("/login");
        })
        .catch((e) => {
          this.isSubmit = false;
          this.loading = false;

          if (e.response.data.status === "passwords.token") {
            this.$store.dispatch("notify", {
              type: "is-danger",
              message:
                "Le lien de réinitialisation de mot de passe n'est plus valide ou a dépassé le délai. Veuillez renvoyer votre demande.",
            });
            this.redirectToForgotPassword();
            return;
          }

          this.$store.dispatch("fireError", e);
        });
    },
    // vérifie la saisie du champ email
    confirmMail(email) {
      if (!email) {
        this.mailMessage = this.$t("error.validation.required");
      } else if (!this.mailregExp.test(email)) {
        this.mailMessage = this.$t("error.validation.mail_valid");
      } else {
        this.mailMessage = "";
      }
    },
    confirmPassword(pwd, confirmpwd) {
      if (!pwd) {
        this.passwordMessage = this.$t("error.validation.required");
      } else if (pwd.length < 8) {
        this.passwordMessage =
          "Le mot de passe doit contenir au moins 8 caractères";
      } else if (pwd !== confirmpwd) {
        this.passwordMessage = this.$t("error.validation.password_mismatch");
      } else {
        this.passwordMessage = "";
      }
    },
    redirectToForgotPassword() {
      this.$router.push({
        path: "/login",
        query: { isForgotPwd: true },
      });
    },
  },
};
</script>
