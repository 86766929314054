<template>
  <div>
    <b-loading :active.sync="blocksFetching" />

    <div v-if="componentReady">
      <template v-if="!isAdminForm">
        <b-modal
          v-model="isModalCreateOrUpdateActive"
          trap-focus
          has-modal-card
          aria-role="dialog"
          aria-label="enregister un gabarit de texte"
          close-button-aria-label="Close"
          :aria-modal="true"
          :destroy-on-hide="false"
        >
          <CreateOrUpdateModal
            @edit-template="editTemplateHandler"
            @create-template="openCreateModal"
            @valid-existing-template="validExistingTemplate"
            :isExistingTemplate="true"
          />
        </b-modal>
        <b-modal
          v-model="isModalCreateActive"
          trap-focus
          has-modal-card
          aria-role="dialog"
          aria-label="enregister un gabarit de texte"
          close-button-aria-label="Close"
          :aria-modal="true"
          :destroy-on-hide="false"
        >
          <CreateNameModal
            @close="closeCreateModal"
            @create-template="createTemplateHandler"
            @valid-existing-template="validExistingTemplate"
            :isExistingTemplate="isTemplateSkribix"
          />
        </b-modal>
        <section
          class="headerTextView section has-background-white is-justify-content-center py-0 is-flex is-flex-direction-column"
        >
          <div
            class="is-flex is-flex-direction-column is-justify-content-center"
          >
            <div
              class="is-flex is-flex-wrap-wrap is-align-items-center is-justify-content-space-between is-fullheight"
            >
              <div class="is-flex is-flex-wrap-wrap is-align-items-center">
                <div class="is-flex is-align-items-center">
                  <b-button
                    type="is-link"
                    class="buttonArrowLeft button is-small is-outlined mr-4 has-text-blue has-hover-bottom"
                    @click.prevent="closeTemplateConstruct"
                  >
                    <IconArrowLeft class="iconArrow" />
                  </b-button>
                  <h3 v-if="template">
                    Template Skribix
                    {{ template.name ? ` - ${template.name}` : null }}
                  </h3>
                  <h3 v-else>Création d'un nouveau gabarit</h3>
                </div>
              </div>
              <div class="is-border-transparent radius has-hover-bottom">
                <ButtonIcon
                  color="pink"
                  size="large"
                  :text="$t('wizard.steps.actions.validate')"
                  class="is-responsive"
                  icon="IconCheck"
                  @click-button="validTemplateConstruct"
                />
              </div>
            </div>
          </div>
        </section>
      </template>
      <div
        class="mainTemplateOrder section columns is-flex is-flex-wrap-wrap is-justify-content-space-between"
      >
        <section
          class="sectionFormView column is-12-mobile is-12-tablet is-12-desktop is-4-widescreen is-3-fullhd"
        >
          <div class="columns is-multiline mt-2">
            <b-field
              v-for="(option, i) in templateBlockOptions"
              :key="i"
              class="column is-6-tablet is-6-desktop is-6-widescreen px-2 py-1"
            >
              <button
                type="radio"
                class="b-checkbox checkbox is-fullwidth blockTemplate button py-4"
                :id="`block-${i}`"
                :value="i"
                @click.prevent="pushActiveTemplateDataElement(option)"
              >
                <div class="is-flex is-flex-direction-column">
                  <span>{{ option.name }}</span>
                  <p class="body2">{{ option.instruction }}</p>
                </div>
              </button>
            </b-field>
          </div>
        </section>
        <section
          class="sectionFormView column is-12-mobile is-12-tablet is-12-desktop is-4-widescreen is-6-fullhd"
        >
          <div v-if="activeTemplateBlocks && activeTemplateBlocks.length > 0">
            <div
              v-for="(element, index) in activeTemplateBlocks"
              :key="index"
              class="is-flex is-flex-direction-column is-justify-content-space-evenly"
            >
              <div
                class="card has-border-none is-flex is-flex-direction-column has-text-blue bold is-justify-content-space-between my-1 py-2"
              >
                <div class="card-content">
                  <div class="is-flex is-justify-content-space-between">
                    <h4 v-if="element.name">
                      {{ element.name }}
                    </h4>
                    <div
                      v-if="
                        parseInt(element.id) !== 1 &&
                        parseInt(element.id) !== 2 &&
                        parseInt(element.id) !== 3
                      "
                    >
                      <b-button
                        class="is-large has-text-blue has-border-none"
                        icon-pack="fas"
                        icon-right="arrow-up"
                        @click="decrementIndex(index, index - 1)"
                      />
                      <b-button
                        v-if="activeTemplateBlocks[index + 1]"
                        class="is-large has-text-blue has-border-none"
                        icon-pack="fas"
                        icon-right="arrow-down"
                        @click="incrementIndex(index, index + 1)"
                      />
                      <b-button
                        class="is-large has-text-blue has-border-none"
                        icon-pack="fas"
                        icon-right="trash-can"
                        @click="deleteTemplateElement(index)"
                      />
                    </div>
                  </div>
                  <b-field>
                    <b-input
                      :id="`input-instruction-${index}`"
                      v-model="element.instructions"
                      @input="updateInstructions"
                      type="textarea"
                      placeholder="Instructions"
                      class="body1"
                      rows="5"
                    />
                  </b-field>
                  <div
                    v-if="
                      [1, 2].includes(parseInt(element.id)) ||
                      [1, 2].includes(parseInt(element.block_id))
                    "
                    class="is-fullwidth"
                  >
                    <b-field>
                      <b-input
                        id="inputMaxLength"
                        v-model="element.max_length"
                        type="number b-input"
                        placeholder="200"
                        class="body1 has-text-blue-light"
                        @input="updateMaxLength"
                      />
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <article
              class="is-flex is-flex-direction-column is-align-items-center is-justify-content-space-evenly py-2 my-2 has-border-none"
            >
              <div class="media pr-0">
                <figure
                  class="image has-text-pink rounded is-flex is-justify-content-center is-align-items-center"
                >
                  <img
                    src="/img/svg/icon_info.svg"
                    title="illustration en attente de brief"
                    alt="illustration en attente de brief"
                  />
                </figure>
              </div>
              <div class="has-text-centered pb-4">
                <ul>
                  <li><h4>Votre gabarit est vide</h4></li>
                  <li>
                    <p class="body1">
                      Cliquez sur les blocs pour ajouter des éléments
                    </p>
                  </li>
                </ul>
              </div>
            </article>
          </div>
        </section>
        <section
          v-if="!isAdminForm"
          class="sectionFormView column is-12-mobile is-12-tablet is-12-desktop is-4-widescreen is-3-fullhd"
        >
          <div
            class="cardAddBlock card has-border-none is-flex is-flex-direction-column has-text-blue bold is-justify-content-space-between my-1"
          >
            <div class="card-content is-flex is-justify-content-space-between">
              <p class="body-2 mr-2 has-text-blue">
                Permettre au rédacteur d'ajouter des blocs
              </p>
              <b-field>
                <b-switch v-model="isEditorsAddBlocks" />
              </b-field>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import { TEMPLATE_TYPES } from "@/utils/constants";
import CreateOrUpdateModal from "@/components/Model/Form/TemplateFormItem/TemplateCreateOrUpdateModal";
import CreateNameModal from "@/components/Model/Form/TemplateFormItem/TemplateCreateNameModal";
import ButtonIcon from "@/components/UI/buttons/ButtonIcon";

export default {
  name: "TemplateConstructFormItem",
  props: {
    field: {
      type: Object,
    },
    indexStep: {
      type: Number,
    },
    indexField: {
      type: Number,
    },
    isNextStep: {
      type: Boolean,
    },
    templateId: {
      type: Number,
    },
    isAdminForm: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CreateOrUpdateModal,
    CreateNameModal,
    ButtonIcon,
  },

  data() {
    return {
      isModalCreateOrUpdateActive: false,
      isModalCreateActive: false,
      isTemplateUpdate: false,
      selected: [],
      componentReady: false,
      blocksFetching: false,
      isEditorsAddBlocks: true,
      orderType: this.$store.state.order.newOrder["order_type"]
        ? this.$store.state.order.newOrder["order_type"]
        : null,
      options: [],
      templateBlockOptions: [],
      activeTemplateBlocks: [],
      template: null,
      // filters: { is_public: 1, order_type_id: null },
      indexBlock: null,
      optionTitle: false,
      optionMetaDescription: false,
      isTemplateSkribix: false,
      isCustomTemplate: false,
      instruction: " ",
    };
  },

  created() {
    this.getData();
  },

  computed: {
    ...mapState("template", [
      "templates",
      "customTemplates",
      "availableTemplateBlockOptions",
      "preserveTemplateState",
    ]),
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["clientRoleOnly"]),
    ...mapState("order", ["orderHasTemplate"]),

    templateSelected: {
      get() {
        return this.$store.state.template.templateSelected;
      },
      set(value) {
        this.$store.commit("template/setTemplateSelected", value);
      },
    },

    templateConfig: {
      get() {
        return this.$store.state.template.templateConfig;
      },
      set(value) {
        this.$store.commit("template/setTemplateConfig", value);
      },
    },

    activeTemplateData: {
      get() {
        return this.$store.state.template.activeTemplateData;
      },
      set(value) {
        this.$store.commit("template/setActiveTemplateData", value);
      },
    },

    newOrder: {
      get() {
        return this.$store.state.order.newOrder;
      },
      set(value) {
        this.$store.commit("template/setNewOrder", value);
      },
    },

    minDecrement() {
      return this.activeTemplateBlocks
        ? this.activeTemplateBlocks.filter(
            (el) => el.id === 1 || el.id === 2 || el.id === 3
          ).length
        : 0;
    },

    isBlockTitleExist() {
      return (
        this.activeTemplateBlocks[0] &&
        this.activeTemplateBlocks[0].name === "Title"
      );
    },

    isBlockMetaDescriptionExist() {
      return this.activeTemplateBlocks?.some(
        (template) => template.name === "Meta description"
      );
    },
  },

  methods: {
    ...mapActions("template", [
      "getTemplates",
      "createNewTemplate",
      "editTemplate",
      "getOrderTypeTemplateBlocks",
    ]),

    ...mapMutations("template", ["setPreserveTemplateState"]),

    getData() {
      if (this.isAdminForm) {
        this.blocksFetching = true;

        this.getOrderTypeTemplateBlocks().then(() => {
          this.templateBlockOptions = this.availableTemplateBlockOptions.filter(
            (block) => block.type !== "head"
          );

          if (this.templateId) {
            this.template = this.activeTemplateData;

            this.getBlocks();
          } else {
            this.activeTemplateBlocks = [];
            this.componentReady = true;
          }

          this.blocksFetching = false;
        });
      } else {
        this.getOrderTypeTemplateBlocks(this.newOrder.order_type)
          .then(() => {
            this.templateBlockOptions = this.availableTemplateBlockOptions;
            const templateType = this.templateSelected["template_type"];

            if (templateType === TEMPLATE_TYPES.CUSTOM_TEMPLATE) {
              this.handleCustomTemplate();
            } else if (templateType === TEMPLATE_TYPES.SKIBIX_TEMPLATE) {
              this.handleSkribixTemplate();
            } else if (templateType === TEMPLATE_TYPES.NEW_TEMPLATE) {
              this.handleEmptyTemplate();
            } else {
              this.handleOrderTemplate(templateType);
            }
          })
          .catch((e) => {
            this.$store.dispatch("fireError", e);
          });
      }
    },

    handleCustomTemplate() {
      this.isCustomTemplate = true;
      this.selectTemplate(this.customTemplates, "template_custom_selected");
      this.getBlocks();
    },

    handleSkribixTemplate() {
      this.isTemplateSkribix = true;

      if (this.templates.length > 1) {
        this.selectTemplate(this.templates, "template_skribix_selected");
      } else {
        this.template = { ...this.templates[0] };
      }

      this.getBlocks();
    },

    handleOrderTemplate(templateType) {
      if (
        templateType === TEMPLATE_TYPES.NEW_TEMPLATE &&
        this.activeTemplateData
      ) {
        this.template = { ...this.activeTemplateData };
      } else {
        this.template = { ...this.templates };
      }

      this.getBlocks();
    },

    handleEmptyTemplate() {
      this.activeTemplateBlocks = [];

      this.getOptions();
      this.setIsTemplateUpdate();
    },

    selectTemplate(value, name) {
      const template = value.find(
        (element) =>
          parseInt(element.id) === parseInt(this.templateSelected[name])
      );

      this.template = template ? { ...template } : this.template;
    },

    getBlocks() {
      if (this.preserveTemplateState && !this.isAdminForm) {
        this.syncTemplateListBlocksWithActiveTemplateDataBlocks();
      } else if (this.template.blocks) {
        const templateBlocks = this.template.blocks;

        templateBlocks.forEach((element) => {
          element.instructions =
            element.pivot?.instructions || element.instructions;
          element.max_length = element.pivot?.max_length || element.max_length;
          element.min_length = element.pivot?.min_length || element.min_length;
        });

        this.activeTemplateBlocks = templateBlocks;

        this.syncActiveTemplateDataBlocksWithTemplateListBlocks();
      }

      this.getOptions();
    },

    getOptions() {
      if (this.newOrder["options"]?.includes(2)) {
        if (!this.isBlockTitleExist) {
          this.indexBlock = this.templateBlockOptions.findIndex(
            (el) => el.id === 1
          );
          this.activeTemplateBlocks.splice(
            this.optionMetaDescription ? 1 : 0,
            0,
            this.templateBlockOptions[this.indexBlock]
          );

          this.setIsTemplateUpdate();
        }

        this.optionTitle = true;
      } else {
        if (this.isBlockTitleExist) {
          this.activeTemplateBlocks.splice(0, 1);

          this.setIsTemplateUpdate();
        }

        this.indexBlock = this.templateBlockOptions.findIndex(
          (el) => el.id === 1
        );

        if (this.indexBlock || this.indexBlock === 0) {
          this.templateBlockOptions.splice(this.indexBlock, 1);
        }

        this.optionTitle = false;
      }

      if (this.newOrder["options"]?.includes(3)) {
        if (!this.isBlockMetaDescriptionExist) {
          this.indexBlock = this.templateBlockOptions.findIndex(
            (el) => el.id === 2
          );

          this.activeTemplateBlocks.splice(
            this.optionTitle ? 1 : 0,
            0,
            this.templateBlockOptions[this.indexBlock]
          );

          this.setIsTemplateUpdate();
        }

        this.optionMetaDescription = true;
      } else {
        if (this.isBlockMetaDescriptionExist) {
          this.activeTemplateBlocks.splice(this.optionTitle ? 1 : 0, 1);

          this.setIsTemplateUpdate();
        }

        this.indexBlock = this.templateBlockOptions.findIndex(
          (el) => el.id === 2
        );

        if (this.indexBlock || this.indexBlock === 0) {
          this.templateBlockOptions.splice(this.indexBlock, 1);
        }

        this.optionMetaDescription = false;
      }

      this.componentReady = true;
    },

    decrementIndex(old_index, new_index) {
      this.setIsTemplateUpdate();

      if (new_index >= this.minDecrement) {
        this.activeTemplateBlocks.splice(
          new_index,
          0,
          this.activeTemplateBlocks.splice(old_index, 1)[0]
        );

        this.syncActiveTemplateDataBlocksWithTemplateListBlocks();
      }
    },

    incrementIndex(old_index, new_index) {
      this.setIsTemplateUpdate();

      if (
        old_index >= this.minDecrement &&
        new_index <= this.activeTemplateBlocks.length
      ) {
        this.activeTemplateBlocks.splice(
          new_index,
          0,
          this.activeTemplateBlocks.splice(old_index, 1)[0]
        );

        this.syncActiveTemplateDataBlocksWithTemplateListBlocks();
      }
    },

    deleteTemplateElement(index) {
      this.setIsTemplateUpdate();
      this.activeTemplateBlocks.splice(index, 1);

      this.syncActiveTemplateDataBlocksWithTemplateListBlocks();
    },

    createNewElement(element) {
      let newElement = {};

      newElement.created_at = element.created_at;
      newElement.default_value = element.default_value;
      newElement.id = element.id;
      newElement.instructions = element.instructions;
      newElement.max_length = element.max_length;
      newElement.min_length = element.min_length;
      newElement.name = element.name;
      newElement.required = element.required;
      newElement.translations = element.translations;
      newElement.type = element.type;
      newElement.random = (Math.random() + 1).toString(36).substring(7);

      return newElement;
    },

    pushActiveTemplateDataElement(element) {
      this.setIsTemplateUpdate();

      if (element.id === 1 || element.id === 2 || element.id === 3) {
        if (this.activeTemplateBlocks.find((el) => el.id === element.id)) {
          return false;
        }

        if (element.id === 3) {
          let index = 0;

          if (this.optionTitle && this.optionMetaDescription) {
            index = 2;
          } else if (this.optionTitle || this.optionMetaDescription) {
            index = 1;
          }

          this.activeTemplateBlocks.splice(index, 0, element);
        }
      } else {
        this.activeTemplateBlocks.push(this.createNewElement(element));
      }

      this.syncActiveTemplateDataBlocksWithTemplateListBlocks();
    },

    updateInstructions() {
      this.setIsTemplateUpdate();
      this.syncActiveTemplateDataBlocksWithTemplateListBlocks();
    },

    updateMaxLength() {
      this.setIsTemplateUpdate();
      this.syncActiveTemplateDataBlocksWithTemplateListBlocks();
    },

    closeTemplateConstruct() {
      this.$emit("close-construct");
    },

    syncActiveTemplateDataBlocksWithTemplateListBlocks() {
      this.activeTemplateData.blocks = [];

      this.activeTemplateBlocks.forEach((block) => {
        block.block_id = block.block_id || block.id;
        block.max_length = [1, 2].includes(block.id) ? block.max_length : null;

        const newBlock = { ...block };
        delete newBlock.id;

        this.activeTemplateData.blocks.push(newBlock);
      });
    },

    syncTemplateListBlocksWithActiveTemplateDataBlocks() {
      if (this.activeTemplateData?.blocks) {
        this.activeTemplateBlocks = [];

        this.activeTemplateData.blocks.forEach((block) => {
          block.instructions = block.pivot?.instructions || block.instructions;
          block.max_length = block.pivot?.max_length || block.max_length;
          block.min_length = block.pivot?.min_length || block.min_length;
          block.block_id = block.pivot?.block_id || block.id;

          this.activeTemplateBlocks.push(block);
        });
      }
    },

    validTemplateConstruct() {
      this.newOrder["editable_template"] = this.isEditorsAddBlocks;

      if (this.isTemplateUpdate) {
        this.syncActiveTemplateDataBlocksWithTemplateListBlocks();

        this.activeTemplateData.website_id = this.newOrder.website_id;
        this.activeTemplateData.order_type_id = this.newOrder.order_type;

        if (!this.template) {
          this.activeTemplateData.id = null;
          this.openCreateModal();
          return;
        }

        this.activeTemplateData.id = this.template.id;
        this.activeTemplateData.name = this.template.name;

        if (this.isTemplateSkribix) {
          this.isModalCreateActive = this.isTemplateSkribix;
        } else {
          this.isModalCreateOrUpdateActive = !this.isTemplateSkribix;
        }

        return;
      }

      this.validTemplate(this.template.id);
    },

    openCreateModal() {
      this.closeCreateOrUpdateModal();
      this.isModalCreateActive = true;
    },

    closeCreateOrUpdateModal() {
      this.isModalCreateOrUpdateActive = false;
    },

    closeCreateModal() {
      this.isModalCreateActive = false;
    },

    createTemplateHandler(name) {
      this.closeCreateModal();

      this.activeTemplateData.name = name;

      this.createNewTemplate()
        .then(() => {
          this.validTemplate(this.activeTemplateData.id);

          this.$store.commit("order/setOrderHasTemplate", true);
          this.templateSelected.template_type = TEMPLATE_TYPES.CUSTOM_TEMPLATE;
          this.templateSelected.template_custom_selected =
            this.activeTemplateData.id;
        })
        .catch((e) => {
          this.$store.dispatch("fireError", e);
        });
    },

    validExistingTemplate() {
      this.closeCreateModal();
      this.validTemplate(this.template.id);
    },

    editTemplateHandler() {
      this.closeCreateOrUpdateModal();

      this.editTemplate()
        .then(() => {
          this.validTemplate(this.activeTemplateData.id);
        })
        .catch((e) => {
          this.$store.dispatch("fireError", e);
        });
    },

    validTemplate(id) {
      this.$emit("valid-construct");
      this.newOrder["template_id"] = id;
    },

    setIsTemplateUpdate() {
      this.isTemplateUpdate = true;

      this.setPreserveTemplateState(true);
    },
  },
};
</script>
<style scoped>
.headerTextView {
  min-height: 12vh;
}
@media (min-width: 1216px) {
  .cardAddBlock {
    margin: auto;
    width: 80%;
  }
}
.buttonArrowLeft {
  height: 3em;
  width: 3em;
}

.blockTemplate {
  border: 1px solid transparent !important;
  box-shadow: none !important;
  min-height: 10vh;
}
.blockTemplate:active,
.blockTemplate:focus {
  color: #f35987 !important;
  background-color: #fef2f5;
  border: 1px solid #f35987 !important;
  box-shadow: none !important;
}
</style>

<style>
textarea[id^="input-instruction-"],
#inputMaxLength {
  font-family: BeVietnam !important;
  color: #8f92af !important;
}

textarea::-webkit-scrollbar {
  width: 2px !important;
}

.mainTemplateOrder .b-radio.radio.button {
  min-height: 10vh;
}
button.buttonArrowLeft:hover .iconArrow .iconPath {
  stroke: white !important;
}
</style>
