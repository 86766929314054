<template>
  <div>
    <OrderBriefArticle
      v-if="field.isEditableFormat && !isEditing"
      :isEditing="isEditing"
      :field="field"
      :value="field.value_displayed"
      @start-editing="isEditing = true"
    />
    <div
      v-if="!field.isEditableFormat || (field.isEditableFormat && isEditing)"
    >
      <div class="isSmallField mt-2" v-if="isSmallField">
        <b-field :label="field.label ? field.label : ''">
          <b-select v-model="activitySelected" class="is-fullwidth">
            <option
              v-for="option in thematics"
              :key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>
        <CardValidationField
          :field="field.isValid"
          :is-next-step="isNextStep"
          message="Ce champ est requis"
        />
      </div>
      <div v-else>
        <div class="is-flex">
          <b-field label="">
            <b-autocomplete
              v-model="activitiesSearch"
              :data="filteredActivities"
              field="name"
              clearable
              icon="fas fa-search"
              :placeholder="$t('sites.filters.search')"
              @select="(option) => (activitiesSearchSelected = option)"
            >
            </b-autocomplete>
          </b-field>
          <b-field class="ml-2" label="">
            <b-dropdown
              v-model="categorySelected"
              multiple
              ref="dropdown"
              aria-role="list"
            >
              <button
                class="button is-white has-text-blue"
                type="button"
                slot="trigger"
              >
                <span>Catégorie</span>
                <span class="categoryNumber has-background-pink rounded ml-1">{{
                  categorySelected.length
                }}</span>
                <IconChevronDown class="iconChevronDown" />
              </button>

              <b-dropdown-item
                v-for="categorie in categories"
                :key="categorie.id"
                custom
                aria-role="listitem"
              >
                <b-checkbox
                  type="is-primary"
                  v-model="categorySelected"
                  :native-value="categorie.id"
                  class="is-flex is-flex-direction-row-reverse is-justify-content-space-between"
                >
                  {{ categorie.name }}
                </b-checkbox>
              </b-dropdown-item>

              <div class="py-2">
                <b-button
                  size="is-small"
                  type="is-blue"
                  inverted
                  class="has-border-none is-fullwidth py-9"
                  @click="clearStatusFilter"
                >
                  Effacer les filtres
                </b-button>
              </div>
            </b-dropdown>
          </b-field>
        </div>
        <div>
          <div v-if="activitiesOptions.length > 0">
            <div class="columns is-multiline mt-2" v-if="showAllActivities">
              <b-field
                v-for="option in activitiesOptions"
                :key="option.id"
                class="column is-4-tablet is-4-desktop is-4-widescreen px-2 py-1"
              >
                <input
                  v-model="activitySelected"
                  type="checkbox"
                  class="labelForCheck"
                  :id="`thematics-${option.id}`"
                  :value="option.id"
                />
                <label
                  class="b-checkbox checkbox button is-fullwidth"
                  :for="`thematics-${option.id}`"
                  >{{ option.name }}
                </label>
              </b-field>
            </div>
            <div v-else>
              <div class="columns is-multiline mt-2">
                <b-field
                  v-for="option in activitiesOptions.slice(0, 9)"
                  :key="option.id"
                  class="column is-4-tablet is-4-desktop is-4-widescreen px-2 py-1"
                >
                  <input
                    v-model="activitySelected"
                    type="checkbox"
                    class="labelForCheck"
                    :id="`thematic-${option.id}`"
                    :value="option.id"
                  />
                  <label
                    class="b-checkbox checkbox button is-fullwidth"
                    :for="`thematic-${option.id}`"
                    >{{ option.name }}
                  </label>
                </b-field>
                <button
                  v-if="activitiesOptions.length > 9"
                  class="button is-large has-text-blue has-border-none has-background-grey-lightest is-flex is-justify-content-flex-start"
                  @click.prevent="showAllActivities = true"
                >
                  <IconPlus class="mr-2" />
                  Afficher tous les secteurs
                </button>
              </div>
            </div>
          </div>
          <div class="mt-4 mb-8" v-else>
            <h5 v-if="activitiesSearch" class="my-2">
              Aucun résultat pour {{ activitiesSearch }}
            </h5>
            <h5 v-else class="my-2">
              Il n'existe aucun secteur d'activité correspondant à votre
              recherche
            </h5>
            <div>
              <ButtonAddSector />
            </div>
          </div>
        </div>
        <CardValidationField
          v-if="
            !activitySelected ||
            (activitySelected && activitySelected.length === 0)
          "
          :field="field.isValid"
          :is-next-step="isNextStep"
          :message="$t('wizard.message.required')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonAddSector from "@/components/UI/buttons/ButtonAddCompanySector";
import CardValidationField from "@/components/UI/card/CardValidationField";
import { mapState } from "vuex";
import OrderBriefArticle from "@/components/Model/Order/OrderBriefArticle";

export default {
  name: "thematics-multi-select",
  props: ["field", "indexStep", "indexField", "isSmallField", "isNextStep"],
  components: { ButtonAddSector, CardValidationField, OrderBriefArticle },
  created() {
    this.activitiesOptions = this.thematics;
  },
  watch: {
    activitySelected: function (newValue) {
      this.$emit("save-result", {
        index: this.indexStep,
        index_field: this.indexField,
        value_name: this.field.value_name,
        value: newValue ? newValue : null,
      });
    },
    categorySelected: function (newValue) {
      if (newValue && newValue.length > 0) {
        this.activitiesOptions = [];
        newValue.forEach((index) =>
          this.categories[index].forEach((element) =>
            this.activitiesOptions.push(element)
          )
        );
        this.activitiesSearchResult = this.activitiesOptions;
      }
    },
    activitiesSearch: function (newValue) {
      if (newValue) {
        this.activitiesOptions = this.filteredActivities;
      } else {
        this.clearActivitySearch();
      }
    },
  },
  data() {
    return {
      isEditing: false,
      loading: false,
      activitiesSearch: "",
      activitiesSearchSelected: null,
      categorySelected: [],
      categoryOptions: [
        { id: 1, name: "Santé" },
        { id: 2, name: "Alimentation" },
      ],
      activitySelected: this.field.value ? this.field.value : [],
      showAllActivities: false,
      activitiesSearchResult: [],
    };
  },
  computed: {
    ...mapState("thematic", ["thematics", "categories"]),
    filteredActivities() {
      return this.activitiesOptions.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.activitiesSearch.toLowerCase()) >= 0
        );
      });
    },
  },
  methods: {
    clearStatusFilter() {
      this.categorySelected = [];
      this.activitiesOptions = this.thematics;
    },
    clearActivitySearch() {
      if (this.categorySelected && this.categorySelected.length > 0) {
        this.activitiesOptions = this.activitiesSearchResult;
      } else {
        this.activitiesOptions = this.thematics;
      }
    },
  },
};
</script>

<style scoped>
.categoryNumber {
  height: 16px;
  width: 16px;
  font-size: 10px;
}
.activitiesList {
  min-height: 50vh;
}
.iconChevronDown {
  width: 1.2em !important;
  height: 1.2em !important;
}
</style>
