<template>
  <article
    class="approveCard card is-flex is-flex-direction-column is-align-items-center is-justify-content-center my-2 has-border-none py-6"
  >
    <div class="card-content has-text-centered pb-4">
      <ul>
        <li v-if="false">
          <h4>Voulez-vous enregistrer ce modèle de gabarit ?</h4>
        </li>

        <li>
          <h4>Veuillez donner un nom à votre modèle de gabarit.</h4>
        </li>
        <li>
          <p class="body1">
            Vous pourez le réutiliser lors d’une prochaine commande.
          </p>
        </li>
      </ul>
    </div>
    <div class="buttons is-flex is-flex-direction-column">
      <b-field>
        <b-input
          v-model="templateName"
          type="text"
          placeholder="Nom du modèle"
          class="b-input mt-2"
        />
      </b-field>

      <b-button
        type="is-pink"
        inverted
        class="button is-large has-border-pink has-hover-bottom"
        @click.prevent="createTemplate"
      >
        Enregistrer le modèle
      </b-button>

      <template v-if="false">
        <b-button
          type="is-pink"
          inverted
          class="button is-large has-border-pink has-hover-bottom"
          @click.prevent="createTemplate"
        >
          {{ isExistingTemplate ? "Oui" : "Enregistrer le modèle" }}
        </b-button>
        <b-button
          v-if="isExistingTemplate"
          type="is-pink"
          inverted
          class="button is-large has-border-pink has-hover-bottom"
          @click.prevent="validExistingTemplate"
        >
          Non
        </b-button>
      </template>
      <b-button
        type="is-link"
        inverted
        class="button is-large has-border-link has-hover-bottom"
        @click.prevent="close"
        >Annuler
      </b-button>
    </div>
  </article>
</template>
<script>
export default {
  name: "templateCreateNameModal",
  props: ["isExistingTemplate"],
  data() {
    return {
      templateName: "",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    createTemplate() {
      this.$emit("create-template", this.templateName);
    },
    validExistingTemplate() {
      this.$emit("valid-existing-template");
    },
  },
};
</script>
<style scoped>
@media (min-width: 1024px) {
  .approveCard .card-content {
    width: 80%;
  }
}
</style>
