<template>
  <div>
    <DashboardHeader />

    <b-select
      v-model="activeTab"
      class="mx-8 mt-4 is-visible-medium-small-none"
    >
      <option
        v-for="filteredTab in filteredTabs"
        :value="filteredTab.orderKey"
        :key="filteredTab.orderKey"
      >
        {{ filteredTab.label }} : {{ orderCounts[filteredTab.orderKey] }}
      </option>
    </b-select>

    <b-tabs v-model="activeTab" class="px-6 userTab tabs-nav-hidden-mobile">
      <b-tab-item
        v-for="tab in tabs"
        :key="tab.orderKey"
        :label="`${tab.label} : ${orderCounts[tab.orderKey] || 0}`"
        :value="tab.orderKey"
      >
        <OrdersTab
          v-if="tab.orderKey == activeTab || tab.opened"
          :use-local-state="tab.useLocalState"
          class="pt-4 p-2"
          :default-filter="defaultFilters.editorManager[tab.orderKey] || {}"
          :default-sort="{
            field: 'desired_delivery_date',
            direction: 'asc',
          }"
          :default-page="defaultPageOfDefaultActiveTab(tab.orderKey)"
          :default-per-page="defaultPerPageOfDefaultActiveTab(tab.orderKey)"
        />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import DashboardHeader from "@/components/UI/DashboardHeader";
import OrdersTab from "@/components/Model/Order/OrdersTab";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "DashboardWriter",
  components: {
    DashboardHeader,
    OrdersTab,
  },

  data() {
    this.retrieveOrdersCountsFromLocalStorage();
    const activeTab =
      this.$route.query.tab ||
      (this.$store.state.order.orderCounts.new_orders
        ? "new_orders"
        : "in_progress_orders");

    return {
      defaultActiveTab: this.$route.query.tab,
      activeTab,
      tabs: [
        {
          label: "Nouvelles",
          orderKey: "new_orders",
          useLocalState: true,
          opened: false,
        },

        {
          label: "En cours",
          orderKey: "in_progress_orders",
          useLocalState: true,
          opened: false,
        },

        {
          label: "Candidatures",
          orderKey: "orders_has_application",
          useLocalState: true,
          opened: false,
        },

        {
          label: "À attribuer",
          orderKey: "to_be_assigned_orders",
          useLocalState: true,
          opened: false,
        },

        {
          label: "Urgentes",
          orderKey: "urgent_orders",
          useLocalState: true,
          opened: false,
        },

        {
          label: "En correction",
          orderKey: "in_correction_orders",
          useLocalState: true,
          opened: false,
        },

        {
          label: "Toutes",
          orderKey: "all_orders",
          useLocalState: false,
          opened: false,
        },

        {
          label: "En préparation",
          orderKey: "in_preparation_orders",
          useLocalState: true,
          opened: false,
        },

        {
          label: "Commandes accompagnées",
          orderKey: "orders_with_seo_support",
          useLocalState: true,
          opened: false,
        },

        {
          label: "Abonnements",
          orderKey: "orders_has_subscription",
          useLocalState: true,
          opened: false,
        },
      ],
    };
  },

  mounted() {
    this.tabs.forEach((tab) => {
      this.getOrdersCount({
        key: tab.orderKey,
        filters: this.defaultFilters.editorManager[tab.orderKey] || {},
      }).then((response) => {
        this.checkAndSetActiveTab(tab.orderKey, response.data?.orders_count);
      });
    });

    this.setActiveTabAsOpened();
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("order", ["orderCounts", "defaultFilters"]),

    filteredTabs() {
      return this.tabs.filter(
        (tab) => !tab.displayIfHasValue || this.orderCounts[tab.orderKey] > 0
      );
    },
  },

  methods: {
    ...mapActions("order", ["getOrdersCount"]),
    ...mapMutations("order", ["retrieveOrdersCountsFromLocalStorage"]),

    checkAndSetActiveTab(orderKey, orders_count) {
      if (this.$route.query.tab) {
        return;
      }

      if (
        orderKey == "new_orders" &&
        !orders_count &&
        this.activeTab != "in_progress_orders"
      ) {
        this.activeTab = "in_progress_orders";
      }

      if (orderKey == "new_orders" && orders_count) {
        this.activeTab = "new_orders";
      }
    },

    defaultPageOfDefaultActiveTab(tabName) {
      return +(this.defaultActiveTab == tabName
        ? this.$route.query.page || 1
        : 1);
    },

    defaultPerPageOfDefaultActiveTab(tabName) {
      return +(this.defaultActiveTab == tabName
        ? this.$route.query.per_page || 20
        : 20);
    },

    isActiveTab(tabName) {
      return tabName === this.activeTab;
    },

    setActiveTabAsOpened() {
      this.tabs.find((tab) => tab.orderKey == this.activeTab).opened = true;
    },
  },

  watch: {
    activeTab(val) {
      if (val === this.$route.query.tab) return;

      this.$router.replace({
        query: { tab: val },
      });

      this.setActiveTabAsOpened();
    },
  },
};
</script>
<style scoped>
::v-deep .tabs li {
  margin: 0 0.2%;
}
</style>
