<template>
  <div>
    <b-loading :active.sync="loading" />

    <b-table
      v-if="templates"
      backend-sorting
      backend-filtering
      class="table-striped"
      :striped="true"
      :narrowed="true"
      :data="templates"
      :loading="loading"
      :mobile-cards="true"
      :show-detail-icon="true"
      :per-page="config.per_page"
      :default-sort="[config.sort, config.sortDirection]"
      @sort="onSort"
      @filters-change="onFilterChange"
    >
      <b-table-column field="name" label="Nom" v-slot="props">
        <p class="body1 has-text-grey-darker">
          {{ props.row.name }}
        </p>
      </b-table-column>

      <b-table-column
        field="order_type_id"
        label="Type de texte"
        sortable
        v-slot="props"
      >
        <p
          v-if="
            props.row.order_type_id &&
            $t(`orders.texts.type.${props.row.order_type_id}`) !=
              `orders.texts.type.${props.row.order_type_id}`
          "
          class="body1 has-text-grey-darker"
        >
          {{ $t(`orders.texts.type.${props.row.order_type_id}`) }}
        </p>
      </b-table-column>

      <b-table-column
        v-slot="props"
        cellClass="is-flex is-justify-content-flex-end"
      >
        <b-button
          href="#"
          class="has-text-blue my-1"
          @click.prevent="editTemplate(props.row)"
        >
          <IconPen class="mt-1" />
        </b-button>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { prepareRequestFilters } from "@/utils/index";

export default {
  name: "TemplatesTable",
  data() {
    return {
      loading: true,
      templateFormReady: true,
    };
  },
  created() {
    this.config.sort = "order_type_id";
    this.config.filters.editable = 1;

    this.getData();
  },
  computed: {
    ...mapState("template", {
      templates: (state) => state.templates,
      config: (state) => state.templateConfig,
      activeTemplateData: (state) => state.activeTemplateData,
    }),
  },
  methods: {
    ...mapActions("template", ["getTemplates"]),
    ...mapMutations("template", ["setActiveTemplateData"]),

    getData() {
      this.getTemplates().finally(() => {
        this.loading = false;
      });
    },

    async editTemplate(template) {
      this.setActiveTemplateData({ ...template });
      this.$emit("show-form-modal");
    },

    onSort(field, order) {
      this.config.sort = field;
      this.config.sortDirection = order;

      this.getData(true);
    },

    onFilterChange(filter) {
      this.config.filters = prepareRequestFilters(filter);

      this.getData();
    },

    onPageChange(page) {
      this.config.page = page;

      this.getData();
    },
  },
};
</script>
