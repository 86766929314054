<template>
  <b-button
    inverted
    type="is-blue"
    :size="size"
    class="button has-border-blue has-hover-bottom"
    :disabled="processing || disabled"
    @click.prevent="resumeOrderSubscription"
  >
    {{ $t("orders.actions.subscription.resume") }}
  </b-button>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "BtnResumeOrderSubscription",
  props: {
    order: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "is-large",
    },
  },
  data() {
    return {
      processing: false,
    };
  },
  methods: {
    ...mapActions("order-subscription", ["resumeSubscription"]),

    resumeOrderSubscription() {
      this.$buefy.dialog.confirm({
        title: "Réactivation de l'abonnement",
        message: `Êtes-vous sûr de vouloir réactiver l'abonnement pour la commande ${this.order.reference} ?`,
        confirmText: "Réactiver",
        cancelText: "Annuler",
        type: "is-warning",
        onConfirm: async () => {
          this.processing = true;

          try {
            await this.resumeSubscription(this.order.reference);

            this.$store.dispatch("notify", {
              type: "is-info",
              message: `L'abonnement pour la commande ${this.order.reference} a été réactivé avec succès.`,
            });
          } catch (error) {
            this.processing = false;
            const message =
              error.response.data.message == "subscription_ended"
                ? this.$t("orders.subscriptions.messages.subscription_ended")
                : `Une erreur s'est produite lors de la réactivation de l'abonnement pour la commande ${this.order.reference}.`;

            this.$store.dispatch("notify", {
              type: "is-danger",
              message: message,
            });
          }

          this.$emit("get-data");
        },
      });
    },
  },
};
</script>
