<template>
  <article class="hero box p-4 is-pink-light my-2">
    <figure>
      <img src="/img/svg/avis.svg" title="Avis vérifiés" alt="Avis vérifiés" />
    </figure>
    <h4 class="has-text-pink">Skribix & Avis Vérifiés</h4>
    <p class="body1 has-text-pink">Votre avis compte pour nous !</p>
    <a
      class="mt-4 has-text-pink is-flex is-align-items-center"
      target="_blank"
      href="https://www.avis-verifies.com/avis-clients/skribix.com"
    >
      Evaluer le service <IconArrowRight class="ml-2" />
    </a>
  </article>
</template>
<script>
export default {
  name: "BoxExportWordpress",
};
</script>
