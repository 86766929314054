<template>
  <section v-if="tokenIsValid" class="section is-fullheight">
    <div class="container is-fullheight">
      <div class="columns is-centered is-align-items-center is-fullheight">
        <div class="column is-6-tablet is-5-desktop">
          <form class="box has-background-white">
            <h5 class="mb-2 has-text-centered">
              Créer un mot de passe pour votre compte
            </h5>

            <div class="field">
              <label for="password" class="label">Mot de passe</label>
              <input
                id="password"
                v-model="password"
                autocomplete="password"
                class="input has-background-white"
                name="password"
                placeholder="Mot de passe"
                required
                type="password"
              />
            </div>
            <p
              v-if="isSubmit && passwordMessage"
              class="body2 has-text-danger mb-2"
            >
              {{ passwordMessage }}
            </p>

            <div class="field">
              <label for="password" class="label">
                Confirmation de mot de passe
              </label>
              <input
                v-model="passwordConfirmation"
                required
                id="passwordConfirmation"
                autocomplete="password"
                class="input has-background-white"
                name="password"
                placeholder="Confirmez votre mot de passe"
                type="password"
              />
            </div>

            <div class="columns is-mobile is-align-items-center">
              <div class="column is-narrow">
                <router-link class="has-text-primary" to="/login">
                  Se connecter ?
                </router-link>
              </div>

              <div class="column is-narrow ml-auto">
                <button
                  v-show="!loading"
                  class="button is-blue"
                  @click.prevent="resetPwdHandler"
                >
                  {{ $t("auth.send") }}
                </button>
                <button
                  v-show="loading"
                  class="btn btn-primary btn-auth-loading btn-block waves-effect waves-themed"
                  disabled="disabled"
                  type="button"
                >
                  <span
                    aria-hidden="true"
                    class="spinner-border spinner-border-sm"
                    role="status"
                  ></span>
                  {{ $t("auth.send") }}...
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.h2 {
  margin: 0.5em 0 1em 0;
}
</style>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  title: "title.auth.login",
  name: "SetPassword",

  data: function () {
    return {
      email: "",
      password: "",
      passwordConfirmation: "",
      isSubmit: false,
      loading: false,
      mailregExp:
        /^((\w[^W]+)[.-]?){1,}@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      mailMessage: "",
      passwordMessage: "",
      token: "",
      tokenIsValid: false,
    };
  },

  created() {
    this.token = this.$route.query.token;
    this.email = this.$route.query.email;

    if (!this.token || !this.email) {
      this.$router.push("/login");
    }
  },

  beforeMount() {
    this.verifyPasswordToken({
      token: this.token,
      email: this.email,
    })
      .then((validationResponse) => {
        if (!validationResponse.data.valid) {
          this.$store.dispatch("notify", {
            type: "is-danger",
            message: `Le lien de changement de mot de passe est invalide ou a expiré`,
          });

          this.$router.push("/login");
          return;
        }

        this.tokenIsValid = true;
      })
      .catch((e) => {
        this.$store.dispatch("fireError", e);
      })
      .finally(() => {
        this.loading = false;
      });
  },

  computed: {
    ...mapState("auth", ["isConnected"]),
    ...mapGetters("auth", ["homepage"]),
  },

  methods: {
    ...mapActions("auth", {
      resetPwd: "resetPassword",
      verifyPasswordToken: "verifyPasswordToken",
    }),

    resetPwdHandler() {
      this.loading = true;
      this.isSubmit = true;

      this.validatePassword(this.password, this.passwordConfirmation);

      if (this.passwordMessage) {
        this.loading = false;
        return;
      }

      this.resetPwd({
        token: this.token,
        email: this.email,
        password: this.password,
        password_confirmation: this.passwordConfirmation,
      })
        .then(() => {
          this.loading = false;
          this.$store.dispatch("notify", {
            type: "is-success",
            message: `Votre nouveau mot de passe a bien été enregistré`,
          });
          this.isSubmit = false;
          this.$router.push(this.homepage);
        })
        .catch((e) => {
          this.$store.dispatch("fireError", e);
          this.isSubmit = false;
          this.loading = false;
        });
    },

    validatePassword(pwd, confirmpwd) {
      if (!pwd) {
        this.passwordMessage = this.$t("error.validation.required");
      } else if (pwd.length < 8) {
        this.passwordMessage =
          "Le mot de passe doit contenir au moins 8 caractères";
      } else if (pwd !== confirmpwd) {
        this.passwordMessage = this.$t("error.validation.password_mismatch");
      } else {
        this.passwordMessage = "";
      }
    },
  },
};
</script>
