var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[(_vm.isLoading)?_c('b-steps',{class:{ mainFormView: !_vm.isBriefOrderView },attrs:{"animated":false},scopedSlots:_vm._u([(_vm.customNavigation && !_vm.isBriefOrderView)?{key:"navigation",fn:function({ previous, next }){return [_c('footer',{staticClass:"footerFormView has-background-white is-flex is-flex-wrap-wrap is-flex-direction-column is-justify-content-center"},[_c('div',{staticClass:"footerButtons is-flex is-flex-wrap-wrap is-justify-content-space-between"},[_c('b-button',{staticClass:"button is-large has-border-blue has-hover-bottom ml-2",attrs:{"inverted":"","type":"is-blue","icon-pack":"fas","icon-left":"arrow-left","disabled":previous.disabled || _vm.isSaving},on:{"click":function($event){$event.preventDefault();return _vm.goPrevious(previous)}}},[_vm._v(" Précédent ")]),_c('ButtonSaveAndContinueLater',{staticClass:"is-hidden-mobile",attrs:{"disabled":_vm.isSaving},on:{"save-draft":_vm.saveDraft}}),(!next.disabled)?_c('b-button',{staticClass:"button is-large has-border-pink has-hover-bottom mr-2",attrs:{"inverted":"","type":"is-pink","icon-pack":"fas","icon-right":"arrow-right","disabled":next.disabled || _vm.isSaving || _vm.isActionButtonDisabledTemp},on:{"click":function($event){$event.preventDefault();return _vm.goNext(next)}}},[_vm._v(" Suivant ")]):_c('b-button',{staticClass:"button is-large has-border-pink has-hover-bottom mr-2",attrs:{"inverted":"","type":"is-pink","disabled":_vm.isSaving || _vm.isActionButtonDisabledTemp},on:{"click":function($event){$event.preventDefault();return _vm.submitCandidate()}}},[(_vm.candidateProfile.preferences.status === 2)?_c('span',[_vm._v(" Terminer ")]):_c('span',[_vm._v("Envoyer ma candidature")])])],1)])]}}:null],null,true),model:{value:(_vm.activeStep),callback:function ($$v) {_vm.activeStep=$$v},expression:"activeStep"}},[_c('div',{class:{
          'section columns is-flex is-flex-wrap-wrap py-8': !_vm.isBriefOrderView,
        }},[(!_vm.isBriefOrderView)?_c('section',{staticClass:"sectionFormView column is-12-mobile is-12-tablet is-12-desktop is-2-widescreen is-2-fullhd"},[_c('div',{staticClass:"buttons"},_vm._l((_vm.steps),function(step,index){return _c('b-step-item',{key:index,staticClass:"navButton is-flex is-flex-direction-column is-fullwidth is-align-items-flex-start",attrs:{"visible":step.visible}},[(step.isStepVisible)?_c('b-button',{staticClass:"button is-large has-border-none is-fullwidth is-flex is-flex-direction-column is-align-items-flex-start",class:{
                  'is-pink is-inverted':
                    !step.isStepDisabled && !step.isStepCompleted,
                },attrs:{"disabled":step.isStepDisabled}},[_c('span',[_vm._v(" "+_vm._s(step.label)+" ")]),_c('div',{staticClass:"is-flex is-flex-wrap-wrap is-align-items-center is-justify-content-flex-start"},[_vm._l((step.step_count),function(element){return _c('div',{key:element},[(step.visible)?_c('IconCircle',{attrs:{"color":!step.isStepCompleted &&
                        !step.isStepDisabled &&
                        (_vm.activeStep + 1 === element + index ||
                          _vm.activeStep >= element + index)
                          ? 'pink'
                          : !step.isStepCompleted &&
                            !step.isStepDisabled &&
                            _vm.activeStep + 1 < element + index
                          ? 'pink-light'
                          : !step.isStepDisabled && step.isStepCompleted
                          ? 'blue'
                          : 'grey-light'}}):_vm._e()],1)}),_c('p',{staticClass:"body2 bold mx-1",class:{
                      'has-text-blue-light': !step.isStepDisabled,
                      'has-text-grey-light': step.isStepDisabled,
                    }},[_vm._v(" "+_vm._s(step.duration)+" ")])],2)]):_vm._e()],1)}),1)]):_vm._e(),_c('section',{class:{
            'column is-12-mobile is-12-tablet is-12-desktop is-9-widescreen is-9-fullhd mb-4':
              !_vm.isBriefOrderView,
          }},[_c('div',{staticClass:"is-flex is-flex-direction-column is-fullheight"},[(!_vm.isBriefOrderView)?_c('ul',{staticClass:"titleFormStep"},[_c('li',[(_vm.steps[_vm.activeStep].title)?_c('div',[_c('h2',[_vm._v(" "+_vm._s(_vm.steps[_vm.activeStep].title)+" ")])]):_vm._e(),_c('p',{staticClass:"body1"},[_vm._v(" "+_vm._s(_vm.steps[_vm.activeStep].description)+" ")])])]):_vm._e(),_vm._l((_vm.steps[_vm.activeStep].content),function(element,index){return _c('form',{key:index,staticClass:"my-auto form"},[(element.title)?_c('h4',{class:{ 'mt-4': !element.isTitleOrderBrief }},[_vm._v(" "+_vm._s(element.title)+" ")]):_vm._e(),(element.description)?_c('p',{staticClass:"body1"},[_vm._v(" "+_vm._s(element.description)+" ")]):_vm._e(),_c(element.name,{key:_vm.activeStep,ref:"step",refInFor:true,tag:"component",staticClass:"is-flex is-flex-direction-column is-justify-content-space-evenly",class:{
                  'mt-4': !element.isBriefEditor,
                  'mt-3': element.isBriefEditor,
                },attrs:{"indexStep":_vm.activeStep,"indexField":index,"field":element,"isSmallField":element.small,"isNextStep":_vm.isNextStep},on:{"save-result":_vm.saveResult}})],1)}),(_vm.isBrief && _vm.steps[_vm.activeStep].isCompany)?_c('article',{staticClass:"card is-flex py-2 my-2 has-border-none has-text-blue has-background-blue-border mb-4"},[_c('div',{staticClass:"card-content pr-0"},[_c('figure',{staticClass:"image is-24x24"},[_c('img',{attrs:{"src":"/img/svg/icon_info.svg","alt":"icone information","title":"icone information"}})])]),_c('div',{staticClass:"card-content"},[_c('p',{staticClass:"body1 has-text-blue"},[_vm._v(" A chaque nouvelle commande, vous retrouverez ces informations. Vous ne remplirez ces informations qu’une seule fois :) ")])])]):_vm._e()],2)])])]):_vm._e()],1),(_vm.isCharging)?_c('section',[_c('b-loading',{model:{value:(_vm.isCharging),callback:function ($$v) {_vm.isCharging=$$v},expression:"isCharging"}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }