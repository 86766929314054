export default () => ({
  options: null,
  option: [],
  optionsConfig: {
    include: "",
    sort: "id",
    sortDirection: "",
    page: 1,
    filters: "",
    per_page: 100,
  },
  newOption: {
    fr: { name: "", description: "" },
    en: { name: "", description: "" },
    is_active: true,
  },
});
