<template>
  <form class="has-background-white" v-if="company">
    <template v-for="(element, index) in fieldsCompanyProfile">
      <div
        v-if="element.visible !== false"
        class="my-2"
        :key="index"
        style="height: 100%"
      >
        <span class="is-flex">
          <p class="has-text-blue bold" v-if="element.title">
            {{ element.title }}
          </p>
          <span class="iconSize ml-2" v-if="element.required">
            <i class="fas fa-asterisk"></i>
          </span>
        </span>
        <component
          v-if="componentReady"
          :key="index"
          :indexStep="index"
          :indexField="index"
          :is="element.name"
          :field="element"
          :isSmallField="element.small"
          :isNextStep="isAddCompanyActive"
          @save-result="saveResult"
          class="is-flex is-flex-direction-column is-justify-content-space-evenly mt-1"
        />
      </div>
    </template>
    <div v-if="editPrice">
      <hr />
      <h4>Gestion de tarifs</h4>
      <div v-for="lev in level" :key="lev" :value="lev">
        <div class="columns">
          <div class="column is-align-items-center is-flex">
            <h6>{{ $t(`wizard.order.text.writing_level.${lev}`) }}</h6>
          </div>
          <div class="column">
            <b-field label="Prix">
              <b-numberinput v-model="price[lev]" step="0.01" />
            </b-field>
          </div>
          <div class="column">
            <b-field label="Prix Rédacteur">
              <b-numberinput v-model="writer_price[lev]" step="0.01" />
            </b-field>
          </div>
        </div>
        <hr />
      </div>

      <CardValidationField
        v-if="hasError && hasErrorMessage"
        :is-next-step="true"
        :message="hasErrorMessage"
      />
    </div>
    <div v-else>
      <b-button
        inverted
        type="is-pink"
        class="button is-large has-border-pink has-hover-bottom"
        @click.prevent="editPriceHandler"
      >
        Editer les prix
      </b-button>
    </div>

    <div class="is-flex is-justify-content-flex-end mt-5">
      <b-button
        inverted
        type="is-pink"
        class="button is-large has-border-pink has-hover-bottom"
        :disabled="saving"
        @click.prevent="editCompanyHandler"
      >
        Enregistrer
      </b-button>
    </div>
  </form>
</template>
<script>
import { mapActions } from "vuex";
import AddressAutocomplete from "@/components/Model/Form/FormItem/AddressAutocomplete";
import BriefText from "@/components/Model/Form/FormItem/BriefText";
import BriefSwitch from "@/components/Model/Form/FormItem/BriefSwitch";
import FieldToManageCredit from "@/components/Model/Company/Form/FieldToManageCredit";
import CardValidationField from "@/components/UI/card/CardValidationField";

export default {
  name: "EditCompanyForm",
  data() {
    return {
      isAddCompanyActive: false,
      componentReady: true,
      editPrice: false,
      price: { 1: 9 / 100, 2: 11 / 100, 3: 13 / 100 },
      writer_price: { 1: 5 / 100, 2: 6 / 100, 3: 7 / 100 },
      hasError: false,
      hasErrorMessage: "",
      level: Object.keys(this.$t("wizard.order.text.writing_level")),
      saving: false,
    };
  },

  props: ["companyInfos"],

  components: {
    AddressAutocomplete,
    BriefText,
    BriefSwitch,
    CardValidationField,
    FieldToManageCredit,
  },

  watch: {
    price: {
      handler(val) {
        console.log("val", val);
      },
      deep: true,
    },
  },

  created() {
    if (this.company?.price) {
      this.price = this.company.price.text;
      this.writer_price = this.company.price.writer_price;
    }
  },

  computed: {
    company: {
      get() {
        return this.$store.state.company.company;
      },
      set(value) {
        this.$store.commit("company/setCompany", value);
      },
    },

    fieldsCompanyProfile() {
      return [
        {
          title: "Nom",
          name: "BriefText",
          value_name: "name",
          required: true,
          value: this.company ? this.company.name : " ",
        },
        {
          title: "Adresse",
          name: "AddressAutocomplete",
          value_name: "address",
          required: true,
          value: this.company ? this.company.address : " ",
          changeCityValue: 3,
          changeZipcodeValue: 2,
        },
        {
          isEditableFormat: false,
          title: "Code Postal",
          name: "BriefText",
          value_name: "zipcode",
          required: true,
          value: this.company ? this.company.zipcode : " ",
          regex: "\\d{5}$",
          hasErrorMessage: "Veuillez saisir une Code Postal Valide",
        },
        {
          title: "Ville",
          name: "BriefText",
          value_name: "city",
          required: true,
          value: this.company ? this.company.city : " ",
        },
        {
          title: "IBAN",
          name: "BriefText",
          value_name: "iban",
          required: false,
          value: this.company ? this.company.iban : " ",
          visible: this.company?.is_writer_company === true,
        },
        {
          title: "SIREN ou autre identifiant professionnel",
          name: "BriefText",
          value_name: "siren",
          required: true,
          value: this.company ? this.company.siren : " ",
        },
        {
          title: "TVA Intracommunautaire",
          name: "BriefText",
          value_name: "tva",
          required: false,
          regex:
            "[a-zA-Z]+([+-]?(?=\\.\\d|\\d)(?:\\d+)?(?:\\.?\\d*))(?:[eE]([+-]?\\d+))?",
          hasErrorMessage:
            "Veillez saisir une Numéro de TVA Intracommunautaire Valide",
          value: this.company ? this.company.tva : " ",
        },
        {
          title: "Paiement mensuel",
          name: "BriefSwitch",
          value_name: "monthly_payment",
          required: false,
          value: Boolean(this.company?.monthly_payment),
        },
        {
          title: "Pennylane ID",
          name: "BriefText",
          value_name: "pennylane_id",
          required: false,
          value: this.company?.pennylane_id,
          visible: this.company?.is_writer_company !== true,
        },
        {
          title: "Pennylane Supplier ID",
          name: "BriefText",
          value_name: "pennylane_supplier_id",
          required: false,
          value: this.company?.pennylane_supplier_id,
          visible: this.company?.is_writer_company === true,
        },
        {
          title: "Crédits",
          name: "FieldToManageCredit",
          value_name: "credits",
          required: false,
          regex: "^\\d+(\\.\\d+)?$",
          hasErrorMessage: "Veuillez entrer un nombre valide",
          value: this.company ? this.company.credits : 0,
          companyId: this.company ? this.company.id : null,
        },
      ];
    },
  },

  methods: {
    ...mapActions("company", ["getCompanies", "editCompany"]),
    ...mapActions("credit", ["getCreditHistories"]),

    saveResult(result) {
      this.company[result.value_name] = result.value;

      if (result.index) {
        this.fieldsCompanyProfile[result.index].value = result.value;

        if (result.index_field === 1) {
          this.forceRerender();
        }
      }
    },

    async forceRerender() {
      this.componentReady = false;
      await this.$nextTick();
      this.componentReady = true;
    },

    editCompanyHandler() {
      this.isAddCompanyActive = true;

      this.validStep(this.fieldsCompanyProfile);

      new Promise((resolve, reject) => {
        this.fieldsCompanyProfile.isStepValid === true ? resolve() : reject();
      })
        .then(() => {
          this.saving = true;

          this.editCompany(this.company.reference)
            .then((response) => {
              this.isAddCompanyActive = false;

              this.$emit("close-modal-add-company", response.data.company);

              this.$store.dispatch("notify", {
                type: "is-success",
                message: "La compagnie a bien été modifiée",
              });

              this.getCompanies();
              this.getCreditHistories(this.company.id);
            })
            .catch((e) => {
              this.$store.dispatch("fireError", e);
            })
            .finally(() => {
              this.saving = false;
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    editPriceHandler() {
      this.editPrice = true;
    },

    checkPrice() {
      this.hasErrorMessage = "";
      this.hasError = false;

      if (!this.editPrice) {
        return true;
      }

      let bValidePrice = true;
      Object.keys(this.price).forEach((index) => {
        if (
          this.price[index] <= 0 ||
          this.price[index] <= this.writer_price[index]
        ) {
          bValidePrice = false;
        }
      });

      if (!bValidePrice) {
        this.hasErrorMessage =
          "Tous les prix doivent être supérieur à 0.01€ ou supérieur au prix rédacteur";
        this.hasError = true;
        return false;
      }

      let new_price = {
        value_name: "price",
        value: {
          text: this.price,
          writer_price: this.writer_price,
        },
      };
      this.saveResult(new_price);
      return true;
    },

    validStep(steps) {
      steps.forEach((element) => {
        if (element.required === true) {
          if (
            (Array.isArray(element.value) &&
              element.value.every((elem) => elem === null)) ||
            (Array.isArray(element.value) && element.value.length === 0) ||
            (Array.isArray(element.value) && element.hasError)
          ) {
            element.isValid = false;
          } else if (
            element.value & (typeof element.value === "object") &&
            Object.keys(element.value) &&
            Object.keys(element.value).length === 0
          ) {
            element.isValid = false;
          } else if (
            !element.value ||
            element.value === " " ||
            element.hasError
          ) {
            element.isValid = false;
          } else {
            element.isValid = true;
          }
        } else {
          if (element.hasError) {
            element.isValid = false;
          } else {
            element.isValid = true;
          }
        }
      });

      if (steps.filter((element) => element.isValid === false).length === 0) {
        steps.isStepValid = this.checkPrice();
      } else {
        steps.isStepValid = false;
      }
    },
  },
};
</script>

<style scoped>
.iconSize {
  font-size: 0.5em;
}
</style>
