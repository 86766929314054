<template>
  <div>
    <main class="section" v-if="isLoading">
      <form
        class="column is-12-mobile is-12-tablet is-12-desktop is-12-widescreen is-12-fullhd is-flex is-flex-direction-column is-justify-content-space-evenly m-auto"
      >
        <div
          v-for="(element, index) in fieldsUserProfile"
          class="my-1"
          :key="index"
          style="height: 100%"
        >
          <p class="has-text-blue bold" v-if="element.title">
            {{ element.title }}
          </p>

          <component
            v-if="componentReady"
            :key="index"
            :indexStep="index"
            :indexField="index"
            :is="element.name"
            :field="element"
            :isSmallField="element.small"
            :isNextStep="isNextStep"
            @save-result="saveResult"
            :isNotWizard="true"
            class="is-flex is-flex-direction-column is-justify-content-space-evenly mt-1"
          />
        </div>
      </form>
      <CardValidationField
        v-if="hasError"
        :field="false"
        :is-next-step="true"
        :message="hasErrorMessage"
      />
    </main>
  </div>
</template>

<script>
import SelectItem from "@/components/Model/Form/FormItem/SelectItem";
import CardValidationField from "@/components/UI/card/CardValidationField";
export default {
  name: "FormationExperienceFormItem",
  props: ["field", "indexStep", "indexField"],
  components: {
    SelectItem,
    CardValidationField,
  },

  data() {
    return {
      isLoading: true,
      isNextStep: false,
      componentReady: true,
      hasError: false,
      hasErrorMessage: "",
    };
  },

  computed: {
    candidateProfile: {
      get() {
        return this.$store.state.user.candidateProfile;
      },
      set(value) {
        this.$store.commit("user/setCandidateProfile", value);
      },
    },

    fieldsUserProfile() {
      return [
        {
          title: "Depuis quand est tu rédacteur ou rédactrice ?",
          name: "SelectItem",
          value_name: "years_of_experience",
          required: true,
          maxValue: 40,
          unity: "ans",
          type: "number",
          value: this.candidateProfile.preferences
            ? this.candidateProfile.preferences.years_of_experience
            : " ",
        },
      ];
    },
  },
  methods: {
    saveResult(result) {
      if (!this.candidateProfile.preferences) {
        this.candidateProfile.preferences = {};
      }

      this.candidateProfile.preferences[result.value_name] = result.value;

      if (result.index_field <= this.fieldsUserProfile.length - 1) {
        this.fieldsUserProfile[result.index_field].value = result.value;
        this.fieldsUserProfile[result.index_field].hasError = result.hasError;
      }
    },

    async validStep(steps) {
      this.isNextStep = true;

      await steps.forEach((step) => {
        const dynamicRequiredIsValid = step.dynamicRequired
          ? step.dynamicRequired()
          : false;

        if (step.required === true || dynamicRequiredIsValid) {
          const experienceValue =
            this.candidateProfile.preferences[step.value_name];

          if (!experienceValue || experienceValue === " " || step.hasError) {
            step.isValid = false;
          } else {
            step.isValid = true;
          }
        } else if (step.hasError && step.dynamicRequired === undefined) {
          step.isValid = false;
        } else {
          step.isValid = true;
        }
      });

      if (steps.filter((step) => step.isValid === false).length === 0) {
        steps.isStepValid = true;
      } else {
        steps.isStepValid = false;
      }
    },
  },
};
</script>
<style scoped>
.section {
  padding: 0;
}
</style>
