var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.field.isEditableFormat && !_vm.isEditing)?_c('div',[_c('OrderBriefArticle',{attrs:{"isEditing":_vm.isEditing,"field":_vm.field,"value":_vm.field.value},on:{"start-editing":function($event){_vm.isEditing = true}}}),_c('CardValidationField',{attrs:{"field":_vm.field.isValid,"is-next-step":_vm.isNextStep,"message":_vm.$t('wizard.message.required')}})],1):_vm._e(),(!_vm.field.isEditableFormat || (_vm.field.isEditableFormat && _vm.isEditing))?_c('b-field',{class:{
      'is-danger': _vm.hasError,
      'is-flex is-align-items-center input-edit': _vm.field.isUserView,
    },attrs:{"custom-class":_vm.field.isUserView && 'is-flex is-flex-grow-1 mr-2',"label":_vm.field.label ? _vm.field.label : '',"message":_vm.hasError ? _vm.field.hasErrorMessage : ''}},[_c('div',{class:{
        'is-flex is-align-items-center is-flex-grow-5': _vm.field.isUserView,
      }},[_c('VuePhoneNumberInput',{staticClass:"phone-number",class:{
          'b-input mt-2': !_vm.field.isUserView,
          'width-100000': _vm.field.isUserView,
        },attrs:{"type":"text","placeholder":_vm.field.placeholder ? _vm.field.placeholder : '',"show-code-on-list":true,"default-country-code":_vm.countryCode,"translations":{
          countrySelectorLabel: 'Code pays',
          countrySelectorError: 'Choisir un pays',
          phoneNumberLabel: 'Numéro de téléphone',
          example: 'Exemple :',
        },"disabled":Boolean(_vm.disabled)},on:{"update":_vm.onUpdate},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.field.isEditable && _vm.field.isUserView)?_c('PartialSaveFormValueButton',{attrs:{"field":_vm.field,"before":_vm.persistValue,"callback":_vm.stopEditing}}):_vm._e()],1)]):_vm._e(),(_vm.hasError && _vm.field.hasErrorMessage)?_c('CardValidationField',{attrs:{"is-next-step":_vm.isNextStep,"message":_vm.field.hasErrorMessage}}):_vm._e(),(!_vm.selected || _vm.selected === ' ')?_c('CardValidationField',{attrs:{"field":_vm.field.isValid,"is-next-step":_vm.isNextStep,"message":_vm.$t('wizard.message.required')}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }