<template>
  <form v-if="option">
    <b-select v-model="locale" class="mb-3">
      <option value="fr">🇫🇷</option>
      <option value="en">🇺🇸</option>
    </b-select>

    <b-field label="Nom">
      <b-input v-if="option[locale]" v-model="option[locale]['name']" />
    </b-field>

    <b-field label="Description">
      <b-input
        v-if="option[locale]"
        v-model="option[locale]['description']"
        type="textarea"
      />
    </b-field>

    <div class="columns">
      <div class="column">
        <b-field label="Prix">
          <b-numberinput v-model="option.price" step="0.01" />
        </b-field>
      </div>
      <div class="column">
        <b-field label="Prix Rédacteur">
          <b-numberinput v-model="option.writer_price" step="0.01" />
        </b-field>
      </div>
      <div class="column is-flex-grow-0">
        <b-field label="Facturation">
          <BillingType :billing-type="option.billing_type" @change="change">
          </BillingType>
        </b-field>
      </div>
    </div>

    <b-field class="mt-2" label="Actif">
      <b-switch v-model="option.is_active">
        {{ option.is_active ? "Oui" : "Non" }}
      </b-switch>
    </b-field>

    <OrderTypeOptionParameterForm class="mb-2" />

    <b-button type="is-primary" :loading="saving" @click.prevent="doSave">
      Sauvegarder
    </b-button>
  </form>
</template>

<script>
import { mapActions, mapState } from "vuex";

import OrderTypeOptionParameterForm from "./OrderTypeOptionParameterForm";
import BillingType from "@/components/Model/Billing/BillingType";

export default {
  name: "CreateOptionForm",
  components: { BillingType, OrderTypeOptionParameterForm },
  data() {
    return {
      locale: this.$i18n.locale,
      saving: false,
      search: "",
    };
  },

  computed: {
    ...mapState("option", {
      options: (state) => state.options,
      option: (state) => state.option,
    }),
    options: {
      get() {
        return this.$store.state.option.options;
      },
      set(value) {
        this.$store.commit("option/setOptions", value);
      },
    },
    thematic: {
      get() {
        return this.$store.state.option.option;
      },
      set(value) {
        this.$store.commit("option/setOption", value);
      },
    },
  },
  methods: {
    ...mapActions("option", {
      saveOption: "save",
      updateOption: "update",
      getAll: "getOptions",
    }),

    change(billing) {
      console.log("billing", billing);
      this.option.billing_type = billing;
    },

    doSave() {
      this.saving = true;
      console.log("save");

      let optionPromize = this.option.id
        ? this.updateOption()
        : this.saveOption();

      optionPromize
        .then((response) => {
          if (this.option.id) {
            const options = this.options.map((obj) => {
              if (obj.id === this.option.id) {
                let array = {};

                response.data.option.translations.forEach((translation) => {
                  array[translation.locale] = {
                    name: translation.name,
                    description: translation.description,
                  };
                });

                return {
                  ...obj,
                  ...array,
                  name: response.data.option.name,
                  description: response.data.option.description,
                };
              }

              return obj;
            });

            this.options = options;
            this.option = response.data.option;
            this.getAll().finally(() => {
              this.loading = false;
            });
          } else {
            this.options.push(response.data.option);
            this.getAll();
          }
          this.$store.dispatch("notify", {
            type: "is-success",
            message: `L'option a bien été ${
              this.option.id ? "modifiée" : "créée"
            }`,
          });
        })
        .catch((e) => {
          this.$store.dispatch("fireError", e);
        })
        .finally(() => {
          this.saving = false;
          this.$emit("saved");
        });
    },
  },
};
</script>
