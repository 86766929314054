var render = function render(){var _vm=this,_c=_vm._self._c;return _c('figure',{staticClass:"image is-flex is-align-items-center is-justify-content-center",class:{
    'is-online': _vm.isOnline,
    'online-animated': _vm.hasBlinkAnimation && _vm.isOnline,
    [`is-${_vm.size}x${_vm.size}`]: true,
  }},[_c('b-tooltip',{staticClass:"is-fullwidth",attrs:{"multilined":"","position":"is-bottom","label":_vm.tooltip,"active":Boolean(_vm.tooltip)}},[(_vm.user.avatar && _vm.isImageFound)?_c('img',{staticClass:"is-rounded rounded",attrs:{"height":"auto","width":"auto","alt":!_vm.tooltip ? 'photo compte utilisateur skribix' : '',"src":_vm.user.avatar_thumb_cropped || _vm.user.avatar},on:{"error":_vm.handleImageError}}):_c('div',{staticClass:"divIconUser is-flex is-align-items-center is-justify-content-center",class:{
        'has-text-white': _vm.colorIconWhite,
        'has-text-blue': !_vm.colorIconWhite,
      }},[_c('IconUser',{staticClass:"icon-size-1_2",class:{
          'has-fill-white': _vm.colorIconWhite,
          'has-fill-blue': !_vm.colorIconWhite,
        }})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }