<template>
  <form class="is-flex is-flex-wrap-wrap is-justify-content-space-between mt-2">
    <div class="is-flex is-flex-wrap-wrap">
      <b-field label="">
        <b-dropdown
          v-model="selectedOptions"
          multiple
          ref="dropdown"
          aria-role="list"
        >
          <button
            class="button is-white has-text-blue"
            type="button"
            slot="trigger"
          >
            <span>{{ $t("sites.filters.status") }}</span>
            <span
              v-if="with_count"
              class="statusNumber has-background-pink rounded ml-1"
              >{{ selectedOptions.length }}
            </span>
            <IconChevronDown class="iconChevron" />
          </button>
          <b-dropdown-item
            v-for="(option, index) in statusOptions"
            :key="index"
            custom
            aria-role="listitem"
          >
            <b-checkbox
              type="is-primary"
              v-model="selectedOptions"
              :native-value="index"
              class="is-flex is-flex-direction-row-reverse is-justify-content-space-between"
            >
              <StatusOrderCard
                v-if="options === 'order.text.status'"
                :status="index"
                :options="options"
              />
              <StatusOrderCard v-else :status="index" :options="options" />
            </b-checkbox>
          </b-dropdown-item>

          <div class="py-2">
            <b-button
              size="is-small"
              type="is-blue"
              inverted
              class="has-border-none is-fullwidth py-9"
              @click="clearStatusFilter"
              >{{ $t("orders.filters.clear") }}
            </b-button>
          </div>
        </b-dropdown>
      </b-field>
    </div>
    <slot name="otherfilter"> </slot>
  </form>
</template>
<script>
import StatusOrderCard from "@/components/Model/Order/StatusOrderCard";
import { mapGetters, mapState } from "vuex";
export default {
  name: "texts-filters",
  components: { StatusOrderCard },
  props: [
    "with_count",
    "data",
    "options",
    "exceptedOptions",
    "onlyOptions",
    "fieldSearch",
    "typeUser",
  ],
  data() {
    return {
      textSearch: "",
      textSelected: null,
      selectedOptions: [],
      period: "",
    };
  },
  created() {
    if ((this.editorManager && !this.activeOrdersTab) || this.client) {
      this.selectedOptions = [];
    }
  },
  computed: {
    ...mapGetters("auth", [
      "admin",
      "writer",
      "client",
      "editorManager",
      "corrector",
      "candidate",
    ]),
    ...mapState("order", ["activeOrdersTab"]),

    statusOptions() {
      const options = this.$t(this.options);

      if (this.onlyOptions?.length) {
        let onlyOptions = {};

        Object.keys(options).forEach((key) => {
          if (this.onlyOptions.includes(parseInt(key))) {
            onlyOptions[key] = options[key];
          }
        });

        return onlyOptions;
      }

      if (this.exceptedOptions?.length) {
        Object.keys(options).forEach((key) => {
          if (this.exceptedOptions.includes(parseInt(key))) {
            delete options[key];
          }
        });
      }

      return options;
    },

    periodOptions() {
      return Object.keys(this.$t("orders.filters.period_list")).length;
    },

    ordersConfig: {
      get() {
        return this.$store.state.order.ordersConfig;
      },
      set(value) {
        this.$store.commit("wizard/setOrdersConfig", value);
      },
    },
  },
  watch: {
    textSearch: function (newValue) {
      this.$emit("search-texts", newValue);
    },
    selectedOptions: function (newValue) {
      this.$emit("filter-status", newValue);
    },
    activeOrdersTab: function (newValue) {
      switch (newValue) {
        case 1:
          this.clearFilter();
          this.selectedOptions = [];
          break;
        case 2:
          this.clearFilter();
          this.selectedOptions = [4];
          break;
        case 3:
          this.clearFilter();
          this.selectedOptions = [1, 2, 3];
          break;
        case 4:
          this.clearFilter();
          this.selectedOptions = [5, 6, 7];
          break;
        case 5:
          this.clearFilter();
          this.ordersConfig.filters.orders_to_assign = 1;
          this.selectedOptions = [4, 5, 6, 7, 8];
          break;
        case 6:
          this.clearFilter();
          this.ordersConfig.filters.urgent_orders = 1;
          this.selectedOptions = [4, 5, 6, 7, 8];
          break;
        case 7:
          this.clearFilter();
          this.selectedOptions = [6];
          break;
        default:
          this.clearFilter();
          this.selectedOptions = [4, 5, 6, 7, 8];
      }
    },
  },
  methods: {
    clearStatusFilter() {
      this.selectedOptions = [];
      this.$emit("filter-status", null);
    },
    clearFilter() {
      this.ordersConfig.filters.orders_to_assign = null;
      this.ordersConfig.filters.urgent_orders = null;
    },
  },
};
</script>
<style scoped>
.statusNumber {
  height: 16px;
  width: 16px;
  font-size: 10px;
  position: relative;
  top: 3px;
}
.iconChevron {
  width: 1.2em !important;
  height: 1.2em !important;
  position: relative;
  top: 3px;
}
</style>
