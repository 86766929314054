var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section pt-4 pb-6 is-flex is-flex-wrap-wrap is-justify-content-space-between has-background-white",class:{
    'dashboard-header-large': !_vm.user.is_active,
  }},[_c('article',{staticClass:"container is-flex is-justify-content-flex-start is-align-items-center mb-2 ml-0 dashboard-header-1"},[_c('UserAvatarBigSize',{staticClass:"has-border-white rounded",attrs:{"user":_vm.user,"hasBorderBlue":true,"size":"big","imgAlt":"photo de l'utilisateur skribix"}}),_c('div',{staticClass:"ml-4"},[_c('h1',{staticClass:"p-0"},[_vm._v(_vm._s(_vm.$t("dashboard.hello"))+" "+_vm._s(_vm.user.firstname))]),(_vm.writer)?_c('p',{staticClass:"body1",domProps:{"innerHTML":_vm._s(
          _vm.user.is_active === false
            ? _vm.$t('dashboard.greeting_inactive_account')
            : _vm.$t('dashboard.greeting')
        )}}):(_vm.client)?_c('p',{staticClass:"body1"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.client"))+" ")]):_vm._e()]),(_vm.writer)?_c('div',{staticClass:"availability-input-wrapper"},[(
          _vm.writerPreferences.availability ||
          _vm.writerPreferences.availability === false ||
          _vm.writerPreferences.availability === null
        )?_c('b-field',{attrs:{"label":"Disponible"}},[_c('b-switch',{on:{"input":_vm.updateWriterPreference},model:{value:(_vm.writerPreferences.availability),callback:function ($$v) {_vm.$set(_vm.writerPreferences, "availability", $$v)},expression:"writerPreferences.availability"}},[_vm._v(" "+_vm._s(_vm.writerPreferences.availability ? "Yes" : "No")+" ")])],1):_vm._e()],1):_vm._e()],1),(_vm.admin || _vm.writer || _vm.editorManager || _vm.corrector || _vm.recruiter)?_c('div',{staticClass:"container is-flex is-justify-content-space-between mr-0 mb-2"},[_c('div',{staticClass:"is-flex is-flex-direction-column is-justify-content-center user-role-wrapper"},[_c('p',{staticClass:"body1"},[_vm._v("Vous avez les rôles :")]),_vm._l((_vm.roles),function(role){return _c('div',{key:role},[_c('p',{staticClass:"body1"},[_vm._v(" "+_vm._s(_vm.$t(`role.${role}`))+" "),(_vm.writer && role === 'writer')?_c('span',[_vm._v(" - "+_vm._s(_vm.$t( `wizard.order.text.writing_level.${_vm.writerPreferences.writer_level}` ))+" ")]):_vm._e()])])})],2),(_vm.writer && _vm.isLoading)?_c('div',{staticClass:"is-flex is-flex-direction-column is-justify-content-center"},[_c('span',{staticClass:"container is-flex is-flex-wrap-wrap is-justify-content-flex-end"},[_c('BoxWriterSpace',{staticClass:"mr-2"}),_c('BoxWorkshopSkribix')],1)]):_vm._e()]):(_vm.candidate)?_c('div',{staticClass:"container is-flex is-flex-wrap-wrap is-justify-content-flex-end"},[_c('BoxCandidateSpace',{staticClass:"mr-2"}),_c('BoxWorkshopCandidateSkribix')],1):_c('div',{staticClass:"container is-flex is-flex-wrap-wrap is-justify-content-flex-end"},[_c('BoxSeoWebinar',{staticClass:"mr-2"}),_c('BoxExportWordpress')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }