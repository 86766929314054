var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{class:{
    'is-flex-direction-column': !_vm.field.isUserView,
    'is-flex': !_vm.field.isNoFlex,
  }},[_c('div',{staticClass:"is-flex is-align-items-center p-0 pt-2",class:{
      'column is-flex-grow-2': _vm.field.isUserView,
      'is-justify-content-space-between': !_vm.field.isUserView,
    }},[_c('p',{staticClass:"body2 bold has-text-blue",class:{ 'mb-2': !_vm.field.isBriefEditor && !_vm.field.isUserView }},[_vm._v(" "+_vm._s(_vm.field.label)+" ")]),(_vm.field.isEditable && !_vm.field.isUserView)?_c('b-button',{staticClass:"is-small has-text-blue has-border-none",class:{ 'my-1': !_vm.field.isBriefEditor },on:{"click":function($event){$event.preventDefault();return _vm.startEditing.apply(null, arguments)}}},[_c('IconPen',{staticClass:"iconPen"})],1):_vm._e()],1),(_vm.value && _vm.value.length !== 0)?_c('div',{staticClass:"briefAnswers",class:{
      'has-background-white radius py-2 px-3':
        !_vm.field.isBriefEditor && !_vm.field.isUserView,
      'p-0 pt-2 column is-flex-grow-5 is-justify-content-flex-end is-flex':
        _vm.field.isUserView,
    }},[(_vm.field.isHtml)?_c('div',{class:{
        'is-flex is-align-items-center': _vm.field.isUserView,
      }},[_c('p',{staticClass:"body1 has-text-blue",class:{
          right: _vm.field.isUserView,
        },domProps:{"innerHTML":_vm._s(_vm.value)}}),(_vm.field.isEditable && _vm.field.isUserView)?_c('b-button',{staticClass:"is-small has-text-blue has-border-none",class:{ 'my-1': !_vm.field.isBriefEditor },on:{"click":function($event){$event.preventDefault();return _vm.startEditing.apply(null, arguments)}}},[_c('IconPen',{staticClass:"iconPen"})],1):_vm._e()],1):(
        typeof _vm.value !== 'string' &&
        typeof _vm.value !== 'boolean' &&
        _vm.value.length > 0
      )?_c('div',{class:{
        'is-flex is-align-items-center': _vm.field.isUserView,
      }},[_c('div',{class:{
          'end mt-2': _vm.field.isUserView,
        }},_vm._l((_vm.value),function(option){return _c('div',{key:option.id},[_c('p',{staticClass:"body1 has-text-blue"},[_vm._v(" "+_vm._s(option.name)+" ")])])}),0),(_vm.field.isEditable && _vm.field.isUserView)?_c('b-button',{staticClass:"is-small has-text-blue has-border-none",class:{ 'my-1': !_vm.field.isBriefEditor },on:{"click":function($event){$event.preventDefault();return _vm.startEditing.apply(null, arguments)}}},[_c('IconPen',{staticClass:"iconPen"})],1):_vm._e()],1):(typeof _vm.value === 'boolean')?_c('div',[_c('li',{class:{
          'is-flex is-align-items-center': _vm.field.isUserView,
        }},[_c('p',{staticClass:"body1 has-text-blue",class:{
            right: _vm.field.isUserView,
          }},[_vm._v(" "+_vm._s(_vm.value == true ? "Oui" : "Non")+" ")]),(_vm.field.isEditable && _vm.field.isUserView)?_c('b-button',{staticClass:"is-small has-text-blue has-border-none",class:{ 'my-1': !_vm.field.isBriefEditor },on:{"click":function($event){$event.preventDefault();return _vm.startEditing.apply(null, arguments)}}},[_c('IconPen',{staticClass:"iconPen"})],1):_vm._e()],1)]):_c('ul',[_c('li',{class:{
          'is-flex is-align-items-center': _vm.field.isUserView,
        }},[(_vm.isLink)?_c('a',{staticClass:"body1 has-text-pink",class:{
            right: _vm.field.isUserView,
          },attrs:{"href":_vm.field.value,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.value ? _vm.value : null)+" "+_vm._s(_vm.valueType ? _vm.valueType : null)+" ")]):_c('p',{staticClass:"body1 has-text-blue",class:{
            right: _vm.field.isUserView,
          }},[_vm._v(" "+_vm._s(_vm.value ? _vm.value : null)+" "+_vm._s(_vm.valueType ? _vm.valueType : null)+" ")]),(_vm.field.isEditable && _vm.field.isUserView)?_c('b-button',{staticClass:"is-small has-text-blue has-border-none",class:{ 'my-1': !_vm.field.isBriefEditor },on:{"click":function($event){$event.preventDefault();return _vm.startEditing.apply(null, arguments)}}},[_c('IconPen',{staticClass:"iconPen"})],1):_vm._e()],1)])]):_c('div',{class:{
      'p-0 pt-2': _vm.field.isUserView,
    }},[_c('ul',[_c('li',{class:{
          'is-flex is-align-items-center': _vm.field.isUserView,
        }},[_c('p',{staticClass:"body1 has-text-blue"},[_vm._v(" "+_vm._s(_vm.value === false ? "Non" : "Non renseigné")+" ")]),(_vm.field.isEditable && _vm.field.isUserView)?_c('b-button',{staticClass:"is-small has-text-blue has-border-none",class:{ 'my-1': !_vm.field.isBriefEditor },on:{"click":function($event){$event.preventDefault();return _vm.startEditing.apply(null, arguments)}}},[_c('IconPen',{staticClass:"iconPen"})],1):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }