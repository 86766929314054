<template>
  <form class="has-background-white mb-5 p-5">
    <b-loading :active.sync="dataSaving" />
    <h3 class="is-size-4 is-underlined">{{ steps[activeStepIndex].title }}</h3>

    <div class="mt-4">
      <div class="mb-4">
        <span class="has-text-pink">*</span> <i>Champs obligatoires</i>
      </div>

      <div
        v-for="(element, index) in steps[activeStepIndex].fields"
        class="my-2"
        :key="index"
        style="height: 100%"
      >
        <div v-if="element.disabled !== true">
          <span class="is-flex">
            <p class="has-text-blue bold" v-if="element.title">
              {{ element.title }}
            </p>
            <span
              v-if="element.required || element.dynamicRequired()"
              class="iconSize ml-2"
            >
              <i class="fas fa-asterisk"></i>
            </span>
          </span>
          <component
            v-if="componentReady"
            :key="index"
            :indexStep="index"
            :indexField="index"
            :is="element.name"
            :field="element"
            :isSmallField="element.small"
            :is-next-step="formValidated"
            @save-result="saveResult"
            class="is-flex is-flex-direction-column is-justify-content-space-evenly mt-1"
          />
        </div>
      </div>
    </div>

    <div
      class="is-flex is-justify-content-space-between is-align-items-center mt-4"
    >
      <b-button
        v-if="activeStepIndex > 0"
        inverted
        type="is-pink"
        class="button is-large has-border-pink has-hover-bottom"
        @click.prevent="goToPreviousStep"
        :disabled="dataSaving"
      >
        {{ steps[activeStepIndex - 1].title }}
      </b-button>
      <router-link
        v-else
        to="/login"
        class="btn has-text-blue is-outlined has-background-white has-border-none is-underlined"
      >
        Vous avez déjà un compte ?
      </router-link>

      <b-button
        v-if="activeStepIndex < steps.length - 1"
        inverted
        type="is-pink"
        class="button is-large has-border-pink has-hover-bottom ml-auto"
        @click.prevent="goToNextStep"
        :disabled="actionsTmpDisabled || dataSaving"
      >
        Continuer
      </b-button>
      <b-button
        v-else
        inverted
        type="is-pink"
        class="button is-large has-border-pink has-hover-bottom ml-auto"
        @click.prevent="userRegistrationHandler"
        :disabled="actionsTmpDisabled || dataSaving"
      >
        {{ $t("auth.register.actions.register") }}
      </b-button>
    </div>
  </form>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { validateFormInputs } from "@/utils/validation";
import { isCountryEuropeanAndNotFrance } from "@/utils/index";
import renderMixin from "@/mixins/renderMixin";
import AddressAutocomplete from "@/components/Model/Form/FormItem/AddressAutocomplete";
import BriefText from "@/components/Model/Form/FormItem/BriefText";
import BriefSwitch from "@/components/Model/Form/FormItem/BriefSwitch";
import CardValidationField from "@/components/UI/card/CardValidationField";
import PhoneInput from "@/components/Model/Form/FormItem/PhoneInput";

export default {
  name: "ClientRegistrationForm",
  mixins: [renderMixin],
  data() {
    return {
      dataSaving: false,
      actionsTmpDisabled: false,
      formValidated: false,
      componentReady: true,
      activeStepIndex: 0,
      steps: [],
    };
  },
  components: {
    AddressAutocomplete,
    BriefText,
    BriefSwitch,
    CardValidationField,
    PhoneInput,
  },
  beforeMount() {
    this.steps = [
      {
        title: "À propos de votre société",
        key: "company",
        fields: this.companyFields,
      },
      {
        title: "À propos de vous",
        key: "user",
        fields: this.clientFields,
      },
    ];

    this.componentReady = true;
  },
  computed: {
    ...mapGetters("auth", ["homepage"]),
    newClient: {
      get() {
        return this.$store.state.guest.newClient;
      },
      set(value) {
        this.$store.commit("guest/setNewClient", value);
      },
    },
    companyFields() {
      return [
        {
          title: "Nom de la société",
          name: "BriefText",
          value_name: "name",
          required: true,
          value: "",
        },
        {
          title: "Adresse",
          name: "AddressAutocomplete",
          value_name: "address",
          required: true,
          value: "",
          changeCountryValue: 4,
          changeCityValue: 3,
          changeZipcodeValue: 2,
        },
        {
          isEditableFormat: false,
          title: "Code Postal",
          name: "BriefText",
          value_name: "zipcode",
          required: true,
          value: "",
          regex: "\\d{5}$",
          hasErrorMessage: "Veuillez saisir une Code Postal Valide",
        },
        {
          title: "Ville",
          name: "BriefText",
          value_name: "city",
          required: true,
          value: "",
        },
        {
          title: "Pays",
          name: "BriefText",
          value_name: "country",
          required: true,
          value: "",
          is: true,
        },
        {
          title: "SIREN ou autre identifiant professionnel",
          name: "BriefText",
          value_name: "siren",
          required: true,
          value: "",
        },
        {
          title: "TVA Intracommunautaire",
          name: "BriefText",
          value_name: "tva",
          dynamicRequired: () => {
            const country = this.$store.state.guest.newClient.company.country;
            return isCountryEuropeanAndNotFrance(country);
          },
          regex:
            "[a-zA-Z]+([+-]?(?=\\.\\d|\\d)(?:\\d+)?(?:\\.?\\d*))(?:[eE]([+-]?\\d+))?",
          errorMessages: {
            regex:
              "Veuillez saisir une Numéro de TVA Intracommunautaire Valide",
          },
          hasErrorMessage:
            "Veuillez saisir une Numéro de TVA Intracommunautaire Valide",
          value: "",
        },
      ];
    },
    clientFields() {
      return [
        {
          title: "Prénom",
          name: "BriefText",
          value_name: "firstname",
          required: true,
          value: "",
        },
        {
          title: "Nom",
          name: "BriefText",
          value_name: "lastname",
          required: true,
          value: "",
        },
        {
          title: "Téléphone",
          name: "PhoneInput",
          value_name: "phone",
          required: true,
          hasErrorMessage: "Veillez saisir une Numéro de Téléphone Valide",
          value: "",
          countryCode: "",
        },
        {
          title: "Email",
          name: "BriefText",
          value_name: "email",
          required: true,
          value: "",
          regex: "^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$",
          hasErrorMessage: "Veuillez saisir une adresse email valide",
        },
        {
          title: "Mot de passe",
          name: "BriefText",
          type: "password",
          value_name: "password",
          required: true,
          value: "",
          regex: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})",
          errorMessages: {
            regex:
              "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule et un chiffre",
          },
        },
        {
          title: "Confirmer le mot de passe",
          name: "BriefText",
          type: "password",
          value_name: "password_confirmation",
          required: true,
          value: "",
          customValidation(value, fields) {
            const isValid =
              value ===
              fields.find((field) => field.value_name === "password").value;

            return {
              isValid,
              errorMessage: isValid
                ? null
                : "Le mot de passe et la confirmation du mot de passe ne correspondent pas",
            };
          },
        },
      ];
    },
    activeStep() {
      return this.steps[this.activeStepIndex];
    },
  },
  watch: {
    activeStepIndex() {
      this.forceRerender();
    },
  },
  methods: {
    ...mapActions("guest", ["registerClient"]),

    saveResult(result) {
      this.newClient[this.activeStep.key][result.value_name] = result.value;

      if (result.value_name === "country_code") {
        this.steps[this.activeStepIndex].fields[
          result.index_field
        ].countryCode = result.value;
      } else {
        this.steps[this.activeStepIndex].fields[result.index_field].value =
          result.value;
      }

      if (result.value_name === "address") {
        this.forceRerender();
      }
    },

    validateStep() {
      const validationResults = validateFormInputs(
        this.steps[this.activeStepIndex].fields
      );

      if (!validationResults.isValid) {
        this.steps[this.activeStepIndex].fields = validationResults.fields;
        this.formValidated = true;
        this.forceRerender();

        return false;
      }

      return true;
    },

    goToPreviousStep() {
      this.activeStepIndex--;
    },

    goToNextStep() {
      this.actionsTmpDisabled = true;

      setTimeout(() => {
        this.actionsTmpDisabled = false;
      }, 2000);

      if (!this.validateStep()) {
        return;
      }

      this.formValidated = false;
      this.activeStepIndex++;
    },

    userRegistrationHandler() {
      if (!this.validateStep()) {
        return;
      }

      this.formValidated = false;
      this.dataSaving = true;

      this.registerClient(this.newClient)
        .then(() => {
          window.location = `${this.homepage}new-website`;
        })
        .catch((e) => {
          this.dataSaving = false;
          this.$store.dispatch("fireError", e);
        });
    },
  },
};
</script>
<style scoped>
.iconSize {
  font-size: 0.5em;
}
</style>
