<template>
  <div class="card card-content is-fullheight">
    <OrderDuplicateModal
      v-model="isOrderDuplicatorModalActive"
      :order="order"
    />

    <router-link :to="link">
      <ul
        class="is-flex is-flex-direction-column is-justify-content-space-between is-fullheight"
      >
        <li class="is-flex is-justify-content-space-between">
          <div v-if="order.order_type" class="is-flexf is-flex-wrap-wrap">
            <h4 class="mr-2">
              <order-card-locale v-if="order.locale" :locale="order.locale" />

              {{ order.order_texts_number ? order.order_texts_number : "" }}
              {{ $t(`orders.texts.type.${order.order_type}`) }}
            </h4>
            <div class="is-flex is-align-items-center has-text-blue-light">
              <IconHashtag />
              <p class="body1 ml-1">
                {{ order.reference }}
              </p>
            </div>
          </div>
          <h4 v-else>Nouvelle commande</h4>

          <div class="is-flex is-flex-gap-3">
            <BtnResumeOrderSubscription
              v-if="
                order.subscription_status ==
                ORDER_SUBSCRIPTION_STATUSES.SUSPENDED
              "
              size="is-small"
              :order="order"
              @get-data="$router.push(link)"
            />

            <router-link
              v-if="order.unviewed_comments_count"
              :to="trackingLink"
              class="button has-border-pink is-small is-inverted has-hover-bottom has-text-pink is-flex is-align-items-center"
            >
              <IconComment class="icon-size-1_3" />
            </router-link>

            <button
              v-if="order.status > 3 || order.has_subscription"
              class="button has-border-blue is-small is-inverted has-hover-bottom has-text-blue is-flex is-align-items-center"
              @click.prevent="isOrderDuplicatorModalActive = true"
            >
              <IconCopy class="icon-size-1_3" />
            </button>
          </div>
        </li>
        <li
          v-if="order.desired_delivery_date"
          class="is-flex is-align-items-center mb-2 has-text-blue-light"
        >
          <IconCalendar />
          <p class="body1 ml-1">
            {{ order.desired_delivery_date | toDate }}
          </p>
        </li>
        <li v-if="order.writers">
          <UsersAvatars
            :editors-list="order.writers"
            :borderColor="borderColor"
          />
        </li>
        <li class="is-flex is-align-items-end is-justify-content-space-between">
          <StatusOrderCard :status="order.status" options="orders.status" />
          <SubscriptionStatusOrderCard
            v-if="order.subscription_status > 0"
            :status="order.subscription_status"
            options="orders.subscription_status"
          />
          <div
            v-if="order.written_texts && order.order_texts_number"
            class="is-flex is-flex-direction-column is-align-items-end"
          >
            <h4>{{ order.written_texts }}/ {{ order.order_texts_number }}</h4>
            <p class="body2">textes rédigées</p>
          </div>
        </li>
      </ul>
    </router-link>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { ORDER_SUBSCRIPTION_STATUSES } from "@/utils/constants";
import UsersAvatars from "@/components/Model/User/UsersAvatars";
import StatusOrderCard from "@/components/Model/Order/StatusOrderCard";
import SubscriptionStatusOrderCard from "@/components/Model/Order/SubscriptionStatusOrderCard";
import OrderCardLocale from "@/components/Model/Order/OrderCardLocale";
import OrderDuplicateModal from "@/components/Model/Order/OrderDuplicateModal";
import BtnResumeOrderSubscription from "@/components/Model/Order/OrderSubscription/BtnResumeOrderSubscription";

export default {
  name: "OrderCard",
  components: {
    OrderDuplicateModal,
    StatusOrderCard,
    SubscriptionStatusOrderCard,
    UsersAvatars,
    OrderCardLocale,
    BtnResumeOrderSubscription,
  },
  props: {
    order: {},
  },
  data() {
    return {
      borderColor: "white",
      isOrderDuplicatorModalActive: false,
      ORDER_SUBSCRIPTION_STATUSES,
    };
  },
  computed: {
    ...mapState("order", {
      newOrder: (state) => state.newOrder,
    }),
    ...mapState("auth", ["homepage"]),

    link() {
      return (this.order.status && parseInt(this.order.status) === 1) ||
        parseInt(this.order.status) === 2
        ? `${this.homepage}/website/${this.order.website_id}/new-order/${this.order.reference}`
        : `${this.homepage}/website/${this.order.website_id}/order/${this.order.reference}/texts`;
    },
    trackingLink() {
      return `${this.homepage}/website/${this.order.website_id}/order/${this.order.reference}/tracking`;
    },
  },
};
</script>
