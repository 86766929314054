<template>
  <article class="hero box p-4 m-0 is-white my-2">
    <figure>
      <img
        src="/img/svg/web_seo.svg"
        title="illustration webinar seo"
        alt="illustration webinar seo"
      />
    </figure>
    <h4>Comprendre le SEO</h4>
    <p class="body1">Le dernier Webinar Skribix !</p>
    <a
      class="mt-4 has-text-blue-light is-flex is-align-items-center"
      href="https://webikeo.fr/chaine/skribix/"
      target="_blank"
    >
      Réserver ma place <IconArrowRight class="ml-2" />
    </a>
  </article>
</template>
<script>
export default {
  name: "BoxSeoWebinar",
};
</script>
