<template>
  <div>
    <b-loading :is-full-page="dataFetching" :active.sync="dataFetching" />

    <OrderBriefArticle
      v-if="field.isEditableFormat && !isEditing"
      :isEditing="isEditing"
      :field="field"
      :value="field.value"
      @start-editing="isEditing = true"
    />
    <div
      v-if="!field.isEditableFormat || (field.isEditableFormat && isEditing)"
    >
      <div class="isSmallField mt-2" v-if="isSmallField">
        <b-field :label="field.label ? field.label : ''" />
      </div>

      <div v-if="options" class="columns is-multiline mt-2">
        <b-field
          v-for="option in typeOptions"
          :key="option.id"
          class="column is-4-tablet is-4-desktop is-4-widescreen px-2 py-1"
          :disabled="field.is_disabled || requiredOptionIds.includes(option.id)"
        >
          <b-tooltip
            position="is-top"
            multilined
            class="is-fullwidth"
            :label="option.description"
            :active="Boolean(option.description) && !field.is_disabled"
          >
            <input
              v-model="selected"
              type="checkbox"
              class="labelForCheck"
              :id="`options-${option.id}`"
              :value="option.id"
              :disabled="
                field.is_disabled || requiredOptionIds.includes(option.id)
              "
            />

            <label
              class="b-checkbox checkbox button is-fullwidth"
              :for="`options-${option.id}`"
              :disabled="
                field.is_disabled || requiredOptionIds.includes(option.id)
              "
            >
              <div>
                {{ option.name }}

                <b-tag
                  v-if="recommendedOptionIds.includes(option.id)"
                  type="is-primary"
                  class="recommended-option-tag"
                >
                  Recommandé
                </b-tag>

                <p>
                  <span v-if="option.price">
                    +{{ option.price | toCurrency }} par
                    {{ billedBy(option.billing_type) }}
                  </span>
                  <span v-else>Gratuit</span>
                </p>
              </div>
            </label>
          </b-tooltip>
        </b-field>
      </div>

      <CardValidationField
        v-if="!selected || (selected && selected.length === 0)"
        :field="field.isValid"
        :is-next-step="isNextStep"
        :message="$t('wizard.message.required')"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CardValidationField from "@/components/UI/card/CardValidationField";
import OrderBriefArticle from "@/components/Model/Order/OrderBriefArticle";

export default {
  name: "order-text-options-form-item",
  props: ["field", "indexStep", "indexField", "isNextStep", "isSmallField"],
  components: { CardValidationField, OrderBriefArticle },

  data() {
    return {
      isEditing: false,
      dataFetching: false,
      selected: this.field.value ? this.field.value : [],
    };
  },

  methods: {
    ...mapActions("option", ["getOptions"]),

    billedBy(billing_type) {
      switch (+billing_type) {
        case 1:
          return "mot";
        case 2:
          return "texte";
        case 3:
          return "commande";
        case 4:
          return "unité";
        default:
          return "(non renseigné)";
      }
    },

    selectRequiredAndRecommendedOptions() {
      this.selected = [...this.requiredOptionIds, ...this.recommendedOptionIds];
    },

    selectRequiredOptions() {
      let selected = new Set(this.selected.concat(this.requiredOptionIds));
      this.selected = [...selected];
    },
  },

  created() {
    this.optionsConfig.filters = { is_active: true };
    this.optionsConfig.include = "type_parameter_values";
    this.dataFetching = true;

    this.getOptions()
      .then(() => {
        if (!this.selected?.length) {
          this.selectRequiredAndRecommendedOptions();
        } else {
          this.selectRequiredOptions();
        }
      })
      .finally(() => {
        this.dataFetching = false;
      });
  },

  watch: {
    selected: function (newValue) {
      this.$emit("save-result", {
        index: this.indexStep,
        index_field: this.indexField,
        value_name: this.field.value_name,
        value: newValue,
      });
    },

    "newOrder.order_type": function () {
      this.selectRequiredAndRecommendedOptions();
    },
  },

  computed: {
    ...mapState("option", ["options", "optionsConfig"]),
    ...mapState("order", ["newOrder"]),

    typeOptions() {
      if (!this.newOrder.order_type || !this.options) {
        return this.options;
      }

      return this.options.filter((option) => {
        if (!option.type_parameter_values) {
          return false;
        }

        return option.type_parameter_values.parameterized.includes(
          +this.newOrder.order_type
        );
      });
    },

    requiredOptionIds() {
      if (!this.newOrder.order_type) {
        return [];
      }

      return this.typeOptions
        .filter((option) =>
          option.type_parameter_values.required.includes(
            +this.newOrder.order_type
          )
        )
        .map((option) => option.id);
    },

    recommendedOptionIds() {
      if (!this.newOrder.order_type) {
        return [];
      }

      return this.typeOptions
        .filter(
          (option) =>
            option.type_parameter_values.recommended.includes(
              +this.newOrder.order_type
            ) && !this.requiredOptionIds.includes(option.id)
        )
        .map((option) => option.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.recommended-option-tag {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 0.65rem;
}

.b-radio:has([type="radio"]:checked) {
  background-color: #fef2f5;
  border: 1px solid #f35987;

  & > div {
    color: #f35987 !important;
  }
}

::v-deep .b-checkbox[disabled="disabled"] {
  cursor: default !important;
  pointer-events: none !important;
}
</style>
