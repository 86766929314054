<template>
  <div>
    <main class="section" v-if="isLoading">
      <form
        class="column is-12-mobile is-12-tablet is-12-desktop is-12-widescreen is-12-fullhd is-flex is-flex-direction-column is-justify-content-space-evenly m-auto"
      >
        <div
          v-for="(element, index) in fieldsUserProfile"
          class="my-1"
          :key="index"
          style="height: 100%"
        >
          <p class="has-text-blue bold mb-3" v-if="element.title">
            {{ element.title }}
          </p>
          <component
            :key="index"
            :indexStep="index"
            :indexField="index"
            :is="element.name"
            :field="element"
            :isSmallField="element.small"
            :isNextStep="isNextStep"
            @save-result="saveResult"
            :isNotWizard="true"
            class="is-flex is-flex-direction-column is-justify-content-space-evenly mt-1"
          />
        </div>
      </form>
    </main>
  </div>
</template>

<script>
import OrderTypesMultiSelect from "@/components/Model/Form/FormItem/OrderTypesMultiSelect";

export default {
  name: "TypeTextsFormItem",
  props: ["field", "indexStep", "indexField"],
  components: {
    OrderTypesMultiSelect,
  },
  data() {
    return {
      isLoading: true,
      isNextStep: false,
    };
  },
  computed: {
    candidateProfile: {
      get() {
        return this.$store.state.user.candidateProfile;
      },
      set(value) {
        this.$store.commit("user/setCandidateProfile", value);
      },
    },
    fieldsUserProfile() {
      return [
        {
          id: 3,
          title: "Type de textes",
          name: "OrderTypesMultiSelect",
          value_name: "order_types",
          required: true,
          value: this.candidateProfile.preferences.order_types
            ? this.candidateProfile.preferences.order_types
            : [],
        },
      ];
    },
  },
  methods: {
    saveResult(result) {
      this.candidateProfile.preferences[result.value_name] = result.value;
      this.fieldsUserProfile[result.index_field].value = result.value;
    },

    validStep(steps) {
      this.isNextStep = true;
      steps.forEach((element) => {
        if (element.required === true) {
          if (
            (Array.isArray(element.value) &&
              element.value.every((elem) => elem === null)) ||
            (Array.isArray(element.value) && element.value.length === 0) ||
            (Array.isArray(element.value) && element.hasError)
          ) {
            element.isValid = false;
          } else if (
            element.value & (typeof element.value === "object") &&
            Object.keys(element.value) &&
            Object.keys(element.value).length === 0
          ) {
            element.isValid = false;
          } else if (
            !element.value ||
            element.value === " " ||
            element.hasError
          ) {
            element.isValid = false;
          } else {
            element.isValid = true;
          }
        } else {
          if (element.hasError) {
            element.isValid = false;
          } else {
            element.isValid = true;
          }
        }
      });

      if (steps.filter((element) => element.isValid === false).length === 0) {
        steps.isStepValid = true;
      } else {
        steps.isStepValid = false;
      }
    },
  },
};
</script>
<style scoped>
.section {
  padding: 0;
}
</style>
