<template>
  <div>
    <DashboardHeader />

    <b-tabs v-model="activeTab" class="px-6 userTab">
      <b-tab-item
        v-for="tab in tabs"
        :key="tab.label"
        :label="tab.label"
        :value="tab.value"
      >
        <component
          v-if="tab.value == activeTab || tab.opened"
          :is="tab.component"
        />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import DashboardHeader from "@/components/UI/DashboardHeader";
import AdminThematics from "@/components/Model/Admin/AdminThematics";
import AdminOptions from "@/components/Model/Admin/AdminOptions";
import AdminUser from "@/components/Model/Admin/AdminUser.vue";
import AdminCompany from "@/components/Model/Admin/AdminCompany.vue";
import AdminTemplates from "@/components/Model/Admin/AdminTemplates.vue";

export default {
  name: "DashboardAdmin",
  components: {
    AdminUser,
    AdminCompany,
    AdminOptions,
    DashboardHeader,
    AdminThematics,
    AdminTemplates,
  },
  data() {
    const activeTab = this.$route.query.tab || "users";

    return {
      activeTab,
      tabs: [
        {
          label: "Users",
          value: "users",
          component: AdminUser,
          opened: false,
        },
        {
          label: "Companies",
          value: "companies",
          component: AdminCompany,
          opened: false,
        },
        {
          label: "Options",
          value: "options",
          component: AdminOptions,
          opened: false,
        },
        {
          label: "Thematics",
          value: "thematics",
          component: AdminThematics,
          opened: false,
        },
        {
          label: "Garbarits",
          value: "templates",
          component: AdminTemplates,
          opened: false,
        },
      ],
    };
  },
  watch: {
    activeTab(val) {
      this.$router.replace({
        query: { tab: val },
      });

      this.setActiveTabAsOpened();
    },
  },
  methods: {
    setActiveTabAsOpened() {
      this.tabs.find((tab) => tab.value == this.activeTab).opened = true;
    },
  },
};
</script>
