export default {
  parentsThematics(state) {
    let parentsThematics = [];
    state.thematics.map((parent) => {
      if (!parent.parent_id) {
        parentsThematics.push(parent);
      }
    });

    return parentsThematics;
  },
  subThematics(state, getters, rootState) {
    let subThematics = [];

    if (rootState.website.website.thematic_id.length > 0) {
      state.thematics.map((parent) => {
        if (
          rootState.website.website.thematic_id.includes(parent.parent_id) ||
          rootState.website.website.thematic_id.includes(parent.id)
        ) {
          subThematics.push(parent);
        }
      });
    }
    return subThematics;
  },

  subCategorie(state, getters, rootState) {
    let categories = {};
    let categorie = {};

    if (rootState.website.website.thematic_id.length > 0) {
      state.thematics.forEach((element) => {
        if (rootState.website.website.thematic_id.includes(element.parent_id)) {
          if (element.parent_id) {
            categories[element.parent_id] = [];
            categories[element.parent_id].id = element.parent_id;
            categorie = state.thematics.filter(
              (el) => el.id === element.parent_id
            );

            if (categorie.length > 0 && categorie[0].name) {
              categories[element.parent_id].name = categorie[0].name;
            }

            state.thematics.forEach((category) => {
              if (category.parent_id === element.parent_id) {
                categories[element.parent_id].push(category);
              }
            });
          }
        }
      });
    }

    return categories;
  },
};
