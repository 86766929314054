import { POST } from "@/config/backend";

export default {
  async login({ commit, dispatch }, user) {
    const response = await POST(`/tokens`, user);

    if (!response.data.token || response.data.token.length <= 0) {
      throw new Error("Token not found");
    }

    const token = response.data.token;

    commit("setToken", token);
    commit("setUser", response.data.user);
    commit("setIsConnected", true);

    window.localStorage.setItem("token", token);

    dispatch("initialiseStore", null, { root: true });

    return response;
  },

  async connectUser({ commit, dispatch }, user) {
    const response = await POST(`/connect-user/${user}`);

    if (!response.data.token || response.data.token.length <= 0) {
      throw new Error("Token not found");
    }

    const token = response.data.token;

    commit("setToken", token);
    commit("setUser", response.data.user);
    commit("setIsConnected", true);

    window.localStorage.setItem("token", token);

    dispatch("initialiseStore", null, { root: true });
    return response;
  },

  logout({ commit }) {
    localStorage.clear();
    commit("setIsConnected", false);
    commit("order/resetOrderCounts", null, { root: true });
  },

  async forgotPassword(context, data) {
    return await POST(`password/forgot`, data);
  },

  async resetPassword(context, data) {
    return await POST(`password/reset`, data);
  },

  async verifyPasswordToken(context, data) {
    return await POST(`password/verify-token`, data);
  },

  async updateLocale({ rootState, state }, locale) {
    return await POST(
      `${rootState.baseURL}/users/${state.user.id}/locale`,
      locale
    );
  },

  async loginFromFront({ commit, dispatch }, url) {
    const response = await POST(url);

    if (!response.data.token || response.data.token.length <= 0) {
      throw new Error("Token not found");
    }

    const token = response.data.token;

    commit("setToken", token);
    commit("setUser", response.data.user);
    commit("setIsConnected", true);

    window.localStorage.setItem("token", token);

    dispatch("initialiseStore", null, { root: true });
    return response;
  },
};
