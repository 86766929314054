import { render, staticRenderFns } from "./OrdersTable.vue?vue&type=template&id=a1f0c134&scoped=true"
import script from "./OrdersTable.vue?vue&type=script&lang=js"
export * from "./OrdersTable.vue?vue&type=script&lang=js"
import style0 from "./OrdersTable.vue?vue&type=style&index=0&id=a1f0c134&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1f0c134",
  null
  
)

export default component.exports