<template>
  <div
    class="is-flex is-flex-direction-column is-justify-content-space-between"
  >
    <section
      class="section pt-4 pb-6 is-flex is-flex-wrap-wrap is-justify-content-space-between has-background-white"
    >
      <div v-if="candidateProfile.preferences.status === 9">
        <h2 class="pb-4">Merci d'avoir soumis ta candidature !</h2>
        <p>Et maintenant ?</p>
        <p>Notre onboarding manager va prendre connaissance de ton profil.</p>
        <p>Tu recevras un e-mail pour te donner le résultat de ta sélection.</p>
        <p>Si tu es choisi, nous te proposerons de rédiger un texte test..</p>
        <p>
          Si tu réussis le test, tu seras définitivement intégré à l'équipe de
          rédacteurs Skribix.
        </p>
      </div>
      <div v-else>
        <h2 class="pb-4">Ta candidature a été rejetée</h2>
        <p>Et maintenant ?</p>
        <p>
          Tu pourras de nouveau soumettre ta candidature dans 3 mois. En
          attendant, tu dois t'entrainer pour atteindre le niveau attendu.
          Encore merci d'avoir tenté ta chance avec nous.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  title: "title.user.candidatePending",
  name: "CandidatePending",
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    candidateProfile: {
      get() {
        return this.$store.state.user.candidateProfile;
      },
      set(value) {
        this.$store.commit("user/setCandidateProfile", value);
      },
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .hero-head {
    height: 65vh;
  }
}
@media (min-width: 768px) and (max-width: 1210px) {
  .hero-head {
    height: 40vh;
  }
}
</style>
