<template>
  <div>
    <div class="columns is-multiline">
      <b-field
        v-for="option in options"
        :key="option"
        class="column is-4-tablet is-4-desktop is-4-widescreen px-2 py-1"
      >
        <input
          v-model="selected"
          type="checkbox"
          class="labelForCheck"
          :id="$t(`wizard.order.text.language.${option}.value`)"
          :value="$t(`wizard.order.text.language.${option}.value`)"
        />
        <label
          class="b-checkbox checkbox button is-fullwidth"
          :for="$t(`wizard.order.text.language.${option}.value`)"
        >
          {{ $t(`wizard.order.text.language.${option}.name`) }}
        </label>
      </b-field>
      <CardValidationField
        v-if="!selected || (selected && selected.length === 0)"
        :field="field.isValid"
        :is-next-step="isNextStep"
        :message="$t('wizard.message.required')"
      />
    </div>
  </div>
</template>

<script>
import CardValidationField from "@/components/UI/card/CardValidationField";

export default {
  name: "locales-multi-select",
  props: ["field", "indexStep", "indexField", "isNextStep", "isSmallField"],
  components: { CardValidationField },
  data() {
    return {
      isEditing: false,
      selected: this.field.value ? this.field.value : null,
      options: Object.keys(this.$t("wizard.order.text.language")).length,
    };
  },
  watch: {
    selected: function (newValue) {
      this.$emit("save-result", {
        index: this.indexStep,
        index_field: this.indexField,
        value_name: this.field.value_name,
        value: newValue,
      });
    },
  },
};
</script>
