<template>
  <div>
    <b-loading v-if="dataProcessing" active />
    <ButtonIcon
      color="pink"
      icon="IconCheck"
      :size="size"
      :text="$t('writers.actions.reactivate')"
      :disabled="dataProcessing"
      :loading="dataProcessing"
      @click-button="deactivate"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ButtonIcon from "@/components/UI/buttons/ButtonIcon";

export default {
  name: "BtnDeactivateUser",
  components: {
    ButtonIcon,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    size: {
      default: "small",
      type: String,
    },
  },
  data() {
    return {
      dataProcessing: false,
    };
  },
  methods: {
    ...mapActions("user", ["activateUser"]),

    deactivate() {
      this.$buefy.dialog.confirm({
        title: "Réactivation d'un rédacteur",
        message: "Êtes-vous sûr de vouloir réactiver ce rédacteur ?",
        confirmText: "Réactiver",
        cancelText: "Annuler",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          if (this.dataProcessing) {
            return;
          }

          this.dataProcessing = true;

          try {
            await this.activateUser(this.user.reference);
            this.$store.dispatch("notify", {
              type: "is-success",
              message: "Le rédacteur a été réactivé avec succès.",
            });
            this.$emit("user-satus-changed");
          } catch (error) {
            this.dataProcessing = false;
          }
        },
      });
    },
  },
};
</script>
