<template>
  <form v-if="isComponentRendered">
    <div class="columns">
      <div class="column is-one-third">
        <b-field horizontal label="Nom">
          <b-input
            v-model="activeTemplateData.name"
            type="text"
            placeholder="Nom du gabarit"
            required
          />
        </b-field>
        <b-field horizontal label="Type de texte">
          <b-select
            v-model="activeTemplateData.order_type_id"
            placeholder="Choisir un type de texte ..."
            required
          >
            <option
              v-for="(orderType, index) in availableOrderTypeOptions"
              :value="orderType[0]"
              :key="index"
            >
              {{ orderType[1] }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>

    <TemplateConstructFormItem
      is-admin-form
      :templateId="activeTemplateData.id"
    />

    <div class="is-flex is-justify-content-flex-end py-5">
      <b-button
        type="is-primary"
        :loading="saving"
        :disabled="!canSubmit"
        @click.prevent="submit"
      >
        Sauvegarder
      </b-button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import TemplateConstructFormItem from "@/components/Model/Form/TemplateFormItem/TemplateConstructFormItem";

export default {
  name: "TemplateForm",
  components: { TemplateConstructFormItem },
  data() {
    return {
      saving: false,
      isComponentRendered: true,
      initialOrderTypeId: null,
    };
  },
  computed: {
    ...mapState("template", ["activeTemplateData", "templates"]),

    availableOrderTypeOptions() {
      return Object.entries(this.$t("orders.texts.type")).filter(
        ([order_type_id]) =>
          order_type_id == this.initialOrderTypeId ||
          !this.templates?.some(
            (template) => template.order_type_id == order_type_id
          )
      );
    },

    canSubmit() {
      return (
        this.activeTemplateData.name &&
        this.activeTemplateData.order_type_id &&
        this.activeTemplateData.blocks.length
      );
    },
  },
  watch: {
    "activeTemplateData.id": async function () {
      this.isComponentRendered = false;
      await this.$nextTick();

      this.initialOrderTypeId = this.activeTemplateData?.order_type_id || null;
      this.isComponentRendered = true;
    },
  },

  methods: {
    ...mapActions("template", ["createTemplate", "updateTemplate"]),

    submit() {
      if (!this.canSubmit) {
        return;
      }

      this.activeTemplateData.id ? this.update() : this.create();
    },

    create() {
      this.saving = true;

      this.createTemplate(this.activeTemplateData)
        .then(() => {
          this.$store.dispatch("notify", {
            type: "is-success",
            message: `Le gabarit a bien été créé`,
          });
          this.$emit("saved");
        })
        .catch(() => {
          this.$store.dispatch("notify", {
            type: "is-danger",
            message: `Une erreur est survenue lors de la création du gabarit`,
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },

    update() {
      this.saving = true;

      this.updateTemplate({
        templateId: this.activeTemplateData.id,
        data: this.activeTemplateData,
      })
        .then(() => {
          this.$store.dispatch("notify", {
            type: "is-success",
            message: `Le gabarit a bien été modifiée`,
          });
          this.$emit("saved");
        })
        .catch(() => {
          this.$store.dispatch("notify", {
            type: "is-danger",
            message: `Une erreur est survenue lors de la modification du gabarit`,
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .field {
  .field-label {
    min-width: 8rem;
  }

  .select,
  .select select,
  .input {
    width: 100%;
    min-width: 15rem;
  }
}
</style>
