<template>
  <div>
    <section v-if="!isLoading">
      <div class="is-flex-tablet is-justify-content-space-between">
        <OrdersFilters
          class="mb-2"
          fieldSearch="reference"
          :with_count="true"
          :data="orders.data"
          :options="corrector ? 'orders.correctorStatus' : 'orders.status'"
          :excepted-options="writer ? [1, 2, 3, 4, 11] : []"
          :only-options="
            Array.isArray(defaultFilter?.status) ? defaultFilter.status : []
          "
          @search-texts="searchTexts"
          @filter-status="filterStatus"
          @filter-period="filterPeriod"
        />

        <span>
          <ButtonRouterLink
            v-if="editorManager || admin"
            size="large"
            :link="`${homepage}/new-order`"
            :text="$t('orders.new')"
            icon="IconPlus"
            color="pink"
          />
        </span>
      </div>
      <div
        v-if="checkedRows && checkedRows.length > 0"
        class="is-flex is-flex-wrap-wrap"
      >
        <p
          v-if="checkedRows.length === 1"
          class="body1 bold has-text-blue mr-2"
        >
          {{ checkedRows.length }} commande sélectionnée
        </p>
        <p v-else class="body1 bold has-text-blue mr-2">
          {{ checkedRows.length }} commande sélectionnées
        </p>

        <ButtonIcon
          v-if="editorManager || admin"
          color="pink"
          size="large"
          :text="$t('orders.actions.order_start')"
          icon="IconCheck"
          class="mb-4"
          @click-button="startOrdersSelected"
          :disabled="
            isStartOrderSelectedActive ||
            checkedRows.filter((element) => parseInt(element.status) === 4)
              .length === 0
          "
        />
      </div>

      <OrdersTable
        class="mt-4"
        :orders="orders"
        :checkedRows="checkedRows"
        :loading="isGetDataLoading"
        :default-sort="[ordersConfig.sort, ordersConfig.sortDirection]"
        :active-page="ordersConfig.page"
        :active-per-page="ordersConfig.per_page"
        :default-filters="ordersConfig.filters"
        @update:checkedRows="($event) => (checkedRows = $event)"
        @page-change="onPageChange"
        @per-page-change="onPerPageChange"
        @sort="onSort"
        @filters-change="onFilterChange"
      />
    </section>
    <section v-else>
      <b-loading v-model="isLoading" />
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import {
  retrieveFiltersFromUrlParams,
  addFiltersToUrlParams,
} from "@/utils/index";
import OrdersFilters from "@/components/Model/Text/TextsFilters";
import OrdersTable from "@/components/Model/Order/OrdersTable";
import ButtonRouterLink from "@/components/UI/buttons/ButtonRouterLink";
import ButtonIcon from "@/components/UI/buttons/ButtonIcon";

export default {
  name: "OrdersTab",

  components: {
    ButtonRouterLink,
    ButtonIcon,
    OrdersFilters,
    OrdersTable,
  },

  props: {
    useLocalState: {
      type: Boolean,
      default: false,
    },

    defaultFilter: {
      type: Object,
    },

    defaultSort: {
      type: Object,
      default: () => ({
        field: "created_at",
        direction: "desc",
      }),
    },

    defaultInclude: {
      type: Array,
      default: () => [],
    },

    defaultPage: {
      type: Number,
      default: 1,
    },

    defaultPerPage: {
      type: Number,
      default: 20,
    },
  },

  created() {
    this.ordersConfig.page = this.defaultPage;
    this.ordersConfig.per_page = this.defaultPerPage;
    this.ordersConfig.sort = this.defaultSort.field;
    this.ordersConfig.sortDirection = this.defaultSort.direction;

    const hasDefaultFilter =
      this.defaultFilter && Object.keys(this.defaultFilter).length > 0;

    this.ordersConfig.filters = retrieveFiltersFromUrlParams(this.$route.query);

    if (hasDefaultFilter) {
      this.ordersConfig.filters = {
        ...this.ordersConfig.filters,
        ...this.defaultFilter,
      };
    }

    if (this.editorManager && !hasDefaultFilter) {
      this.filterStatus([4, 5, 6, 7, 8]);
    } else {
      this.getData();
    }
  },

  data() {
    return {
      buttonSize: "small",
      isLoading: true,
      checkboxPosition: "left",
      checkedRows: [],
      borderColor: "white",
      isUpdateStatusActive: false,
      isGetDataLoading: false,
      isStartOrderSelectedActive: false,
      localOrders: {},
      localOrdersConfig: {
        include: `${this.$store.state.order.ordersConfig.include}`,
        filters: {},
      },
    };
  },

  computed: {
    ...mapState("auth", ["homepage"]),
    ...mapGetters("auth", [
      "admin",
      "editorManager",
      "corrector",
      "writer",
      "candidate",
      "clientRoleOnly",
    ]),

    orders() {
      return this.useLocalState
        ? this.localOrders
        : this.$store.state.order.orders;
    },

    ordersConfig: {
      get() {
        if (this.useLocalState) {
          return this.localOrdersConfig;
        }

        return this.$store.state.order.ordersConfig;
      },
      set(value) {
        if (this.useLocalState) {
          this.localOrdersConfig = value;
        } else {
          this.$store.commit("wizard/setOrdersConfig", value);
        }
      },
    },
  },

  methods: {
    ...mapActions("order", ["getOrders", "getUpdateStatus"]),

    searchTexts(newValue) {
      this.ordersConfig.filters.by_reference = newValue;

      this.getData();
    },

    filterStatus(newStatusValue) {
      if (!newStatusValue?.length) {
        // if no status is selected, we reset the status filter to the default value
        this.ordersConfig.filters.status = this.defaultFilter?.status || [];
      } else {
        this.ordersConfig.filters.status = newStatusValue;
      }

      this.ordersConfig.page = 1;

      this.getData();
    },

    filterPeriod(newValue) {
      this.ordersConfig.filters.between = newValue;

      this.getData();
    },

    getData() {
      if (this.isGetDataLoading) {
        return Promise.resolve();
      }

      this.isGetDataLoading = true;

      this.setIncludes();
      this.setResourceFields();

      return this.getOrders({
        ordersConfig: this.ordersConfig,
        useLocalState: this.useLocalState,
      })
        .then((response) => {
          this.isLoading = false;

          if (this.useLocalState) {
            this.localOrders = response.data.orders;
          }

          if (
            this.isStartOrderSelectedActive &&
            this.checkedRows.filter((el) => parseInt(el.status) === 4).length >
              0
          ) {
            this.isStartOrderSelectedActive = false;
            this.checkedRows = [];
          }
        })
        .catch((e) => {
          this.$store.dispatch("fireError", e);
        })
        .finally(() => {
          this.isGetDataLoading = false;
        });
    },

    setIncludes() {
      let includes = [
        ...this.defaultInclude,
        ...[
          "website",
          "company",
          "texts",
          "previous_subscription_cycle_texts_count",
        ],
      ];

      if (this.editorManager || (!this.writer && !this.candidate)) {
        includes.push("user", "applicants", "writers");
      }

      this.ordersConfig.include = includes.join(",");
    },

    setResourceFields() {
      this.ordersConfig.resource_fields = {
        order: [
          "reference",
          "locale",
          "order_texts_number",
          "order_type",
          "desired_delivery_date",
          "date_before_delivery_date",
          "status",
          "subjects_status",
          "website_id",
          "type",
          "texts_last_return_histories",
          "is_internal_order",
          "is_created_by",
          ...this.ordersConfig.include.split(","),
        ],
        user: [
          "reference",
          "firstname",
          "lastname",
          "avatar",
          "avatar_thumb_cropped",
        ],
        company: ["reference", "name"],
        thematic: ["name"],
        text: ["reference", "corrector_id", "writer_id", "status"],
        website: ["domain", "has_brief", "company_id"],
      };

      if (this.editorManager || this.writer) {
        this.ordersConfig.resource_fields.order.push("writer_level");
      }

      if (this.editorManager || this.corrector) {
        this.ordersConfig.resource_fields.order.push(
          "written_content_percentage",
          "total_content_length"
        );
      }

      if (this.admin || this.editorManager || this.corrector) {
        this.ordersConfig.resource_fields.order.push("writers");
      }

      if (this.editorManager || this.writer || this.corrector) {
        this.ordersConfig.resource_fields.order.push(
          "order_texts_number",
          "order_texts_attribued_number"
        );
      }

      if (!this.clientRoleOnly) {
        this.ordersConfig.resource_fields.order.push("users_under_editing");
      }
    },

    startOrdersSelected() {
      if (!this.isStartOrderSelectedActive) {
        this.isStartOrderSelectedActive = true;

        this.checkedRows.forEach((element) => {
          if (parseInt(element.status) === 4) {
            this.isUpdateStatusActive = false;
            this.changeOrderStatus(element.reference);
          }
        });
      }
    },

    changeOrderStatus(ref) {
      if (!this.isUpdateStatusActive) {
        this.isUpdateStatusActive = true;

        this.getUpdateStatus(ref)
          .then(() => {
            this.getData();
          })
          .catch((e) => {
            this.$store.dispatch("fireError", e);
          })
          .finally(() => {
            this.isUpdateStatusActive = false;
          });
      }
    },

    onPageChange(page) {
      this.ordersConfig.page = page;

      this.getData();
    },

    onPerPageChange(per_page) {
      this.ordersConfig.per_page = per_page;

      this.getData();
    },

    onSort(sort) {
      this.ordersConfig.sort = sort.field;
      this.ordersConfig.sortDirection = sort.order;

      this.getData();
    },

    onFilterChange(filter) {
      let updatedFilters = {
        ...this.ordersConfig.filters,
      };

      // Update filters based on the provided filter object
      Object.keys(filter).forEach((element) => {
        updatedFilters[element] = filter[element];
      });

      // Merge defaultFilter with updatedFilters
      if (this.defaultFilter && Object.keys(this.defaultFilter).length) {
        Object.keys(this.defaultFilter).forEach((key) => {
          const defaultFilterValue = this.defaultFilter[key];
          const updatedFilterValue = updatedFilters[key];

          // Check if both defaultFilter and updatedFilters have array values for the key
          if (Array.isArray(defaultFilterValue) && updatedFilterValue) {
            let mergedFilters = new Set([
              ...defaultFilterValue,
              ...updatedFilterValue,
            ]);

            updatedFilters[key] = [...mergedFilters];
          } else {
            updatedFilters[key] = defaultFilterValue;
          }
        });
      }

      this.ordersConfig.filters = updatedFilters;

      this.getData();

      addFiltersToUrlParams(updatedFilters, this.$route.query);
    },
  },
};
</script>
