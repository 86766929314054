<template>
  <figure
    class="image is-flex is-align-items-center is-justify-content-center"
    :class="{
      'is-online': isOnline,
      'online-animated': hasBlinkAnimation && isOnline,
      [`is-${size}x${size}`]: true,
    }"
  >
    <b-tooltip
      multilined
      position="is-bottom"
      class="is-fullwidth"
      :label="tooltip"
      :active="Boolean(tooltip)"
    >
      <img
        v-if="user.avatar && isImageFound"
        class="is-rounded rounded"
        height="auto"
        width="auto"
        :alt="!tooltip ? 'photo compte utilisateur skribix' : ''"
        :src="user.avatar_thumb_cropped || user.avatar"
        @error="handleImageError"
      />
      <div
        v-else
        class="divIconUser is-flex is-align-items-center is-justify-content-center"
        :class="{
          'has-text-white': colorIconWhite,
          'has-text-blue': !colorIconWhite,
        }"
      >
        <IconUser
          class="icon-size-1_2"
          :class="{
            'has-fill-white': colorIconWhite,
            'has-fill-blue': !colorIconWhite,
          }"
        />
      </div>
    </b-tooltip>
  </figure>
</template>
<script>
export default {
  name: "user-avatar",
  props: {
    user: {
      type: Object,
      required: true,
    },
    tooltip: {
      type: String,
    },
    colorIconWhite: {
      type: Boolean,
      default: false,
    },
    isOnline: {
      type: Boolean,
      default: false,
    },
    hasBlinkAnimation: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 32,
    },
  },

  data() {
    return {
      isImageFound: true,
    };
  },

  methods: {
    handleImageError() {
      this.isImageFound = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.divIconUser {
  padding: 0.5em;
}

.is-online {
  $imageElementClass: "&:has(.divIconUser), img";
  #{$imageElementClass} {
    border: 3px solid rgba(#48c774, 0.7) !important;
  }

  &.online-animated {
    #{$imageElementClass} {
      animation: blink 2s infinite;
    }

    &-5 {
      #{$imageElementClass} {
        animation-duration: 5s !important;
      }
    }
  }
}

@keyframes blink {
  0% {
    box-shadow: 0 0 2px 2px rgba(72, 199, 116, 0.2);
  }
  40% {
    box-shadow: 0 0 8px 5px rgba(72, 199, 116, 0.4);
  }
  100% {
    box-shadow: 0 0 2px 2px rgba(72, 199, 116, 0.2);
  }
}
</style>
