<template>
  <div>
    <b-loading :active.sync="dataFetching" />
    <div v-if="isLoading">
      <FormStepsCandidate
        v-if="
          status === CANDIDATE_STATUSES.DRAFT ||
          status === CANDIDATE_STATUSES.AWAITING_CONTRACT
        "
        :isNewCandidate="isNewCandidate"
        :isLoading="isLoading"
        @after-save="afterSave"
      />
      <CandidatePending
        v-if="
          status === CANDIDATE_STATUSES.PENDING ||
          status === CANDIDATE_STATUSES.REJECTED
        "
        :isNewCandidate="isNewCandidate"
        :isLoading="isLoading"
      />
      <div v-if="status === CANDIDATE_STATUSES.AWAITING_TEST">
        <DashboardHeader />
        <OrdersTab class="pt-4 p-2" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  prepareTextsUploadValue,
  prepareAvatarUploadValue,
  prepareSubjectsValue,
} from "@/utils/candidate";
import { CANDIDATE_STATUSES } from "@/utils/constants";
import FormStepsCandidate from "@/components/Model/Form/FormStepsCandidate";
import CandidatePending from "@/views/User/CandidatePending";
import OrdersTab from "@/components/Model/Order/OrdersTab";
import DashboardHeader from "@/components/UI/DashboardHeader";

export default {
  title: "title.form.new_candidate",
  name: "FormNewCandidate",
  components: {
    FormStepsCandidate,
    CandidatePending,
    OrdersTab,
    DashboardHeader,
  },
  data() {
    return {
      isNewCandidate: true,
      dataFetching: false,
      isLoading: false,
      status: 1,
      CANDIDATE_STATUSES,
    };
  },
  methods: {
    ...mapActions("user", ["getCandidateProfile"]),
    ...mapActions("thematic", ["getThematics"]),

    async getData() {
      this.dataFetching = true;
      this.isLoading = false;

      let getCandidateProfilePromise = this.getCandidateProfile();
      let getThematicsPromise = this.getThematics();

      Promise.all([getCandidateProfilePromise, getThematicsPromise])
        .then(() => {
          this.isLoading = true;

          this.status = this.candidateProfile.preferences.status;

          if (!this.candidateProfile.company) {
            this.candidateProfile.company = {};
          }

          this.candidateProfile.subjects = prepareSubjectsValue(
            this.candidateProfile.preferences.subjects
          );

          this.candidateProfile.textsUpload = prepareTextsUploadValue(
            this.candidateProfile.texts
          );

          this.candidateProfile.avatarUpload = prepareAvatarUploadValue(
            this.candidateProfile.avatar
          );

          let arrayThematics = [];

          this.candidateProfile.preferences.subjects.forEach((el) =>
            arrayThematics.push(el.id)
          );

          this.candidateProfile.preferences.subjects = arrayThematics;

          this.steps =
            this.candidateProfile.preferences.status ===
            CANDIDATE_STATUSES.DRAFT
              ? this.baseSteps
              : this.fullSteps;

          if (this.writer) {
            this.steps.splice(7, 1);
          }

          this.dataFetching = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    afterSave(candidate) {
      this.status = candidate.preferences.status;

      if (this.status === CANDIDATE_STATUSES.VALIDATE) {
        this.$store.commit("auth/setHomepage");
        this.$router.push("/writer");
      }

      this.$forceUpdate();
    },
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapGetters("auth", ["writer", "candidate"]),

    steps: {
      get() {
        return this.$store.state.wizard.steps;
      },
      set(value) {
        this.$store.commit("wizard/setSteps", value);
      },
    },

    candidateProfile: {
      get() {
        return this.$store.state.user.candidateProfile;
      },
      set(value) {
        this.$store.commit("user/setCandidateProfile", value);
      },
    },

    company: {
      get() {
        return this.$store.state.company.company;
      },
      set(value) {
        this.$store.commit("company/setCompany", value);
      },
    },

    baseSteps() {
      return [
        {
          index: 0,
          form_steps_type: 1,
          label: "Informations personnelles",
          title: "Tes informations personnelles",
          description:
            "Bienvenue dans l'espace de création de ton profil Skribix. ",
          duration: "1 min",
          step_count: 1,
          content: [
            {
              index: 0,
              name: "PersonnalInformation",
              required: true,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: false,
          isStepValid: false,
        },
        {
          index: 1,
          form_steps_type: 2,
          label: "Coordonnées de facturation",
          title: "Tes Coordonnées de Facturation",
          description:
            "Nous ne sélectionnons que des rédacteurs professionnels qui sont enregistrés dans un pays de l'UE. Si ce n'est pas ton cas, inutile d'aller plus loin.",
          duration: "1 min",
          step_count: 1,
          content: [
            {
              index: 0,
              name: "InvoicesInformation",
              required: true,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 2,
          form_steps_type: 2,
          label: "Formation",
          title: "Ancienneté",
          description:
            "Nous somme curieux de savoir depuis combien de temps tu est dans le métier !",
          duration: "3 min",
          step_count: 3,
          content: [
            {
              index: 0,
              name: "FormationExperience",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 2,
          form_steps_type: 2,
          label: "Formation",
          title: "Formation",
          description: "On veut en savoir plus sur ta formation !",
          duration: "3 min",
          step_count: 3,
          content: [
            {
              index: 0,
              name: "FormationInformation",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 2,
          form_steps_type: 2,
          label: "Formation",
          title: "SEO",
          description: "Évalue ton SEO.",
          duration: "3 min",
          step_count: 3,
          content: [
            {
              index: 0,
              name: "FormationSEO",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 2,
          form_steps_type: 2,
          label: "Rédaction",
          title: "Rédaction",
          description:
            "Ici tu peux nous en dire plus sur la rédaction au niveau professionnel.",
          duration: "2 min",
          step_count: 2,
          content: [
            {
              index: 0,
              name: "FormationRedactionWork",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 2,
          form_steps_type: 2,
          label: "Rédaction",
          title: "Nombre de mots mensuel",
          description:
            "Le nombre de mots maximum que tu peux produire chaque mois.",
          duration: "2 min",
          step_count: 2,
          content: [
            {
              index: 0,
              name: "FormationRedactionQuantityWord",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 2,
          form_steps_type: 2,
          label: "Profil de Rédacteur",
          title: "Ton profil",
          description:
            "Ton profil est visible par le client au moment de sélectionner les rédacteurs qui vont participer à sa commande. Il est donc très important que tu travailles ton profil pour que le client te choisisse. \r Voici une trame de présentation que nous avons conçue afin que tu puisses rédiger ton profil. Celui-ci doit être utile autant au client qu’au staff Skribix. Tu n’es pas obligé(e) de le suivre à la lettre mais il est important que ces informations soient mentionnées.",
          duration: "4 min",
          step_count: 6,
          content: [
            {
              index: 0,
              name: "ProfilInformation",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 2,
          form_steps_type: 2,
          label: "Profil",
          title: "Quels sont tes thématiques préférées ?",
          description:
            "Choisis uniquement les types de contenus que tu maitrises. Nous te proposerons des formations dédiées aux autres types de textes et tu pourras ensuite les ajouter à ton profil.",
          duration: "4 min",
          step_count: 6,
          content: [
            {
              index: 0,
              name: "ProfilThematic",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 3,
          form_steps_type: 2,
          label: "Profil",
          title: "Quels sont tes types de textes préférés ?",
          description:
            "Choisis uniquement les types de contenus que tu maitrises. Nous te proposerons des formations dédiées aux autres types de textes et tu pourras ensuite les ajouter à ton profil.",
          duration: "4 min",
          step_count: 6,
          content: [
            {
              index: 0,
              name: "TypeTexts",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 4,
          form_steps_type: 2,
          label: "Profil",
          title: "Quelles options peux-tu proposer ?",
          description:
            "Attention, pour choisir ces options tu dois pouvoir démontrer tes compétences dans ce domaine.",
          duration: "1 min",
          step_count: 6,
          content: [
            {
              index: 0,
              name: "ProfilOption",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 5,
          form_steps_type: 2,
          label: "Profil",
          title: "Dans quelle langue est-ce-que tu rédiges ?",
          description:
            "Nous recherchons des rédacteurs natifs, pas des traducteurs. Tu ne dois donc sélectionner que ta ou tes langues maternelles.",
          duration: "1 min",
          step_count: 6,
          content: [
            {
              index: 0,
              name: "ProfilLanguage",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 6,
          form_steps_type: 2,
          label: "Profil",
          title: "Tes textes d'exemples",
          description:
            "Uploader quelques textes d'exemples pour qu'on puisse les lire avant de valider ta candidature",
          duration: "1 min",
          step_count: 6,
          content: [
            {
              index: 0,
              name: "ProfilTextExample",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
      ];
    },

    fullSteps() {
      return [
        {
          index: 0,
          form_steps_type: 1,
          label: "Informations personnelles",
          title: "Tes informations personnelles",
          description:
            "Bienvenue dans l'espace de création de ton profil Skribix. ",
          duration: "1 min",
          step_count: 1,
          content: [
            {
              index: 0,
              name: "PersonnalInformation",
              required: true,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: false,
          isStepValid: false,
        },
        {
          index: 1,
          form_steps_type: 2,
          label: "Coordonnées de facturation",
          title: "Tes Coordonnées de Facturation",
          description:
            "Nous ne sélectionnons que des rédacteurs professionnels qui sont enregistrés dans un pays de l'UE. Si ce n'est pas ton cas, inutile d'aller plus loin.",
          duration: "1 min",
          step_count: 1,
          content: [
            {
              index: 0,
              name: "InvoicesInformation",
              required: true,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 2,
          form_steps_type: 2,
          label: "Formation",
          title: "Ancienneté",
          description:
            "Nous somme curieux de savoir depuis combien de temps tu est dans le métier !",
          duration: "3 min",
          step_count: 3,
          content: [
            {
              index: 0,
              name: "FormationExperience",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 3,
          form_steps_type: 2,
          label: "Formation",
          title: "Formation",
          description: "On veut en savoir plus sur ta formation !",
          duration: "3 min",
          step_count: 3,
          content: [
            {
              index: 0,
              name: "FormationInformation",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 4,
          form_steps_type: 2,
          label: "Formation",
          title: "SEO",
          description: "Évalue ton SEO.",
          duration: "3 min",
          step_count: 3,
          content: [
            {
              index: 0,
              name: "FormationSEO",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 5,
          form_steps_type: 2,
          label: "Rédaction",
          title: "Rédaction",
          description:
            "Ici tu peux nous en dire plus sur la rédaction au niveau professionnel.",
          duration: "2 min",
          step_count: 2,
          content: [
            {
              index: 0,
              name: "FormationRedactionWork",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 6,
          form_steps_type: 2,
          label: "Rédaction",
          title: "Nombre de mots mensuel",
          description:
            "Le nombre de mots maximum que tu peux produire chaque mois.",
          duration: "2 min",
          step_count: 2,
          content: [
            {
              index: 0,
              name: "FormationRedactionQuantityWord",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 7,
          form_steps_type: 2,
          label: "Profil de Rédacteur",
          title: "Ton profil",
          description:
            "Ton profil est visible par le client au moment de sélectionner les rédacteurs qui vont participer à sa commande. Il est donc très important que tu travailles ton profil pour que le client te choisisse.",
          duration: "4 min",
          step_count: 6,
          content: [
            {
              index: 0,
              name: "ProfilInformation",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 8,
          form_steps_type: 2,
          label: "Profil",
          title: "Quels sont tes thématiques préférées ?",
          description: "Choisis uniquement tes spécialités.",
          duration: "4 min",
          step_count: 6,
          content: [
            {
              index: 0,
              name: "ProfilThematic",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 9,
          form_steps_type: 2,
          label: "Profil",
          title: "Quels sont tes types de textes préférés ?",
          description:
            "Choisis uniquement les types de contenus que tu maitrises. Nous te proposerons des formations dédiées aux autres types de textes et tu pourras ensuite les ajouter à ton profil.",
          duration: "4 min",
          step_count: 6,
          content: [
            {
              index: 0,
              name: "TypeTexts",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 10,
          form_steps_type: 2,
          label: "Profil",
          title: "Quelles options peux-tu proposer ?",
          description:
            "Attention, pour choisir ces options tu dois pouvoir démontrer tes compétences dans ce domaine.",
          duration: "1 min",
          step_count: 6,
          content: [
            {
              index: 0,
              name: "ProfilOption",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 11,
          form_steps_type: 2,
          label: "Profil",
          title: "Dans quelle langue est-ce-que tu rédiges ?",
          description:
            "Nous recherchons des rédacteurs natifs, pas des traducteurs. Tu ne dois donc sélectionner que ta ou tes langues maternelles.",
          duration: "1 min",
          step_count: 6,
          content: [
            {
              index: 0,
              name: "ProfilLanguage",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 12,
          form_steps_type: 2,
          label: "Profil",
          title: "Tes textes d'exemples",
          description:
            "Uploader quelques textes d'exemples pour qu'on puisse les lire avant de valider ta candidature",
          duration: "1 min",
          step_count: 6,
          content: [
            {
              index: 0,
              name: "ProfilTextExample",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 13,
          form_steps_type: 1,
          label: "Signature du contrat",
          title: "Ton contrat de prestation de service SKRIBIX",

          duration: "3 min",
          step_count: 1,
          content: [
            {
              index: 0,
              name: "Contract",
              required: true,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 14,
          form_steps_type: 1,
          label: "Création de ton compte",
          title: "Félicitation, ton compte rédacteur est prêt",
          duration: "1 min",
          step_count: 1,
          content: [
            {
              index: 0,
              name: "CreateWriterAccount",
              required: true,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: true,
          isStepValid: false,
        },
      ];
    },
  },
};
</script>
<style>
.mainFormView nav {
  display: none;
}
.navButton p {
  font-family: Athletics !important;
}
.is-inline {
  display: none;
}
</style>
