import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";

import i18n from "@/i18n/i18n";
import { NotificationProgrammatic as Notification } from "buefy";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lang: null,
    showBackdrop: false,
    locales: ["fr", "en"],
    debug: process.env.VUE_APP_DEBUG ?? false,
  },
  getters: {},
  mutations: {
    setLang(state, lang) {
      localStorage.setItem("lang", JSON.stringify(lang));
      i18n.locale = lang;
      state.lang = lang;
    },
    setBackdrop(state, data) {
      state.showBackdrop = data;
    },
  },
  actions: {
    notify(context, data) {
      if (data.type == "is-info") {
        data.iconPack = "mdi";
        data.icon = "information-outline";
      }

      if (data.type == "is-danger") {
        data.iconPack = "mdi";
        data.icon = "alert-outline";
      }

      data.iconPack = data.iconPack ?? "fas";

      Notification.open({
        duration: data.duration ?? 5000,
        hasIcon: true,
        icon: data.icon ?? null,
        iconSize: data.iconPack == "fas" ? "is-large" : "is-medium",
        message: data.message,
        position: data.position || "is-top-right",
        type: data.type || "is-success",
        iconPack: data.iconPack,
      });
    },

    fireError(context, error) {
      if (context.state.debug) {
        console.log(error);
      }

      if (!error) {
        return;
      }

      let traduction = i18n.messages[process.env.VUE_APP_I18N_LOCALE];
      let title = "Une erreur est survenue";
      let body = error?.response?.data?.message || error?.message || "";

      if (error?.response?.status) {
        switch (error.response.status) {
          case 400:
          case 401:
          case 403:
            body = error.response.data?.message || "";
            break;

          case 422:
            title = error.response.data.message.includes("validation.unique")
              ? traduction.error.title.validation.unique
              : error.response.data?.message ||
                "Erreur de validation des données";

            if (error.response.data.message.includes("validation.unique")) {
              body = traduction.error.validation.unique;
            } else if (error.response.data.errors) {
              body =
                error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                ];
            }

            break;

          case 550:
          case 500:
            title = error.response.data?.message || "Erreur serveur";
            break;

          case 429:
            body =
              "Vous avez envoyé trop de requêtes en un laps de temps. Veuillez réessayer plus tard.";
            break;
        }
      }

      let message = "";

      if (title) {
        message += `<b>${title}</b>`;
      }

      if (body && body !== title) {
        message += `<br/>${body}`;
      }

      Notification.open({
        message,
        duration: 5000,
        position: "is-top-right",
        type: "is-danger",
      });
    },

    initialiseStore({ commit }, router) {
      const user = localStorage.getItem("user");
      const token = localStorage.getItem("token");

      if (token && router) {
        const guestOnlyRoutes = ["/set-password", "/inscription"];

        // Test if the current route is for guests only
        const isGuestOnlyRoute = guestOnlyRoutes.some((route) =>
          router.history._startLocation.includes(route)
        );

        // Logout if the current route is for guests only
        if (isGuestOnlyRoute) {
          localStorage.clear();
          return;
        }
      }

      if (!user || !token) {
        localStorage.clear();

        const authorizedRoutes = [
          "/reset",
          "/set-password",
          "/inscription",
          "/redirect-to-app",
        ];

        // Test if the current route is authorized for guests
        const isAuthorized = authorizedRoutes.some((route) =>
          router.history._startLocation.includes(route)
        );

        // Redirect to login page if the current route is not authorized for guests
        if (
          !isAuthorized &&
          !router.history._startLocation.includes("/login")
        ) {
          if (window.location.pathname !== "/") {
            commit("auth/setRedirectRoute", window.location.pathname);
          }

          router.push("/login");
          return;
        }

        router.push(router.history._startLocation);
        return;
      }

      commit("auth/setToken", token);
      commit("auth/setUser", JSON.parse(user));
      commit("auth/setIsConnected", true);
      commit("auth/setHomepage");
    },

    prepareParams(context, config) {
      let field = config.sort;

      if (config.sortDirection === "desc") {
        field = "-" + field;
      }

      let params = {
        page: config.page,
        include: config.include,
        sort: field,
        per_page: config.per_page,
      };

      if (config.resource_fields) {
        params.resource_fields = config.resource_fields;
      }

      if (config.no_cache === true) {
        params.no_cache = true;
      }

      if (config.filters) {
        for (const [key, value] of Object.entries(config.filters)) {
          params[`filter[${key}]`] = value;
        }
      }

      return params;
    },
  },

  modules: modules,
});
