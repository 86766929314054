<template>
  <div>
    <main class="section" v-if="isLoading">
      <form
        class="column is-12-mobile is-12-tablet is-12-desktop is-12-widescreen is-12-fullhd is-flex is-flex-direction-column is-justify-content-space-evenly m-auto"
      >
        <div
          v-for="(element, index) in fieldsUserProfile"
          class="my-1"
          :key="index"
          style="height: 100%"
        >
          <p class="has-text-blue bold" v-if="element.title">
            {{ element.title }}
          </p>

          <component
            v-if="componentReady && element"
            :key="index"
            :indexStep="index"
            :indexField="index"
            :is="element.name"
            :field="element"
            :isSmallField="element.small"
            :isNextStep="isNextStep"
            @save-result="saveResult"
            :isNotWizard="true"
            class="is-flex is-flex-direction-column is-justify-content-space-evenly mt-1"
          />
        </div>
      </form>
      <CardValidationField
        v-if="hasError"
        :field="false"
        :is-next-step="true"
        :message="hasErrorMessage"
      />
    </main>
  </div>
</template>

<script>
import BriefText from "@/components/Model/Form/FormItem/BriefText";
import BriefSwitch from "@/components/Model/Form/FormItem/BriefSwitch";
import CardValidationField from "@/components/UI/card/CardValidationField";
export default {
  name: "FormationFormItem",
  props: ["field", "indexStep", "indexField"],
  components: {
    BriefText,
    BriefSwitch,
    CardValidationField,
  },

  data() {
    return {
      isLoading: true,
      isNextStep: false,
      componentReady: true,
      hasError: false,
      have_formation: false,
      hasErrorMessage: "Veillez saisir une formation",
    };
  },

  created() {
    if (
      this.candidateProfile.preferences &&
      this.candidateProfile.preferences.have_formation
    ) {
      this.fieldsUserProfile.push(this.fieldFormation);
    }
  },

  computed: {
    candidateProfile: {
      get() {
        return this.$store.state.user.candidateProfile;
      },
      set(value) {
        this.$store.commit("user/setCandidateProfile", value);
      },
    },

    fieldFormation() {
      return {
        title: "Laquelle ?",
        name: "BriefText",
        value_name: "formation",
        required: true,
        value: this.candidateProfile.preferences
          ? this.candidateProfile.preferences.formation
          : " ",
      };
    },

    fieldsUserProfile() {
      return [
        {
          title: "As-tu suivi une formation ?",
          name: "BriefSwitch",
          value_name: "have_formation",
          required: false,
          value: this.candidateProfile.preferences.have_formation
            ? this.candidateProfile.preferences.have_formation
            : false,
        },
      ];
    },
  },
  methods: {
    saveResult(result) {
      if (!this.candidateProfile.preferences) {
        this.candidateProfile.preferences = {};
      }

      this.candidateProfile.preferences[result.value_name] = result.value;

      if (result.index_field <= this.fieldsUserProfile.length - 1) {
        this.fieldsUserProfile[result.index_field].value = result.value;
        this.fieldsUserProfile[result.index_field].hasError = result.hasError;
      }

      if (result.value_name == "have_formation") {
        this.forceRerender();
        this.have_formation = result.value;
      }

      if (result.value_name) {
        this.handleFormationFieldWhenSaving(result);
      }
    },

    handleFormationFieldWhenSaving(result) {
      const formationField = this.fieldsUserProfile.find(
        (objet) => objet.value_name === "formation"
      );

      this.candidateProfile.preferences[result.value_name] = result.value;

      if (this.have_formation && !formationField) {
        this.fieldsUserProfile.push(this.fieldFormation);
      } else if (!this.have_formation && formationField) {
        this.fieldsUserProfile.pop();
      }
    },

    async validStep(steps) {
      this.isNextStep = true;

      await steps.forEach((step) => {
        const dynamicRequiredIsValid = step.dynamicRequired
          ? step.dynamicRequired()
          : false;

        if (step.required === true || dynamicRequiredIsValid) {
          const formationValue =
            this.candidateProfile.preferences[step.value_name];

          if (!formationValue || formationValue === " " || step.hasError) {
            step.isValid = false;
          } else {
            step.isValid = true;
          }
        } else if (step.hasError && step.dynamicRequired === undefined) {
          step.isValid = false;
        } else {
          step.isValid = true;
        }
      });

      if (steps.filter((step) => step.isValid === false).length === 0) {
        steps.isStepValid = true;
      } else {
        steps.isStepValid = false;
      }
    },

    async forceRerender() {
      this.componentReady = false;
      await this.$nextTick();
      this.componentReady = true;
    },
  },
};
</script>
<style scoped>
.section {
  padding: 0;
}
</style>
